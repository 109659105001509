import React, { useEffect, useState } from 'react'
import PropertiesImg from "../../assets/images/listings/listing-single-1.jpg";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from 'react-toastify';
import { propertyNearByApi } from '../../services/propertyService';
import LoaderMu from '../common/LoaderMu';
import PropertyCard from '../Property/PropertyCard';
import { useTranslation } from 'react-i18next';
// all 1.2s ease 0s
function NearBySimilars({ propertyId }) {

	const {t} = useTranslation()

	const [loading, setLoading] = useState(false)

	const [propertiesList, setPropertiesList] = useState([])

	// SLIDER OPTIONS
	const SamplePrevArrow = (props) => <div className="slick-arrow-prev" onClick={props?.onClick} ><HiOutlineArrowNarrowLeft className='fs-5' /></div>;
	const SampleNextArrow = (props) => <div className="slick-arrow-next" onClick={props?.onClick} ><HiOutlineArrowNarrowRight className='fs-5' /></div>;
	var settings = {
		dots: true,
		infinite: true,
		speed: 3000,
		slidesToShow: 3,
		slidesToScroll: 3,
		appendDots: dots => (
			<div className='slick-dots-wrapper d-flex '>
				<ul className='slick-dots-ul'> {dots} </ul>
			</div>
		),
		
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: true
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		  ]
	};


	// CALL API TO GET PROPERTY DETAILS
	const fetchNearByProperties = () => {
		setLoading(true)
		propertyNearByApi(propertyId).then((response) => {
			setLoading(false)
			setPropertiesList(response)
		}).catch((error) => {
			setLoading(false)
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}

	// FETCH PROPERTY DETAILS

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		})

		fetchNearByProperties()
	}, [propertyId])




	return (
		<>
			<LoaderMu loading={loading} />
			{
				propertiesList?.length > 0 ? <>
					<div className="row mt30 wow fadeInUp" data-wow-delay="700ms">
						<div className="col-lg-9">
							<div className="main-title2">
								<h2 className="title">{t("NEARBY SIMILARS")}</h2>
								{/* <p className="paragraph">{t("BASED ON WHAT YOU SEEING NOW")}</p> */}
							</div>
						</div>
					</div>
					{
						propertiesList && propertiesList?.length > 2 ?
							<div className="row wow fadeInUp" data-wow-delay="900ms">
								<div className="col-lg-12">
									<div className="property-city-slider navi_pagi_top_right slider-dib-sm slider-3-grid ">
										<Slider {...settings}>
											{
												propertiesList?.map((property, index) => {
													return <div key={index + 1} className="item px-3"> <PropertyCard propertyDetails={property} /></div>
												})
											}
										</Slider>
									</div>
								</div>
							</div>
							:
							<div className="row">
								{
									propertiesList && propertiesList?.length > 0 ?
										propertiesList?.map((property, index) => {
											return <div key={index + 1} className="col-sm-6 col-lg-4"><PropertyCard propertyDetails={property} /> </div>
										}) : ""
								}
							</div>

					}
				</> : ""
			}

		</>
	)
}

export default NearBySimilars
