import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { removeSearchParams } from '../../services/propertyService'


const LocationSearchInput = ({ className, placeholder, setSelectedPlace, value, navigatePath,selectedPlace ,isHome ,handleEnter}) => {

	const navigate = useNavigate()

	const [searchText, setSearchText] = useState((value && value!==undefined) ? value : '')

	// HANDLE LOCATION INPUT CHANGE
	const handleLocationInputChange = (event) => {
		setSearchText(event?.target?.value)
		// REMOVE SELECTED PLACE
		setSelectedPlace(null)
		if (!isHome && event.target.value === '' && navigatePath) {
	
			const navigateURl = removeSearchParams(navigatePath, "formattedAddress")
			navigate(navigateURl)
		}
	}

	// WHEN USER TYPE AND SELET LOCATION
	useEffect(() => {
		if (searchText && searchText !== '' && searchText !== null) {
			const input = document.getElementById('autocomplete-input');
			const defaultBounds = new window.google.maps.LatLngBounds(
				new window.google.maps.LatLng(32.04634249067534, 35.86552953668171), // Southwest corner
				new window.google.maps.LatLng(32.08456795312497, 35.89816793287933)  // Northeast corner
			);
			// OPTION FORM AUTOCOMPLETE AREA SEARCH
			const options = {
				bounds: defaultBounds,
				// types: ['(regions)'],
				types: ['geocode'],
				// fields: ["address_components", "geometry", "icon", "name","formatted_address"],
				componentRestrictions: { country: "jo" },
				// strictBounds: true,
			};
			if (input) {
				const autocomplete = new window.google.maps.places.Autocomplete(input, options);

				// THIS WILL CALL ON PLACE CHANGED AND SELECTED BY USER
				function handlePlaceChanged() {
					const place = autocomplete.getPlace();
				
					// Ensure the place has geometry and viewport information
					if (place?.geometry && place?.geometry?.location && place?.geometry?.viewport) {
						// Construct a new viewport object with northeast and southwest properties
						const newViewport = {
							northeast: place.geometry.viewport.getNorthEast(),
							southwest: place.geometry.viewport.getSouthWest()
						};

						// Replace the viewport property in the place object with the new viewport object
						place.geometry.viewport = newViewport;
						setSelectedPlace(place);
						setSearchText(place?.formatted_address ?? '')

						// Do something with the modified place object
					} // ELSE Invalid selected place

				}
				// CALL THIS EVENT ON ALSO PAGE RELOAD
				window.google.maps.event.addListener(autocomplete, 'place_changed', handlePlaceChanged);
			}
		}
	}, [searchText])

	// WHEN VALUE IS AVAILABLE IN SEARCH PARAMS
	useEffect(() => {
		// THIS WILL CALL WHEN VALUE IS AUTOFILLED
		if (value && value !== '' && value !== null) {
			
			var geocoder = new window.google.maps.Geocoder();
			geocoder.geocode({ 'address': value }, function (results, status) {
				if (status == window.google.maps.GeocoderStatus.OK) {
					const place = results[0]
					// Construct a new viewport object with northeast and southwest properties
					const newViewport = {
						northeast: place.geometry.viewport.getNorthEast(),
						southwest: place.geometry.viewport.getSouthWest()
					};
					// Replace the viewport property in the place object with the new viewport object
					
					place.geometry.viewport = newViewport;
					setSelectedPlace(place);
					setSearchText(place?.formatted_address ?? '')

				}
			})
		}
		
	}, [value])

	const handleOnKeyDown = (event)=>{
		if(isHome){
			if(event.key === "Enter"){
				handleEnter()
			}
		}
		
	}

	return (
		<input id="autocomplete-input"
			type="text"
			value={searchText}
			className={` ${className} form-control`}
			placeholder={placeholder}
			onKeyDown={handleOnKeyDown}
			onChange={handleLocationInputChange}
		/>
	)
}

export default LocationSearchInput
