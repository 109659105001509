import React, { useEffect, useState } from 'react'

import PropertiesImg from "../../assets/images/listings/listing-single-1.jpg";
import OwlCarousel from "react-owl-carousel2";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { propertiesForYou } from '../../services/homePageServices';
import { useTranslation } from 'react-i18next';
import { Empty, Skeleton } from 'antd';
import PropertyCard from '../Property/PropertyCard';
import { propertyInfoApi } from '../../services/advanceSearch';
import { constructUrl } from '../../services/propertyService';
import { nameBasedRoutes } from '../../router/public';
import Cookies from 'universal-cookie';
const PropertiesForYou = () => {

  const navigate = useNavigate()

  const { i18n } = useTranslation()

  const cookies = new Cookies()

  const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';
  // SLIDER OPTIONS
  const PropertiesOptions = {
    rtl: userLanguage === 'ar' ? true : false,
    items: 3,
    nav: true,
    rewind: true,
    autoplay: true,
    margin: 30,
    // responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: false
      },
      1000: {
        items: 3,
        nav: true,
        loop: false
      }
    }
  };
  const events = {
    onDragged: function (event) { },
    onChanged: function (event) { },
  };

  const [seeMorePath, setSeeMorePath] = useState('')

  const { t } = useTranslation()

  const [propertiesList, setPropertiesList] = useState([])

  const [loading, setLoading] = useState(true)

  // FETCH PROPERTIES FOR YOU LIST
  const fetchPropertiesForYou = () => {
    setLoading(true)
    propertiesForYou().then((response) => {
      setLoading(false)
      setPropertiesList(response)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }

  // FETCH PROPERTY INFO DATA FOR SEARCH PATH
  const fetchPropertiesInfo = () => {
    setLoading(true)
    propertyInfoApi().then((response) => {
      setLoading(false)
      // IF MAP VIEW TRUE THEN REDIRECT TO MAP PAGE ELSE REDIRECT TO PROPERTY LIST PAGE
      let navigatePath = nameBasedRoutes?.propertyList?.path
      if (response?.userPropertySearch?.isMapView === true) {
        navigatePath = nameBasedRoutes?.searchByMap?.path
      }
      // CREATE A PROPER PAYLOAD FOR SEARCH DATA
      // VALUE TO BE IGNORED
      const ignoredValues = [null, '']
      if (response?.userPropertySearch !== null) {
        const filteredFormData = Object.entries(response?.userPropertySearch)
          .filter(([key, value]) => (!ignoredValues.includes(value))) // Filter out properties with null values
          .map(([key, value]) => ({ key, value }));
        // CONSTRUCT URL BASED ON DATA AVAILABLE
        const navigateURl = constructUrl(navigatePath, filteredFormData)
        setSeeMorePath(navigateURl)
      }


    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }

  // FETCH PROPERTIES FOR YOU
  useEffect(() => {
    fetchPropertiesForYou()
    fetchPropertiesInfo()
  }, [i18n?.language])

  return (
    <div className="container">
      <div
        className="row align-items-center wow fadeInUp"
        data-wow-delay="00ms"
      >
        <div className="col-lg-9">
          <div className="main-title2">
            <h2 className="title">{t('PROPERTIES FOR YOU')}</h2>
            <p className="paragraph">
              {t("BASED ON YOUR LATEST SEARCH PROCESS")}
            </p>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="text-start text-lg-end mb-3">
            <Link className="ud-btn2" to={seeMorePath}>
              {t("SEE MORE RESULTS")}
              <i className="fal fa-arrow-right-long" />
            </Link>
          </div>
        </div>
      </div>
      {
        loading === true ?
          <div className="row">
            {[...Array(3)].map((element, index) => {
              return <div key={index + 1} className="col-xl">
                <Skeleton.Button active={true} size={"100%"} shape={"square"} block={true} style={{ height: "400px" }} />
              </div>
            })
            }
          </div> :
          propertiesList && propertiesList?.length > 0 ?
            propertiesList && propertiesList?.length > 1 ?
              <div className="row">
                <div className="col-lg-12 wow fadeInUp" data-wow-delay="300ms">
                  <OwlCarousel
                    className="feature-listing-slider2 vam_nav_style dots_none slider-dib-sm slider-3-grid owl-carousel owl-theme"
                    options={PropertiesOptions}
                    events={events}
                  >
                    {
                      // listingstyle1 style3
                      propertiesList?.map((property, index) => (
                        <div key={index + 1} className="item">
                          <PropertyCard styleClass="listingstyle1 style3" propertyDetails={property} />
                        </div>
                      ))
                    }

                  </OwlCarousel>
                </div>
              </div> : <div className="row">
                {
                  /* NO NEED FOR SLIDER IF ONLY ONE PROPERTY AVAILABLE */
                  propertiesList?.map((property, index) => (
                    <div key={index + 1} className="col-sm-6 col-lg-4">
                      <PropertyCard styleClass="listingstyle1 style3" propertyDetails={property} />
                    </div>
                  ))
                }
              </div>

            : <Empty description={t("NO PROPERTIES FOUND")} />
      }

    </div>

  )
}

export default PropertiesForYou
