import PropTypes from 'prop-types';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { getUserDataApi, soicalSignIn, soicalSignUp } from '../../services/userService';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import storage from "../../helpers/storage"
import { FaGoogle } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import { setUser } from '../../redux/userProfileSlics';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const GoogleSignupButtonDefault = ({ setLoading, onLogin, type, redirectProfile }) => {

    const dispatch = useDispatch()

    const cookies = new Cookies()

    const { i18n } = useTranslation()

    const navigate = useNavigate()

    const theme = "filled_black"; // filled_blue | filled_black
    const size = "large";
    const shape = "circle";

    const SCRIPT_ID = 'g-script';
    const ENABLE_GOOGLE = true;
    //const [loading, setLoading] = useState(setLoadingComing);
    const [isMounted, setIsMounted] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    let google = window.google || {};

    /**
     * Hook on mount and unmount app.
     */
    useEffect(() => {
        google?.accounts?.id.initialize({
            // client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            client_id: "655691401831-v29rhgmkeclu0itdbnb9of1nkub13b3r.apps.googleusercontent.com",
            callback: handleCallbackResponse
        });

        google?.accounts?.id.renderButton(
            document.getElementById('signUpDiv'),
            { type: "standard", theme: "outlined", size: "large", shape: "rectangular", width: "350", logo_alignment: "left" }
        )
    }, []);

    // google main functionality  
    function handleCallbackResponse(res) {
        // console.log('token',res.credential)
        const userObj = jwtDecode(res.credential)
        // console.log('user data',userObj.given_name)
        onSuccess(userObj);

    }

    const login = async () => {
        try {
            const instance = window.gapi.auth2.getAuthInstance();
            const response = await instance.signIn();
            let profile = instance.currentUser.get().getBasicProfile();

            const user = {};
            let token = instance.currentUser.get().getAuthResponse(true).access_token;
            let backendToken = instance.currentUser.get().getAuthResponse(true).id_token;
            user.id = profile.getId();
            user.name = profile.getName();
            user.email = profile.getEmail();
            user.photoUrl = profile.getImageUrl();
            user.firstName = profile.getGivenName();
            user.lastName = profile.getFamilyName();
            user.authToken = token;
            user.idToken = backendToken;
            user.provider = "GOOGLE";
            if (response && response.code) {
                user.authorizationCode = response.code;
            }
            onSuccess(user)
            await instance.signOut();
        } catch (error) {
            onError(error);
        }
    }

    /**
     * Handles successful authentication response.
     * 
     * @function
     * @name onSuccess
     * @param {CredentialResponse} response - The response containing credentials.
     * @returns {void}
     */
    const onSuccess = (response) => {
        setLoading(true);

        // Calling social sign-in api
        soicalSignIn({
            socialUserId: response.sub,
            firstName: response.given_name,
            lastName: response.family_name,
            email: response.email,
            socialUserName: response.name,
            socialNetwork: 'GOOGLE',
            deviceType: 'BROWSER',
            signup: false
        }).then(responseResult => {
            if (responseResult.id_token) {
                storage.setToken(responseResult?.id_token)
                storage.setData("userType", "USER");
                storage.setData("refresh-token", responseResult?.id_token);
                getUserDataApi()
                    .then((userApiResponse) => {
                        setLoading(false);

                        dispatch(
                            setUser({ userData: userApiResponse, token: response?.id_token })
                        );
                        // SET USER LANGUAGE KEY

                        cookies.remove("userLangKey")
                        cookies.set("userLangKey", userApiResponse?.langKey)
                        i18n.changeLanguage(userApiResponse?.langKey)

                        if (userApiResponse?.profileComplete === false) {
                            cookies.set("showProfileModal", true)
                        } else {
                            cookies.set("showProfileModal", false)
                        }

                        onLogin(responseResult);
                        if (redirectProfile) {
                            navigate("/profile-user");
                        } else {
                            navigate("/");
                        }

                    })
                    .catch((error) => {
                        setLoading(false);
                        toast.error(
                            error?.response?.data?.error_description
                                ? error?.response?.data?.error_description
                                : error?.response?.data?.message
                        );
                    });
            }
            else {
                // Calling social sign-up api
                soicalSignUp({
                    socialUserId: response.sub,
                    firstName: response.given_name,
                    lastName: response.family_name,
                    email: response.email,
                    socialUserName: response.name,
                    socialNetwork: 'GOOGLE',
                    deviceType: 'BROWSER',
                    signup: true
                }).then(responseResult => {
                    // storage.setToken(responseResult?.id_token)
                    // onLogin(responseResult);
                    // //setLoading(false);
                    // window.location.href = process.env.REACT_APP_WEBSITE_WEB_URL + '/profile-user'
                    storage.setToken(responseResult?.id_token)
                    storage.setData("userType", "USER");
                    storage.setData("refresh-token", responseResult?.id_token);
                    getUserDataApi()
                        .then((userApiResponse) => {
                            setLoading(false);

                            dispatch(
                                setUser({ userData: userApiResponse, token: response?.id_token })
                            );
                            // SET USER LANGUAGE KEY

                            cookies.remove("userLangKey")
                            cookies.set("userLangKey", userApiResponse?.langKey)
                            i18n.changeLanguage(userApiResponse?.langKey)

                            if (userApiResponse?.profileComplete === false) {
                                cookies.set("showProfileModal", true)
                            } else {
                                cookies.set("showProfileModal", false)
                            }


                            onLogin(responseResult);
                            if (redirectProfile) {
                                navigate("/profile-user");
                            } else {
                                navigate("/");
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            toast.error(
                                error?.response?.data?.error_description
                                    ? error?.response?.data?.error_description
                                    : error?.response?.data?.message
                            );
                        });
                }, error => {
                    //setLoading(false);
                    toast(error.data.error_description ? error.data?.error_description : 'Something went wrong.', { type: toast.TYPE.ERROR });
                });
            }

        }, error => {
            // Calling social sign-up api
            soicalSignUp({
                socialUserId: response.sub,
                firstName: response.given_name,
                lastName: response.family_name,
                email: response.email,
                socialUserName: response.name,
                socialNetwork: 'GOOGLE',
                deviceType: 'BROWSER',
                signup: true
            }).then(responseResult => {
                storage.setToken(responseResult?.id_token)
                storage.setData("userType", "USER");
                storage.setData("refresh-token", responseResult?.id_token);
                getUserDataApi()
                    .then((userApiResponse) => {
                        setLoading(false);

                        dispatch(
                            setUser({ userData: userApiResponse, token: response?.id_token })
                        );
                        // SET USER LANGUAGE KEY

                        cookies.remove("userLangKey")
                        cookies.set("userLangKey", userApiResponse?.langKey)
                        i18n.changeLanguage(userApiResponse?.langKey)

                        if (userApiResponse?.profileComplete === false) {
                            cookies.set("showProfileModal", true)
                        } else {
                            cookies.set("showProfileModal", false)
                        }
                        onLogin(responseResult);
                        if (redirectProfile) {
                            navigate("/profile-user");
                        } else {
                            navigate("/");
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        toast.error(
                            error?.response?.data?.error_description
                                ? error?.response?.data?.error_description
                                : error?.response?.data?.message
                        );
                    });
                // onLogin(responseResult);
                // //setLoading(false);
                // window.location.href = process.env.REACT_APP_WEBSITE_WEB_URL + '/profile-user'
            }, error => {
                //setLoading(false);
                toast(error.data.error_description ? error.data?.error_description : 'Something went wrong.', { type: toast.TYPE.ERROR });
            });
        });

    }

    /**
     * Handles failed authentication attempt.
     * 
     * @function
     * @name onError
     * @returns {void}
     */
    const onError = (error) => {
        //setLoading(false);
    }

    return (
        <div id="signUpDiv">
            <FaGoogle /> With Google
        </div>
    )
}

/**
 * Defining propTypes
 */


export default GoogleSignupButtonDefault;