import { getUserDataApi, soicalSignIn, soicalSignUp } from '../../services/userService';
import { toast } from 'react-toastify';
import { useEffect, useRef } from 'react';
import storage from "../../helpers/storage"
import { FaGoogle } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { setUser } from '../../redux/userProfileSlics';
import { useTranslation } from 'react-i18next';

const GoogleLoginButtonDefault = ({ setLoading, onLogin, type }) => {

    const { i18n } = useTranslation()

    const dispatch = useDispatch()

    const buttonRef = useRef(null);

    const cookies = new Cookies()

    let google = window.google || {};

    /**
     * Hook on mount and unmount app.
     */
    useEffect(() => {
        google.accounts.id.initialize({
            // client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            client_id: "655691401831-v29rhgmkeclu0itdbnb9of1nkub13b3r.apps.googleusercontent.com",
            callback: handleCallbackResponse
        });
        google.accounts.id.renderButton(
            document.getElementById('signInDiv'),
            // buttonRef.current,
            { type: "standard", theme: "outlined", size: "large", shape: "rectangular", width: "350", logo_alignment: "left"}
        )
    }, []);

    // google main functionality  
    function handleCallbackResponse(res) {
        // console.log('token', res.credential)
        const userObj = jwtDecode(res.credential)
        // console.log('user data', userObj.given_name)
        onSuccess(userObj);
    }

    /**
     * Handles successful authentication response.
     */
    const onSuccess = (response) => {
        setLoading(true);
        // CALLING SOCIAL SIGN IN API 
        soicalSignIn({
            socialUserId: response.sub,
            firstName: response.given_name,
            lastName: response.family_name,
            email: response.email,
            socialUserName: response.name,
            socialNetwork: 'GOOGLE',
            deviceType: 'BROWSER',
            signup: false
        }).then(responseResult => {
            if (responseResult.id_token) {
                storage.setToken(responseResult?.id_token)
                storage.setData("userType", "USER");
                storage.setData("refresh-token", responseResult?.id_token);
                getUserDataApi()
                    .then((userApiResponse) => {
                        setLoading(false);
                        dispatch(
                            setUser({ userData: userApiResponse, token: responseResult?.id_token })
                        );
                        onLogin(responseResult);
                        // SET USER LANGUAGE KEY
                        cookies.remove("userLangKey")
                        cookies.set("userLangKey", userApiResponse?.langKey)
                        i18n.changeLanguage(userApiResponse?.langKey)

                        if (userApiResponse?.profileComplete === false) {
                            cookies.set("showProfileModal", true)
                        } else {
                            cookies.set("showProfileModal", false)
                        }
                        // HANDLE REDIRECT
                        // navigate("/");

                    })
                    .catch((error) => {
                        setLoading(false);
                        toast.error(
                            error?.response?.data?.error_description
                                ? error?.response?.data?.error_description
                                : error?.response?.data?.message ?? error?.message
                        );
                    });
            }
            else {
                // CALLING SOCIAL SIGN UP API
                soicalSignUp({
                    socialUserId: response.sub,
                    firstName: response.given_name,
                    lastName: response.family_name,
                    email: response.email,
                    socialUserName: response.name,
                    socialNetwork: 'GOOGLE',
                    deviceType: 'BROWSER',
                    signup: true
                }).then(responseResult => {

                    storage.setToken(responseResult?.id_token)
                    storage.setData("userType", "USER");
                    storage.setData("refresh-token", responseResult?.id_token);

                    // GET USER DATA AND SET IN REDUX , COOKIES
                    getUserDataApi()
                        .then((userApiResponse) => {
                            setLoading(false);
                            dispatch(
                                setUser({ userData: userApiResponse, token: responseResult?.id_token })
                            );
                            // SET USER LANGUAGE KEY
                            cookies.remove("userLangKey")
                            cookies.set("userLangKey", userApiResponse?.langKey)
                            i18n.changeLanguage(userApiResponse?.langKey)

                            if (userApiResponse?.profileComplete === false) {
                                cookies.set("showProfileModal", true)
                            } else {
                                cookies.set("showProfileModal", false)
                            }

                            onLogin(responseResult);
                            // navigate("/");

                        })
                        .catch((error) => {
                            setLoading(false);
                            toast.error(
                                error?.response?.data?.error_description
                                    ? error?.response?.data?.error_description
                                    : error?.response?.data?.message
                            );
                        });
                }, error => {
                    //setLoading(false);
                    toast(error?.data?.error_description ? error?.data?.error_description : 'Something went wrong.', { type: toast.TYPE.ERROR });
                });
            }

        }, error => {
            // Calling social sign-up api
            soicalSignUp({
                socialUserId: response.sub,
                firstName: response.given_name,
                lastName: response.family_name,
                email: response.email,
                socialUserName: response.name,
                socialNetwork: 'GOOGLE',
                deviceType: 'BROWSER',
                signup: true
            }).then(responseResult => {
                storage.setToken(responseResult?.id_token)
                storage.setData("userType", "USER");
                storage.setData("refresh-token", responseResult?.id_token);
                getUserDataApi()
                    .then((userApiResponse) => {
                        setLoading(false);

                        dispatch(
                            setUser({ userData: userApiResponse, token: responseResult?.id_token })
                        );
                        // SET USER LANGUAGE KEY

                        cookies.remove("userLangKey")
                        cookies.set("userLangKey", userApiResponse?.langKey)
                        i18n.changeLanguage(userApiResponse?.langKey)

                        if (userApiResponse?.profileComplete === false) {
                            cookies.set("showProfileModal", true)
                        } else {
                            cookies.set("showProfileModal", false)
                        }

                        onLogin(responseResult);
                        // navigate("/");
                    })
                    .catch((error) => {
                        setLoading(false);
                        toast.error(
                            error?.response?.data?.error_description
                                ? error?.response?.data?.error_description
                                : error?.response?.data?.message
                        );
                    });


                // onLogin(responseResult);
                // //setLoading(false);
                // window.location.href = process.env.REACT_APP_WEBSITE_WEB_URL + '/profile-user'
            }, error => {
                setLoading(false);
                toast(error?.data?.error_description ? error?.data?.error_description : 'Something went wrong.', { type: toast.TYPE.ERROR });
            });
        });

    }
    /**
     * Handles failed authentication attempt.
     * 
     * @function
     * @name onError
     * @returns {void}
     */


    return (
        // ud-btn btn-white w-100 p-0 text-center google-login-btn

            <div id="signInDiv">
                <FaGoogle /> With Google
            </div>

    )
}
/**
 * Defining propTypes
 */


export default GoogleLoginButtonDefault;