import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import OwlCarousel from "react-owl-carousel2";
import { toast } from 'react-toastify';
import { homeGovernorateList } from '../../services/homePageServices';
import PropertyCityCard from './PropertyCityCard';
import { Empty, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const PropertiesCities = () => {

	const { t } = useTranslation()
	const { i18n } = useTranslation()

	const cookies = new Cookies()

	const navigate = useNavigate()

	const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';
	const CityOptions = {
		rtl: userLanguage === 'ar' ? true : false,
		items: 4,
		nav: true,
		rewind: true,
		autoplay: true,
		margin: 30,
		// responsiveClass: true,
		responsive: {
			0: {
				items: 1,
				nav: true
			},
			600: {
				items: 1,
				nav: false
			},
			1000: {
				items: 4,
				nav: true,
				loop: false
			}
		}
	};
	const events = {
		onDragged: function (event) { },
		onChanged: function (event) { },
	};

	const [loading, setLoading] = useState(true)
	const [propertiesList, setPropertiesList] = useState([])

	// FETCH PROPERTIES BY CITIES
	const propertiesByCities = () => {
		setLoading(true)
		homeGovernorateList().then((response) => {
			setLoading(false)

			const citiesWithProp = response.filter((element) => element?.propertyCount > 0)

			// console.log(citiesWithProp)
			setPropertiesList(citiesWithProp)
		}).catch((error) => {
			setLoading(false)
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}
	// FETCH PROPERTIES FOR YOU
	useEffect(() => {
		propertiesByCities()
	}, [i18n?.language])

	return (
		<section className="pb60-md pt30 pb90">
			<div className="container">
				<div
					className="row align-items-center wow fadeInUp"
					data-wow-delay="300ms"
				>
					<div className="col-lg-9">
						<div className="main-title2">
							<h2 className="title">{t("PROPERTIES BY CITIES")}</h2>
						</div>
					</div>
				</div>
				{/* DISPLAY SKELETON WHILE LOADING */}
				{
					loading === true ?
						<div className="row">
							{[...Array(4)].map((element, index) => {
								return <div key={index + 1} className="col-md-3">
									<Skeleton.Button active={true} size={"100%"} shape={"square"} block={true} style={{ height: "300px" }} />
								</div>
							})}
						</div> :
						propertiesList?.length > 0 ?
							<div className="row">
								<div className="col-lg-12 wow fadeInUp" data-wow-delay="300ms">
									<OwlCarousel
										className="property-city-slider dots_none slider-dib-sm slider-4-grid2 vam_nav_style owl-theme owl-carousel"
										options={CityOptions}
										events={events}
									>
										{

											propertiesList?.map((property, index) => (
												<div key={index + 1}>
													<PropertyCityCard propertyData={property} />
												</div>
											))
										}
									</OwlCarousel>
								</div>
							</div>
							:
							// IF SINGLE PROPERTY THEN DO NOT NEED TO DISPLAY SLIDER
							propertiesList?.length > 0 ?
								<div className="row properties-grid-cities">
									{
										propertiesList?.map((property, index) => (
											<div key={index + 1} className="col-lg-3  ">
												<PropertyCityCard propertyData={property} />
											</div>
										))
									}
								</div> : <Empty description={t("NO PROPERTIES FOUND")} />
				}
			</div>
		</section>
	)
}

export default PropertiesCities
