import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'


function PropertyDescription({ propertyData }) {

  const cookies = new Cookies()

  const { i18n } = useTranslation()

  const { t } = useTranslation()

  const userLanguage = i18n?.language ?? cookies?.get("userLangKey") ?? 'en'

  const [visibleDetails, setVisibleDetails] = useState([])

  useEffect(() => {
    if (propertyData?.propertyType?.propertyDetail) {

      const visibleData = propertyData?.propertyType?.propertyDetail
        .filter(element => element?.visible === true)
        .map(element => {
          const fieldName = element?.field_name;
          let fieldValue = fieldName ? propertyData?.propertyDetail?.[fieldName] : null;
          if (fieldValue != null) {
            if (["areaSqm", "landAreaSqm", "gardenSize"].includes(element?.field_name)) {
              fieldValue += ` ${t("Sq.M.")}`;
            } else if (element?.field_name === "floorNumber") {
              switch (propertyData?.propertyDetail["floorNumber"]) {
                case -1:
                  fieldValue = t("BASEMENT");
                  break;
                case 0:
                  fieldValue = t("GROUND FLOOR");
                  break;
                default:
                  fieldValue = propertyData?.propertyDetail["floorNumber"] + t("TH FLOOR");
              }
            } else if (element?.field_name === "yearOld") {
              fieldValue = moment().subtract(fieldValue, 'years').format('YYYY');
            } else if (element?.field_name === "garden" || element?.field_name === "furnished") {
              if (propertyData?.propertyDetail[element?.field_name] === true) {
                fieldValue = "Yes"
              } else {
                fieldValue = "No"
              }
            }
            return {
              label: element?.field_name === "yearOld" ? t("YEAR BUILT") : element?.field_level[userLanguage],
              value: fieldValue
            };
          }
        })
        .filter(Boolean);
      const detailsArr = [...visibleData,
      { label: `${t("PRICE")}`, value: t("JOD ") + propertyData?.priceInJod },
      { label: `${t("PROPERTY TYPE")}`, value: propertyData?.propertyType?.propertyType },
      { label: `${t("PROPERTY STATUS")}`, value: propertyData?.propertyOfferingType === 'BUY' ? t('FOR BUY') : t('FOR RENT') }]

      // Split the data array into two equal parts
      const halfIndex = Math.ceil(detailsArr.length / 2);
      const firstHalf = detailsArr.slice(0, halfIndex);
      const secondHalf = detailsArr.slice(halfIndex);

      setVisibleDetails([firstHalf, secondHalf])
    }
  }, [propertyData])

  const [open, setOpen] = useState(false) //SHOW MORE COLLAPSE STATE

  // SPLIT PROPERTY DESCRIPTION INTO TWO PARTS
  const [firstThreeLines, remainingLines] = propertyData?.description ? splitParagraph(propertyData?.description) : '';


  return (
    <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
      <h4 className="title fz17 mb30">
        <i className="fal fa-info-circle me-2" />
        {t('PROPERTY DESCRIPTION')}
      </h4>
      {/* PROPERTY DESCRIPTION */}
      <p className="text text-justify mb10">
        {firstThreeLines ?? ''}
      </p>
      {
        propertyData?.description && remainingLines ?
          <div className="agent-single-accordion">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div >
                <Collapse in={open} dimension="height">
                  <div id="flush-collapseOne">
                    <p className="text text-justify">
                      {remainingLines ?? ''}
                    </p>
                  </div>
                </Collapse>
              </div>
              <button
                onClick={() => setOpen(!open)}
                aria-controls="flush-collapseOne"
                aria-expanded={open}
                className="accordion-button  p-0 collapsed"
              >
                {
                  open ? t("SHOW LESS") : t('SHOW MORE')
                }
              </button>
            </div>
          </div> : ""
      }
      <h4 className="title fz17 mb30 mt50">{t('PROPERTY DETAILS')}</h4>
      <div className="row">
        <div className="col-md-6">
          {visibleDetails && visibleDetails?.length > 0 ? visibleDetails[0].map((item, index) => (
            <div key={index + 1} className="row">
              <div className="col-6 pd-list" key={index}>
                <p className="fw600 mb10 ff-heading dark-color">{item?.label}</p>
              </div>
              <div className="col-6 pd-list">
                <p className="text mb10"> {item?.value}</p>
              </div>
            </div>
          )) : ""}
        </div>
        <div className="col-md-6">
          {visibleDetails && visibleDetails?.length > 1 ? visibleDetails[1].map((item, index) => (
            <div key={index + 1} className="row">
              <div className="col-6 pd-list" key={index}>
                <p className="fw600 mb10 ff-heading dark-color">{item?.label}</p>
              </div>
              <div className="col-6 pd-list">
                <p className="text mb10">{item?.value}</p>
              </div>
            </div>
          )) : ""}
        </div>
      </div>

    </div>
  )
}

export default PropertyDescription

function splitParagraph(paragraph) {

  const lines = paragraph?.split('. ');
  const firstThreeLines = lines.slice(0, 3).join('. ') + '.';
  const remainingLines = lines.slice(3).join('. ');
  return [firstThreeLines, remainingLines];
}
