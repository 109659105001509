import React, { useState } from 'react'
import { Select } from "antd";
import AdvanceSearchModal from '../modal/AdvanceSearchModal';
import { useNavigate } from 'react-router-dom';
import { constructUrl, manipulateUrlParams } from '../../services/propertyService';
import { nameBasedRoutes } from '../../router/public';
import LocationSearchInput from '../Property/LocationSearchInput';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
const HomeBanner = () => {
	const navigate = useNavigate()

	const { t } = useTranslation()

	const { i18n } = useTranslation()
	const cookies = new Cookies()

	const currentLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

	const [selectedPlace, setSelectedPlace] = useState(null)

	const [advanceMenuOpen, setAdvanceMenuOpen] = useState(false)
	const [propertyTab, setPropertyTab] = useState("BUY")
	const [propCategoryList, setPropCategoryList] = useState([])
	const [selectedPropCategory, setSelectedPropCategory] = useState('')
	// NAVIGATE PATH
	const navigatePath = nameBasedRoutes?.propertyList?.path;
	// HANDLE SEARCH BUTTON FOR PROPERTY FILTER
	const handleSearchButton = () => {
		let removeParams;
		const params = []
		// FILTER PROPERTY TAB 
		if (propertyTab) {
			params.push({ key: "propertyOfferingType", value: propertyTab })
		}
		// FILTER PROPERTY CATEGORY
		if (selectedPropCategory) {
			params.push({ key: "propertyCategory", value: selectedPropCategory })
		} else {
			// REMOVE IF NOT FOUND
			removeParams = "propertyCategory"
		}
		// SELECTED PLACE
		if (selectedPlace) {
			params.push({ key: "formattedAddress", value: selectedPlace?.formatted_address })
		} else {
			removeParams = "formattedAddress"
		}
		// NAVIGATE ON PROPERTY PAGE 
		const navigateURl = manipulateUrlParams(navigatePath, params, removeParams)
		navigate(navigateURl)
	}


	const handleOnEnter = () => {

		// NAVIGATE ON PROPERTY PAGE 
		const params = [];
		if (selectedPlace) {
			params.push({ key: "formattedAddress", value: selectedPlace?.formatted_address })
			const navigateURl = constructUrl(navigatePath, params)
			navigate(navigateURl)
		}


	}

	return (
		<section className="home-banner-style2 p0">
			<div className="home-style2">
				<div className="container maxw1600">
					<div className="home2-hero-banner2 bdrs12 animate-up-3" />
					<div className="row">
						<div className="col-xl-10 mx-auto">
							<div className="inner-banner-style2 text-center">
								<div className="advance-style2 mt100 mt0-md mb60 mb30-md mx-auto animate-up-1">
									<ul className="nav nav-tabs p-0">
										<li className="nav-item">
											<button
												className={`nav-link ${propertyTab === 'BUY' ? 'active' : ''}`}
												onClick={() => setPropertyTab("BUY")}
											>
												{t("BUY")}
											</button>
										</li>
										<li className="nav-item">
											<button
												className={`nav-link ${propertyTab === 'RENT' ? 'active' : ''}`}
												onClick={() => setPropertyTab("RENT")}
											>
												{t("RENT")}
											</button>
										</li>
									</ul>
									<div className="tab-content" id="myTabContent">
										<div
											className="tab-pane fade show active"
											id="home"
											role="tabpanel"
											aria-labelledby="home-tab"
										>
											<div className="advance-content-style2">
												<div className="row align-items-center justify-content-start justify-content-md-center">
													<div className="col-md-5 col-lg-6">
														<div className="advance-search-field position-relative text-start bdrr1 bdrrn-sm bb1-sm">
															<div
																className="form-search position-relative"
																acceptCharset="utf-8"
															>
																<div className="box-search">
																	<span className="icon flaticon-home-1" />
																	<LocationSearchInput
																		placeholder={t("ENTER LOCATION NAME / AREA NAME")}
																		setSelectedPlace={setSelectedPlace}
																		navigatePath={navigatePath}
																		isHome={true}
																		handleEnter={handleOnEnter}
																	/>
																</div>
															</div>
														</div>
													</div>
													<div className="col-md-3 col-lg-3 ps-md-0">
														<div className="bdrr1 bdrrn-sm pe-0 pe-lg-3 bb1-sm">
															<div className="user-select">
																<Select
																	placeholder={"select"}
																	id="prop_category"
																	name="prop_category"
																	popupClassName={`${currentLanguage === 'ar' ? 'direction-rtl' : ''}`}
																	className="w-100"
																	value={selectedPropCategory}
																	onChange={(value) => { setSelectedPropCategory(value) }}
																	options={[
																		{
																			value: '', label: t("SHOW ALL TYPES"),
																		},
																		...propCategoryList
																	]}
																/>
															</div>
														</div>
													</div>
													<div className="col-md-4 col-lg-3  ">
														<div className="d-flex align-items-center justify-content-between  mt-2 mt-md-0">
															<button
																className="advance-search-btn"
																type="button"
																onClick={() => { setAdvanceMenuOpen(true) }}
															>
																<span className="flaticon-settings" />{" "}
																{t("ADVANCED")}
															</button>
															<button
																className="advance-search-icon ud-btn btn-thm "
																type="button"
																onClick={handleSearchButton}
															>
																<span className="flaticon-search" />
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<h1 className="hero-title animate-up-2">
									{t("FIND YOUR DREAM PROPERTY")}
								</h1>

								<p className="hero-text fz15 animate-up-3 d-none d-md-block">
									{t("AT AMAKEN... ")}
									{t("YOUR IDEAL REAL ESTATE MARKETPLACE IN JORDAN")}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AdvanceSearchModal isOpen={advanceMenuOpen} setModalOpen={setAdvanceMenuOpen} offeringType={propertyTab} setPropCategoryList={setPropCategoryList} propertyCategory={selectedPropCategory}
				propertyStatus={propertyTab} navigatePath={navigatePath} />
		</section>
	)
}
export default HomeBanner
