import { PROPERTY_INFO_API, PROPERTY_TYPE_LIST,AGENCY_AGENT_LIST_API ,GOVERNORATE_LIST_API} from "../constants/paths"
import { axios } from "./axios"

// PROPERTY INFO API FOR ADVANCE SEARCH MODAL FIELDS
export const propertyInfoApi = async()=>{
    const response = await axios.get(PROPERTY_INFO_API)
    return response?.data
}

// PROPERTY TYPE LIST API
export const propertyTypeListApi = async()=>{
    const response = await axios.get(PROPERTY_TYPE_LIST)
    return response?.data
}

// AGENCY / AGENT DROPDOWN LIST 
export const agencyAgentListApi = async (params)=>{
    const response = await axios.get(`${AGENCY_AGENT_LIST_API}`,{params})
    return response?.data  
}

// GOVERNORATE DROPDOWN LIST 
export const governorateListApi = async ()=>{
    const response = await axios.get(GOVERNORATE_LIST_API)
    return response?.data  
}