import React, { useState } from "react";
import UserInputText from "../common/UserInputText";
import { Formik, Form } from "formik";
import { userChangePassword } from "../../validations/ValidationSchema";
import { changePassword } from "../../services/userService";
import Loader from "../../components/common/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ChangePassword() {
  const [loading, setLoading] = useState();
  const navigate = useNavigate();

  const {t} = useTranslation()

  // SUBMIT FORM FOR CHANGE PASSWORD
  const handleSubmit = (values, actions) => {
    setLoading(true)
    const oldPassword = values.oldPassword;
    const newPassword = values.newPassword;
    // CHANGE PASSWORD API CALLING
    changePassword({
      currentPassword: oldPassword.toString(),
      newPassword: newPassword.toString(),
    })
      .then((response) => {
        setLoading(false);
        toast.success("Password updated!");
        navigate("/profile-user");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message
        );
      });
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="ps-widget bdr1 bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
          <h4 className="title fz17 mb30">{t('CHANGE PASSWORD')}</h4>
          <Formik
            validationSchema={userChangePassword}
            initialValues={{
              oldPassword:  "",
              newPassword: "",
              confirmPassword:  "",
            }}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              handleSubmit(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="form-style1">
                <div className="row">
                  <div className="col-sm-6 col-xl-4">
                    <UserInputText
                      type="password"
                      label={t("OLD PASSWORD")}
                      id="oldPassword"
                      placeholder={t("OLD PASSWORD")}
                      name="oldPassword"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values?.oldPassword}
                      className={`form-control ${
                        touched?.oldPassword && errors?.oldPassword
                          ? "error"
                          : ""
                      }`}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-xl-4">
                    <UserInputText
                      type="password"
                      id="newPassword"
                      name="newPassword"
                      label={t("NEW PASSWORD")}
                      placeholder={t("NEW PASSWORD")}
                      controlId={"newPassword"}
                      value={values?.newPassword}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      className={`form-control ${
                        touched?.newPassword && errors?.newPassword
                          ? "error"
                          : ""
                      }`}
                    />
                  </div>
                  <div className="col-sm-6 col-xl-4">
                    <UserInputText
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      label={t("CONFIRM NEW PASSWORD")}
                      placeholder={t("CONFIRM NEW PASSWORD")}
                      controlId={"confirmPassword"}
                      value={values?.confirmPassword}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-end">
                      <button
                        className="ud-btn btn-dark"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {t("CHANGE PASSWORD")}
                        <i className="fal fa-arrow-right-long" />
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

export default ChangePassword;
