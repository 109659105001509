import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import Form from "react-bootstrap/Form";
const Datepicker = ({ date, name, handleDateChange, minDate, maxDate, slotProps, placeholder, label, errorsField, touched, value, minDateTime,handleError }) => {
  // const [value] = React.useState(dayjs(date));
 
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DemoContainer
          components={['DateTimePicker']}
          minDate={minDate}
          maxDate={maxDate}
          slotProps={{ textField: { placeholder: placeholder } }}
          placeholder={placeholder}
          showToolbarPlaceholder
        >
          <DateTimePicker
            label={label}
            onChange={handleDateChange}
            controlId={name}
            id={name}
            name={name}
            onError={handleError}
            minDateTime={minDateTime}
            value={value}
            error={errorsField && touched}
            helperText={errorsField && touched ? errorsField : ""} />
        </DemoContainer>
      </LocalizationProvider>
      <p className='text-danger'>{errorsField}</p>
      <Form.Control.Feedback >
        {errorsField}
      </Form.Control.Feedback>
    </div>

    //   <LocalizationProvider dateAdapter={AdapterDayjs}>
    //   <DatePicker

    // name={name}
    // value={value}
    // onChange={handleDateChange}
    // minDate={minDate}
    // maxDate={maxDate}
    // slotProps={{ textField: { placeholder: placeholder } }}
    // placeholder={placeholder}
    // showToolbarPlaceholder
    //   />
    // </LocalizationProvider>
  );
}

export default Datepicker;