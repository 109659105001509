import React from 'react'
import { Helmet } from 'react-helmet'

const SeoComponent = ({ isDefault, title, meta_title, description, keywords, canonical }) => {

    if (isDefault === true) {
        return (
            <Helmet>
                <title>Amaken</title>
                {/* <meta name="title" content={"Amaken"} />
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} /> */}
                {canonical && <link rel="canonical" href={canonical} />}
            </Helmet>)
    }
    return (
        <Helmet>
            {title && <title>{title}</title>}
            {meta_title && <meta name="title" content={meta_title} />}
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords} />}
            <link rel="canonical" href={canonical} />
        </Helmet>
    )
}

export default SeoComponent