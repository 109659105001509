import React, { useEffect, useState } from 'react'
import { Modal } from "antd"
import { Formik, Form } from 'formik';
import UserSelect from '../common/UserSelect';
import UserInputText from '../common/UserInputText';
import UserTextArea from '../common/UserTextArea';
import { generalInfoApi } from '../../services/userService';
import { toast } from 'react-toastify';
import InputPhoneCode from '../common/InputPhoneCode';
import { useTranslation } from 'react-i18next';
import { reportPropertyValidationSchema } from '../../validations/ValidationSchema';
import { reportPropertyApi } from '../../services/propertyService';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
function ReportProperty() {

	const { id } = useParams()

	const [isModalOpen, setModalOpen] = useState(false)

	const userData = useSelector((state) => state?.user?.userData)

	const [complaintReasons, setComplaintReasons] = useState([])

	const [loading, setLoading] = useState(false)

	const { t } = useTranslation()

	const { i18n } = useTranslation()

	const cookies = new Cookies()
	const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

	const handleOk = () => {
		setModalOpen(false);
	};
	const handleCancel = () => {
		setModalOpen(false);
	};

	// FETCH REASONS LIST FROM GENERAL INFO
	const fetchGeneralInfo = () => {
		generalInfoApi().then((response) => {
			const reasonList = Object.entries(response?.complaintReasonMap).map(([value, label]) => ({ value, label }))
			setComplaintReasons(reasonList ?? [])
			setModalOpen(false)
		}).catch((error) => {
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}

	useEffect(() => {
		fetchGeneralInfo()
	}, [])

	const handleSubmit = (values, actions) => {

		setLoading(true)

		const formData = {
			propertyId: id,
			complaintReason: values?.complaintReason,
			name: userData?.firstName && userData?.lastName ? userData?.firstName + " " + userData?.lastName : values?.name,
			countryCode: userData?.countryCode ? userData?.countryCode : values?.countryCode && values?.countryCode !== '' ? values?.countryCode : null,
			phoneNumber: userData?.mobileNumber ? userData?.mobileNumber : values?.phoneNumber && values?.phoneNumber !== '' ? values?.phoneNumber : null,
			email: userData?.email ? userData?.email : values?.email,
			message: values?.message
		}
		reportPropertyApi(formData).then((response) => {
			setLoading(false)
			toast.success(response?.message)
			actions.resetForm()
			// actions.setFieldValue("message","")
			setModalOpen(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}

	return (
		<div>
			<div className="agen-personal-info position-relative bgc-white default-box-shadow1 bdrs12 p30 mb30-md">
				<div className="widget-wrapper mb-0">
					<h6 className="title fz17 mb30">{t("DO YOU HAVE ANY PROBLEMS?")}</h6>
					<div className="d-grid">
						<button className="ud-btn btn-white2" onClick={() => setModalOpen(true)}>{t("REPORT THIS PROPERTY")}<i className="fal fa-flag me-2"></i></button>
					</div>
				</div>
			</div>
			<Modal footer={false} height={1000} title={<h5 className='modal-title'>{t("REPORT THIS PROPERTY")}</h5>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className={`${userLanguage === 'ar' ? 'direction-rtl' : ''}`} >
				<div>
					<Formik initialValues={{
						complaintReason: undefined,
						name: "",
						countryCode: "962",
						phoneNumber: "",
						email: "",
						message: ""
					}}
						validationSchema={reportPropertyValidationSchema}
						onSubmit={(values, actions) => {
							actions.setSubmitting(false);
							handleSubmit(values, actions);
						}}
					>
						{({ handleSubmit,
							handleChange,
							handleBlur,
							values,
							setFieldValue,
							touched,
							isValid,
							errors, }) => (
							<Form className="form-style1">
								<div className="row">
									<UserSelect
										variant="outlined"
										value={values?.complaintReason}
										name="complaintReason"
										maxLength="300"
										errorsField={errors?.complaintReason}
										touched={touched?.complaintReason}
										handleChange={(values) => { setFieldValue("complaintReason", values) }}
										handleBlur={handleBlur}
										className="form-control"
										placeholder={t("SELECT A REASON")}
										options={complaintReasons ?? []}
									/>
								</div>
								{
									!userData || userData === null ?
										<div className="row">
											<div className="col-md-12">
												<UserInputText controlId="name"
													// label="Name"
													type="text"
													placeholder={t("ENTER YOUR NAME")}
													variant="outlined"
													value={values?.name}
													name="name"
													errorsField={errors?.name}
													touched={touched?.name}
													handleChange={handleChange}
													handleBlur={handleBlur}
													className="form-control" />
											</div>
										</div> : ""
								}

								{
									!userData || userData === null ?
										<div className="col-lg-12">
											<div className="row ">
												<div className="col-md-5 col-xl-5 mt10">
													<InputPhoneCode
														id="countryCode"
														controlId="countryCode"
														name="countryCode"
														type="number"
														country="jo"
														placeholder={t("CODE")}
														handleChange={(code) => { setFieldValue("countryCode", code) }}
														handleBlur={handleBlur}
														value={values?.countryCode}
														className={` ${touched?.countryCode && errors?.countryCode ? "error" : ""
															}`}
													/>
												</div>

												<div className="col-md-7 col-xl-7">
													<UserInputText
														controlId="phoneNumber"
														type="number"
														placeholder={t("PHONE")}
														variant="outlined"
														value={values?.phoneNumber}
														name="phoneNumber"
														maxLength="300"
														errorsField={errors?.phoneNumber}
														touched={touched?.phoneNumber}
														handleChange={handleChange}
														handleBlur={handleBlur}
														className="form-control" />
												</div>
											</div>

										</div> : ""
								}

								{
									!userData || userData === null ? <div className="col-md-12">
										<UserInputText controlId="email"
											// label="Email"
											placeholder={t("ENTER YOUR EMAIL")}
											type="email"
											variant="outlined"
											value={values?.email}
											name="email"
											errorsField={errors?.email}
											touched={touched?.email}
											handleChange={handleChange}
											handleBlur={handleBlur}
											className="form-control" />
									</div> : ""
								}


								<div className="col-md-12">
									<UserTextArea controlId="message"
										// label="Message"
										placeholder={t("ENTER YOUR MESSAGE")}
										variant="outlined"
										value={values?.message}
										name="message"
										errorsField={errors?.message}
										touched={touched?.message}
										handleChange={(event) => { setFieldValue("message", event?.target?.value) }}
										handleBlur={handleBlur}
										rows={4}
										cols={30}
									/>
								</div>
								<div className="col-md-12">
									<div className="d-grid">
										<button type="submit" onClick={handleSubmit} className="ud-btn btn-white2" disabled={loading}>{t("SEND REPORT")} <i className="fal fa-arrow-right-long"></i></button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</div>
	)
}

export default ReportProperty
