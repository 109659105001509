import React, { useEffect, useState } from 'react'
import AdvanceSearchModal from '../modal/AdvanceSearchModal'

import QuickFilter from '../Property/QuickFilter'
import { nameBasedRoutes } from '../../router/public';
import LocationSearchInput from '../Property/LocationSearchInput';
import { useNavigate } from 'react-router-dom';
import { constructUrl, removeSearchParams } from '../../services/propertyService';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { removeAllProperties } from '../../redux/comparePropertySlice';
function SearchFilterMenu({ setSelectedPlace, selectedPlace, selectedAddress }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const compareProps = useSelector((state) => state?.compareProps);
  const [advanceMenuOpen, setAdvanceMenuOpen] = useState(false);

  const navigatePath = nameBasedRoutes?.searchByMap?.path;

  useEffect(() => {
    if (selectedPlace !== null && (selectedPlace?.formatted_address || selectedAddress)) {
      // Construct URL with formatted address
      const formattedAddress = selectedPlace?.formatted_address || selectedAddress;
      const navigateURL = constructUrl(navigatePath, { key: "formattedAddress", value: formattedAddress });

      // Navigate with replace to prevent redundant history entries
      navigate(navigateURL, { replace: true });
    } else {
      // Remove formattedAddress from URL if no valid value is present
      const navigateURL = removeSearchParams(navigatePath, "formattedAddress");
      navigate(navigateURL, { replace: true });
    }
  }, [selectedPlace,selectedAddress]);

  // selectedAddress, navigate, navigatePath

  // HANDLE PROPERTIES COMPARE CLICK
  const handleCompareClick = () => {
    navigate(nameBasedRoutes?.compareProperties?.path, { replace: true });
  };

  // HANDLE REMOVE PROPERTY COMPARE 
  const handleRemoveAllCompare = () => {
    dispatch(removeAllProperties());
  };

  return (
    <section className="advance-search-menu bg-white position-relative default-box-shadow2 pt15 pb5 bb1 dn-992">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="advance-search-list no-box-shadow d-flex justify-content-start d-none d-lg-flex">
              <div className="dropdown-lists me-2">
                <ul className="p-0 mb-0">
                  <li className="list-inline-item position-relative">
                    <LocationSearchInput
                      className="search-field area-search-field"
                      value={selectedAddress}
                      placeholder={t("ENTER LOCATION NAME / AREA NAME")}
                      setSelectedPlace={setSelectedPlace}
                      navigatePath={navigatePath}
                    />
                  </li>
                </ul>
              </div>

              {/* QUICK FILTER MENU */}
              <div className='row w-100 justify-content-md-between align-items-start'>
                <div className="col-md">
                  <QuickFilter navigatePath={navigatePath} />
                </div>
                {
                  compareProps?.length > 1 ?
                    <div className="col-md-auto">
                      <ul className="p-0 mb-0 text-end">
                        <li className="list-inline-item">
                          <button className='ud-btn p-2 btn-thm me-2 my-1' onClick={handleCompareClick}>{t("COMPARE")}</button>
                          <button className='ud-btn p-2 mx-1 btn-dark my-1' onClick={handleRemoveAllCompare}>{t("REMOVE ALL")}</button>
                        </li>
                      </ul>
                    </div>
                    : ""
                }
              </div>
            </div>
            <div className="advance-search-list no-box-shadow d-flex justify-content-center d-block d-lg-none">
              <div className="dropdown-lists">
                <ul className="p-0 mb-0">
                  <li className="list-inline-item">
                    {/* Advance Features modal trigger */}
                    <button
                      type="button"
                      className="open-btn mb5"
                      onClick={() => { setAdvanceMenuOpen(true) }}
                    >
                      <i className="flaticon-settings me-2" /> {t("ADVANCED")}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ADVANCED SEARCH MODAL ON  */}
      <AdvanceSearchModal isOpen={advanceMenuOpen} setModalOpen={setAdvanceMenuOpen} navigatePath={navigatePath} />
    </section>
  )
}

export default SearchFilterMenu
