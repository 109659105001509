import { createSlice } from "@reduxjs/toolkit";
import storage from "../helpers/storage";
import { getUserDataApi } from "../services/userService";
import Cookies from "universal-cookie";
let initalToken = storage.getToken();
const cookies = new Cookies();

// An asynchronous function to fetch user data
const fetchUserInitialData = async () => {
  try {
    if (initalToken) {
      const response = await getUserDataApi();
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

// Fetch the user initial data before creating the slice
const userInitialData = await fetchUserInitialData();

const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: userInitialData ?? null,
    token: initalToken ?? null,
  },
  reducers: {
    setUser: (state, action) => {
      state.userData = action.payload.userData;
      if(action?.payload?.token){
        state.token = action.payload.token;
      }
    },
    updateUserImage : (state,action)=>{
      state.userData.imageUrl = action?.payload?.imageUrl
    },
    updateUserPhoneNO : (state , action)=>{
       state.userData.mobileNumber = action?.payload?.mobileNumber
      state.userData.mobileNumberVerified = action?.payload?.mobileNumberVerified
      state.userData.countryCode = action?.payload?.countryCode
    },
    removeUserImage : (state)=>{
      state.userData.imageUrl = ""
    },
    logoutUser: (state) => {
    //  add storage here
      state.userData = null;
      state.token = null;
    },
  },
});

export const { setUser, logoutUser,updateUserImage,removeUserImage, updateUserPhoneNO} = userSlice?.actions;
export default userSlice?.reducer;
