import { configureStore,combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userProfileSlics";
import layoutReducer from "./layoutSlice";
import generalInfoSlice from "./generalInfoSlice";
import comparePropsReducer from "./comparePropertySlice" 


const rootReducer = combineReducers({
    user: userReducer,
    layout: layoutReducer,
    generalInfo : generalInfoSlice,
    compareProps : comparePropsReducer
  });
  
const store = configureStore({
    reducer : rootReducer
})

export default store;