import React from 'react'
import { Accordion } from 'react-bootstrap'
import { FloorNoDropdownList } from '../../assets/jsonData/staticData'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'

const FloorPlans = ({ floorData }) => {

    const { i18n } = useTranslation()

    const cookies = new Cookies()
    const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';
    const floorList = FloorNoDropdownList?.map((floor) => ({ label: floor?.label[userLanguage], value: floor?.value }))
    return (
        <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <h4 className="title fz17 mb30"><i className="fal fa-pencil-ruler me-2"></i>Floor Plans</h4>
            <div className="row">
                <div className="col-md-12">
                    <div className="accordion-style1 style2">
                        <Accordion>
                            {
                                floorData?.map((floor, index) => {
                                    const floorTitle = floorList.find(f => f.value === floor?.floorNumber);

                                    return <Accordion.Item key={index + 1} eventKey={index}>
                                        <Accordion.Header>
                                            <span className="w-100 d-md-flex align-items-center">
                                                <span className="mr10-sm">{floorTitle?.label}</span>
                                            </span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="accordion-body">
                                                <img className="w-100" src={floor?.imageName} alt="floorImg" />
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                })
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FloorPlans