import React, { useEffect } from "react"
import "react-toastify/dist/ReactToastify.css"
import "./App.scss"
import { useLocation, useNavigate } from "react-router-dom"
import AppRoutes from "./router"
import { ToastContainer } from "react-toastify"
import storage from "./helpers/storage";
import { refreshTokenCALL } from "./services/authService"
import { nameBasedProtectedRoutes } from "./router/protected";
import { nameBasedRoutes } from "./router/public";
import { useTranslation } from "react-i18next"
import Cookies from "universal-cookie"
// GOOGLE ANALYTICS
import ReactGA4 from 'react-ga4';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import CssBaseline from '@mui/material/CssBaseline';


// import "./assets/css/bootstrap.min.css"

// const customTheme = createTheme({
//   direction: 'rtl',
//   typography: {
//     fontFamily: '"DM Sans", sans-serif',
//     htmlFontSize: 17,
//   },
//   palette: {
//     primary: {
//       main: '#17B0B2',
//     },
//     text: {
//       primary: '#000000',
//       secondary: '#777777',
//     }
//   }
// });

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

// const theme = createTheme({
//   direction: 'rtl',
// });

const theme = createTheme({
  direction: 'rtl', // Ensure RTL support
});

function App() {


  const { i18n } = useTranslation()

  const cookies = new Cookies()
  const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

  const location = useLocation();
  const navigate = useNavigate();

  const token = storage.getRefreshToken();
  const refreshTokenFn = () => {
    const tokenObj = {
      refreshToken: token || "",
    };

    refreshTokenCALL(tokenObj)
      .then((response) => {
        storage.setToken(response?.data?.tokens?.access?.token);
        storage.setRefreshToken(response?.data?.tokens?.refresh?.token);
        navigate(nameBasedProtectedRoutes?.userProfile?.path);
      })
      .catch((error) => {
        // setLoading(false);
        // toast.error(error?.response?.data?.message ?? error?.message);
        navigate(nameBasedRoutes?.home?.path);
      });
  };

  useEffect(() => {

    ReactGA4.initialize('G-7T9L0000M3');
    ReactGA4.send({ hitType: "pageview", page: window.location.href, title: location?.pathname });
  }, [location?.pathname]);


  useEffect(() => {

    // Function to add RTL CSS files dynamically
    const addRTLStyles = () => {

      const bootstrapLink = document.createElement('link');
      bootstrapLink.href = `/${process.env.REACT_APP_BASE_URL}assets/rtl/css/bootstrap.rtl.min.css`
      bootstrapLink.rel = 'stylesheet';
      bootstrapLink.dataset.dynamicCss = true; // Add custom attribute for cleanup
      document.head.appendChild(bootstrapLink);

      const customSpacingLink = document.createElement('link');
      customSpacingLink.href = `/${process.env.REACT_APP_BASE_URL}assets/rtl/css/ud-custom-spacing.css`
      customSpacingLink.rel = 'stylesheet';
      customSpacingLink.dataset.dynamicCss = true;
      document.head.appendChild(customSpacingLink);

      const styleLink = document.createElement('link');
      styleLink.href = `/${process.env.REACT_APP_BASE_URL}assets/rtl/css/style.css`;
      styleLink.rel = 'stylesheet';
      styleLink.dataset.dynamicCss = true;
      document.head.appendChild(styleLink);

    };

    // Function to remove dynamically added RTL CSS files
    const removeRTLStyles = () => {
      const dynamicCssLinks = document.querySelectorAll('[data-dynamic-css]');
      dynamicCssLinks.forEach(link => link.remove());
    };

    // Add or remove RTL CSS files based on user's language
    if (userLanguage === 'ar') {
      addRTLStyles();
    } else {
      removeRTLStyles();
    }

    // Clean up when the component unmounts or language changes
    return () => {
      removeRTLStyles();
    };

  }, [userLanguage]); // Trigger useEffect when language changes


  return (
    <>
      {userLanguage === 'ar' ? (
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRoutes />
          </ThemeProvider>
        </CacheProvider>
      ) : (
        <AppRoutes />
      )}
      <ToastContainer autoClose={5000} />
    </>

  )
}

export default App
