import { createSlice } from "@reduxjs/toolkit";

const propertyCompareSlice = createSlice({
    name : "compareProp",
    initialState : [],
    reducers : {
        addNewProperty : (state ,action)=>{
            state.push(action.payload?.newProp);
        },
        removeProperty : (state ,action)=>{
            return state.filter((element)=> element !== action?.payload?.propId )
        },
        removeAllProperties : (state)=>{
            return state = []
        }
    }
})

export const {addNewProperty ,removeProperty,removeAllProperties } = propertyCompareSlice?.actions

export default propertyCompareSlice?.reducer