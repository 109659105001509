import logoImg2 from "../../../assets/images/Amaken-logo2.svg"
import forgotBg from "../../../assets/images/icon/login-page-icon.svg"
import React, { useState } from 'react'
import InputText from "../../../components/common/InputText"
import { ForgotPasswordSchema } from '../../../validations/ValidationSchema';
import { forgotPasswordAPI } from '../../../services/userService';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from "formik"
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { nameBasedRoutes } from '../../../router/public';
import LoaderMu from "../../../components/common/LoaderMu";
import SeoComponent from "../../../components/seo/SeoComponent";


export const ForgotPassword = () => {

  const { t } = useTranslation()
  const [loading, setLoading] = useState()
  const navigate = useNavigate();

  // Submit onboarding handleChange
  const handleSubmit = async (value, actions) => {
    submitForm(value)
  }
  // SUBMIT FORM
  const submitForm = async (value) => {
    const email = value.email
    forgotPasswordAPI({
      email: email.toString(),
      panel: 'USER',
    })
      .then(response => {
        setLoading(false)
        toast.success(response.message)
        navigate("/forgot-password");
      })
      .catch(error => {
        setLoading(false)
        toast.error(error?.response?.data?.error_description ? error?.response?.data?.error_description : error?.response?.data?.message ?? error?.message)
      })
  }

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <LoaderMu loading={loading} />
      <SeoComponent
        isDefault={true}
      />
      <div className="wrapper ovh">
        <div className="body_content">
          {/* Our Compare Area */}
          <section className="our-compare pt60 pb60">
            <img className="login-bg-icon wow fadeInLeft" src={forgotBg} alt="Forgot bg" />
            <div className="container">
              <div className="row wow fadeInRight" data-wow-delay="300ms">
                <div className="col-lg-6">
                  <div className="log-reg-form signup-modal form-style1 bgc-white p50 p30-sm default-box-shadow2 bdrs12">
                    <div className="header-logo text-center mb40">
                      <Link to={nameBasedRoutes?.home?.path ?? "/"}>
                        <img className="mb25" src={logoImg2} alt="forgot logo" />
                      </Link>
                      <h2>{t('FORGET PASSWORD')}</h2>
                      <p className="text">
                        {t('ENTER YOUR EMAIL ADDRESS TO RESET YOUR PASSWORD.')}
                      </p>
                    </div>
                    <Formik
                      validationSchema={ForgotPasswordSchema}
                      initialValues={
                        {
                          email: "",
                        }
                      }
                      onSubmit={(values, actions) => {
                        actions.setSubmitting(false)
                        handleSubmit(values, actions)
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        setFieldValue,
                        touched,
                        isValid,
                        errors
                      }) => (<Form>
                        {/* ENTER EMAIL */}
                        <div className="mb5">
                          <InputText
                            controlId="email"
                            label={t("ENTER EMAIL")}
                            value={values.email}
                            name="email"
                            maxLength="300"
                            errorsField={errors.email}
                            touched={touched.email}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            className="form-control"
                          />
                          <span className="form-label fz12 mb0 fw600 mx10 text-danger" />
                        </div>
                        {/* SUBMIT BUTTON */}
                        <div className="d-grid mb20">
                          <button
                            onClick={handleSubmit}
                            className="ud-btn btn-thm"
                            type="submit"
                          >
                            {t('GET RESET LINK')}
                            <i className="fal fa-arrow-right-long" />
                          </button>
                        </div>
                        <div className="d-grid mb20">
                          <Link
                            className="ud-btn btn-white"
                            type="button"
                            to={nameBasedRoutes?.home?.path}
                          >
                            {t('BACK')} <i className="fal fa-arrow-left-long" />
                          </Link>
                        </div>
                      </Form>)}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
