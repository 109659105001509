import { axios } from "./axios"
import {
    AGENCY_LIST_API,
    AGENCY_PROPERTY_LISTING_API,
    SINGLE_AGENCY_API,
    AGENT_DETAIL_API,
    AGENT_PROPERTY_LISTING_API,
    AGENT_CONTACT_API,
    AGENCY_CONTACT_API,
    AGENCY_VIEW_API,
    AGENT_VIEW_API
} from "../constants/paths"

// agency LISTING
export const getAgencyListingData = async (params) => {
    const response = await axios.get(AGENCY_LIST_API, { params })
    return response
}

// Agency detail
export const getAgencyById = async (id) => {
    const response = await axios.get(`${SINGLE_AGENCY_API}/${id}`)
    return response?.data
}

// Agency view
export const agencyViewCountApi = async (id) => {
    const response = await axios.get(`${AGENCY_VIEW_API}/${id}`)
    return response?.data
}


// PROPERTY LISTING FOR AGENCY
export const getPropertyListing = async (id, params) => {
    const response = await axios.get(`${AGENCY_PROPERTY_LISTING_API}/${id}`, { params })
    return response?.data
}
// Agent signle detail 
export const getAgentDetail = async (id) => {
    const response = await axios.get(`${AGENT_DETAIL_API}/${id}`)
    return response?.data
}
// Agent view
export const agentViewCountApi = async (id) => {
    const response = await axios.get(`${AGENT_VIEW_API}/${id}`)
    return response?.data
}
// PROPERTY LISTING FOR AGENT
export const getPropertyListingAgent = async (id, params) => {
    const response = await axios.get(`${AGENT_PROPERTY_LISTING_API}/${id}`, { params })
    return response?.data
}
// CONTACT US FORM AGENCY API
export const contactUsFormAPI = async (data) => {
    const response = await axios.post(AGENCY_CONTACT_API, data);
    return response?.data
};
// CONTACT US FORM AGNT API
export const contactUsFormAgentAPI = async (data) => {
    const response = await axios.post(AGENT_CONTACT_API, data);
    return response?.data
};