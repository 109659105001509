import React, { useState, useEffect } from 'react';

const Timer = ({ isTimerRunning, setIsTimerRunning }) => {
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let intervalId;

        // Function to update the timer every second
        const updateTimer = () => {
            if (seconds > 0) {
                setSeconds((prevSeconds) => prevSeconds - 1);
            } else if (minutes > 0) {
                setMinutes((prevMinutes) => prevMinutes - 1);
                setSeconds(59);
            } else {
                clearInterval(intervalId); // Stop the timer when it reaches 0
                setIsTimerRunning(false)
            }
        };

        // Start the timer

        if (isTimerRunning) {
            intervalId = setInterval(updateTimer, 1000);
        }

        // Cleanup function to clear the interval when the component is unmounted
        return () => {
            clearInterval(intervalId);
        };
    }, [minutes, seconds]);

    return (
        <div className='me-2'>
            <p className='mb-0'>Didn't Received OTP : {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</p>
        </div>
    );
};

export default Timer;
