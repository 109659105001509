import { Modal } from 'antd'
import { TabPanel, TabView } from 'primereact/tabview'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import Cookies from 'universal-cookie'
function PropertyVideo({ propertyData }) {

  const playerRef = useRef()


  const { t } = useTranslation()


  const { i18n } = useTranslation()
  const cookies = new Cookies()

  const currentLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

  const [activeTab, setActiveTab] = useState(0);
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [previewVideoUrl, setPreviewVideoUrl] = useState('')
  const [shouldCloseModal, setShouldCloseModal] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false)

  const [mainImage, setMainImage] = useState(null)


  useEffect(() => {
    if (propertyData?.propertyImages) {
      const mainImage = propertyData?.propertyImages?.find((image) => image?.mainImage === true)

      setMainImage(mainImage)
    }

  }, [propertyData])

  // SET ACTIVE TAB INDEX
  useEffect(() => {
    if (!propertyData?.videoSource && propertyData?.videoSourceFull) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  }, [propertyData]);

  // HANDLE VIDEO PREVIEW
  const handleVideoPreview = (videoUrl) => {
    setPreviewVideoUrl(videoUrl);
    setVideoModalOpen(true);
  };

  const handleCancel = () => {
    if (isPlaying) {
      setIsPlaying(false);
      setShouldCloseModal(true); // Set shouldCloseModal to true when canceling
    } else {
      setVideoModalOpen(false);
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
    setShouldCloseModal(false); // Ensure shouldCloseModal is false when pausing
  };

  useEffect(() => {

    if (!isPlaying && shouldCloseModal) {
      setVideoModalOpen(false);
      setShouldCloseModal(false); // Reset shouldCloseModal after closing the modal
    }
  }, [isPlaying, shouldCloseModal]);

  return (
    <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
      <h4 className="title fz17 mb30">
        <i className="fal fa-play me-2" />
        {t("VIDEO")}
      </h4>
      <div className="navtab-style1 tabView" >
        <TabView
          className="pr_tabView"
          activeIndex={activeTab}
          onTabChange={(e) => {
            setActiveTab(e?.index);
          }}
        >
          {
            propertyData?.videoSource ?
              <TabPanel
                header={t("INTRO VIDEO")}
                headerClassName="pr_tabHeader me-2"
              >
                <div className="property_video bdrs12 w-100">
                  {
                    mainImage ? <img src={mainImage?.imageName} alt="main-image" /> : ""
                  }
                  {/* VIDEO SOURCE CAN BE EMBEDDED OR FILE */}
                  {
                    propertyData?.videoSource === "EMBEDDED" ?
                      <button
                        className="video_popup_btn mx-auto popup-img popup-youtube border-none"
                        onClick={() => { handleVideoPreview(propertyData?.embeddedVideoUrl) }}
                      >
                        <span className="flaticon-play" />
                      </button> :
                      // IN CASE OF FILE
                      <button
                        className="video_popup_btn mx-auto popup-img popup-youtube border-none"
                        onClick={() => { handleVideoPreview(propertyData?.video) }}
                      >
                        <span className="flaticon-play" />
                      </button>
                  }
                </div>
              </TabPanel> : ""
          }
          {
            propertyData?.videoSourceFull ?
              <TabPanel
                header={t("FULL VIDEO")}
                headerClassName="pr_tabHeader me-2"
              >
                <div className="property_video bdrs12 w-100">
                  {
                    mainImage ? <img src={mainImage?.imageName} alt="main-image" /> : ""
                  }
                  {
                    propertyData?.videoSourceFull === "EMBEDDED" ?
                      <button
                        className="video_popup_btn mx-auto popup-img popup-youtube border-none"
                        onClick={() => { handleVideoPreview(propertyData?.embeddedVideoUrlFull) }}
                      >
                        <span className="flaticon-play" />
                      </button> : <button
                        className="video_popup_btn mx-auto popup-img popup-youtube border-none"
                        onClick={() => { handleVideoPreview(propertyData?.videoFull) }}
                      >
                        <span className="flaticon-play" />
                      </button>
                  }
                </div>
              </TabPanel> : ""
          }
        </TabView>
      </div>
      <Modal
        type="confirm"
        title=" "
        open={videoModalOpen}
        footer={false}
        maskClosable={false}
        onOk={handlePause}
        onCancel={handleCancel}
        className={`${currentLanguage === 'ar' ? 'direction-rtl' : ''} videoModal`}
        width={1000}
      >
        <div>
          <ReactPlayer
            ref={playerRef}
            playing={isPlaying}
            url={previewVideoUrl ?? ''}
            onPlay={() => setIsPlaying(true)}
            onPause={handlePause}
            controls
            width="100%"
            height="500px"
          />
        </div>
      </Modal>
    </div>
  )
}

export default PropertyVideo