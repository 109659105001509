import React from "react";
import { FormLabel } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";

function UserInputText({
  controlId, 
  type,
  label,
  disabled,
  placeholder,
  handleChange,
  handleBlur,
  value,
  rightIcon,
  size,
  name,
  isPasswordHintVisible,
  className,
  maxLength,
  max,
  multiline,
  readOnly,
  ...rest
}) {
  return (
    <div className="mb20">
    {
      label ?    <FormLabel className="heading-color ff-heading fw600 mb10">
        {label}
      </FormLabel> : ""
    }
   
      <Field
        type={type ?? "text"}
        placeholder={placeholder}
        id={name}
        readOnly={readOnly}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        className={className}
        disabled={disabled}
      />
      
        <ErrorMessage name={name} component="div" className="text-danger"/> 
     
    </div>
  );
}

export default UserInputText;
