export const arabicLang = {
    "SELECT": "اختيار",
    "SUBMIT": "إرسال",
    "USERNAME": "اسم المستخدم",
    "PASSWORD": "كلمة المرور",
    "CHOOSE PASSWORD": "اختر كلمة المرور",
    "CONFIRM PASSWORD": "تأكيد كلمة المرور",
    "FIRST NAME": "الاسم الأول",
    "LAST NAME": "اسم العائلة",
    "EMAIL": "البريد الإلكتروني",
    "EMAIL ADDRESS": "البريد الإلكتروني",
    "AGENCY": "شركة عقارية",
    "ADDRESS": "عنوان",
    "YOUR ADDRESS": "عنوانك",
    "FACEBOOK URL": "رابط فيسبوك",
    "INSTAGRAM URL": "رابط انستغرام",
    "LINKEDIN URL": "رابط لينكدإن",
    "MANAGE ACCOUNT": "إدارة الحساب",
    "MY PROFILE (AGENT)": "حسابي (المستشار العقاري)",
    "MY PROFILE (AGENCY)": "ملف الشركة",
    "MENU": "القائمة",
    "NEED SUPPORT?": "بحاجة للمساعدة؟",
    "DISCOVER JORDAN CITIES": "استكشف مدن الأردن",
    "POPULAR SEARCH": "بحث شائع",
    "QUICK LINKS": "روابط سريعة",
    "ABOUT AMAKEN": "عن أماكن",
    "OUR SERVICES": "خدماتنا",
    "TERMS & CONDITION": "الشروط والأحكام",
    "PRIVACY POLICY": "سياسة الخصوصية",
    "FAQS": "الأسئلة الشائعة",
    "DOWNLOAD AMAKEN APP": "تحميل تطبيق أماكن",
    "DOWNLOAD ON THE": "تحميل على",
    "APPLE STORE": "متجر أبل",
    "GET IN ON": "الحصول عليه من",
    "GOOGLE PLAY": "جوجل بلاي",
    "FOLLOW US": "تابع أماكن عبر",
    "AMAKEN - ALL RIGHTS RESERVED": "أماكن - جميع الحقوق محفوظة",
    "PRIVACY": "الخصوصية",
    "TERMS": "الشروط",
    "SITEMAP": "خريطة الموقع",
    "FIND YOUR DREAM PROPERTY": "إبحث عن عقار أحلامك هنا",
    "AT AMAKEN... ": "في أماكن...",
    "YOUR IDEAL REAL ESTATE MARKETPLACE IN JORDAN": "السوق العقاري الأمثل في الأردن",
    "PROPERTIES BY CITIES": "العقارات حسب المدن",
    "AMAKEN": "أماكن",
    "PROPERTIES FOR YOU": "خصيصاً لك",
    "BASED ON YOUR LATEST SEARCH PROCESS": "حسب آخر عمليات بحث قمت بها",
    "SEE MORE RESULTS": "شاهد المزيد من النتائج",
    "PROPERTIES": "العقارات",
    "SEE ALL PROPERTIES": "شاهد جميع العقارات",
    "FEATURED AGENCIES": "الشركات العقارية المميزة",

    "A REAL ESTATE AGENCY?": "هل انت شركة عقارية؟",
    "START LISTING": "ابدأ بإدراج العقارات الخاصة بك مع أماكن",
    "IT'S SIMPLE AND EASY, CLICK BELOW AND FOLLOW THE INSTRUCTIONS.": "بكل بساطة، انقر أدناه واتبع التعليمات.",

    "FORGET PASSWORD": "نسيت كلمة المرور؟",
    "ENTER EMAIL": "أدخل البريد الإلكتروني",
    "ENTER YOUR EMAIL ADDRESS TO RESET YOUR PASSWORD.": "أدخل بريدك الإلكتروني",
    "GET RESET LINK": "إعادة تعيين كلمة المرور",
    "BACK": "رجوع",
    "ENTER PASSWORD": "أدخل كلمة المرور",
    "RESET PASSWORD": "إعادة تعيين كلمة المرور",
    "ENTER CONFIRM PASSWORD": "أدخل تأكيد كلمة المرور",
    "HOME": " الصفحة الرئيسية",
    "AGENCY AVAILABLE": "الشركة العقارية متاحة",
    "ABOUT": "عن",
    "AGENT": "المستشار العقاري",
    "REAL ESTATE": "العقارات",
    "CONTACT FORM": "نموذج الاتصال",
    "AGENCY INFORMATION": "معلومات الشركة العقارية",
    "OFFICE": "المكتب",
    "MOBILE": "موبايل",
    "WEBSITE": "الموقع الإلكتروني",

    "USER PROFILE": "حسابي",
    "UPLOAD PROFILE PICTURE": "تحميل الصورة",
    "JPEG OR PNG FORMAT": "صيغة JPEG أو PNG",
    "UPDATE PROFILE": "تحديث الملف الشخصي",
    "CODE": "رمز الدولة",
    "PHONE NUMBER": "رقم الهاتف",
    "YOUR PHONE NUMBER": "رقم هاتفك",
    "PREFERRED LANGUAGE": "اللغة المفضلة",
    "MAIN USAGE OF THE PLATFORM": "الاستخدام الرئيسي للمنصة",
    "NOTHING SELECTED": "لم يتم الاختيار",
    "AREAS OF INTEREST": "المناطق التي تهمك",
    "IS THIS YOUR FIRST BUY OR RENT?": "هل هذه أول عملية شراء أو استئجار لك؟",
    "WHEN IS THE TIMELINE TO BUY OR RENT?": "ما هو الإطار الزمني للشراء أو الاستئجار؟",

    "CHANGE PASSWORD": "تغيير كلمة المرور",
    "OLD PASSWORD": "كلمة المرور القديمة",
    "NEW PASSWORD": "كلمة المرور الجديدة",
    "CONFIRM NEW PASSWORD": "تأكيد كلمة المرور الجديدة",
    "DELETE ACCOUNT": "حذف الحساب",
    "DELETE THE IMAGE": "حذف الصورة",
    "ARE YOU SURE TO DELETE PROFILE PICTURE?": "هل أنت متأكد من حذف صورة الصورة؟",
    "YES": "نعم ",
    "NO": "لا",
    "BEFORE YOU PROCEED WITH ACCOUNT DELETING, PLEASE TAKE A MOMENT TO CONSIDER THAT ALL YOUR DATA, INCLUDING PROFILE INFORMATION, WILL BE PERMANENTLY DELETED AND CANNOT BE RECOVERED. IF YOU CHANGE YOUR MIND LATER, YOU WILL NEED TO CREATE A NEW ACCOUNT FROM SCRATCH.": "قبل أن تتابع مع حذف الحساب، يُرجى أخذ لحظة للتفكير في أن جميع بياناتك، بما في ذلك معلومات الملف الشخصي، سيتم حذفها بشكل دائم ولا يمكن استرجاعها. إذا غيرت رأيك لاحقًا، فستحتاج إلى إنشاء حساب جديد",
    "WELCOME": "مرحباً",
    "MY FAVORITES": "المفضلة",
    "MY PROFILE (USER)": "حسابي (المستخدم)",
    "LOGOUT": "تسجيل الخروج",
    "HOME": "الرئيسية",
    "SEARCH BY AMAKEN MAP": "البحث ب خريطة أماكن",
    "AGENCIES": "الشركات العقارية",
    "PAGES": "عن أماكن",
    "ABOUT US": "من نحن",
    "FAQ": "الأسئلة الشائعة",
    "AGENCIES SIGN IN": "دخول الشركات",
    "AGENCIES SIGN UP": "تسجيل الشركات ",
    "AGENCIES LOGIN": "دخول الشركات",
    "SIGN IN": "الدخول",
    "SIGN UP": "حساب جديد",
    "WELCOME TO AMAKEN": "مرحباً بك في أماكن",
    "REMEMBER ME": "تذكرني",
    "FORGET PASSWORD?": "نسيت كلمة المرور؟",
    "OR": "أو",
    "WITH GOOGLE": "باستخدام جوجل",
    "WITH FACEBOOK": "باستخدام فيسبوك",
    "WITH APPLE": "باستخدام أبل",
    "NOT GIVEN": "غير معطى",
    "NEED HELP? TALK TO OUR EXPERT": "هل تحتاج إلى مساعدة؟ تحدث إلى خبيرنا",
    "TALK TO OUR EXPERTS OR BROWSE THROUGH MORE PROPERTIES": "تحدث إلى خبرائنا أو تصفح المزيد من العقارات",
    "CONTACT US": "اتصل بنا",
    "FREQUENTLY ASKED QUESTIONS": "الأسئلة الشائعة",
    "NO FAQ AVAILABLE": "لا توجد أسئلة شائعة متاحة",
    "I AGREE": "موافق",
    "TERMS AND CONDITIONS": "الشروط والأحكام",
    "CREATE ACCOUNT": "إنشاء حساب",
    "ADVANCE SEARCH": "بحث متقدم",
    "SEARCH KEYWORD": "كلمة البحث",
    "SEARCH": "بحث",
    "PRICE RANGE": "السعر",
    "PROPERTY CATEGORY": "فئة العقار",
    "TYPE": "النوع",
    "LOCATION": "الموقع",
    "BEDROOMS": "غرف النوم",
    "ANY": "أي",
    "BATHROOMS": "الحمامات",
    "MIN.": "الحد الأدنى",
    "MAX.": "الحد الأعلى",
    "SQUARE METER": "متر مربع",
    "PROPERTY ID": "الرقم المرجعي",
    "HAVE 3D TOUR": "جولة ثلاثية الأبعاد",
    "AGENCY/AGENT": "الشركة عقارية / المستشار العقاري",
    "OTHER FEATURES": "ميزات أخرى",
    "RESET ALL FILTERS": "إعادة ضبط",
    "JOD": "دينار أردني",
    "SQ.M.": "متر مربع",
    "SEE ALL": "عرض الكل",
    "OVERVIEW": "نظرة عامة",
    "BEDROOM": "غرفة نوم",
    "BATH": "حمام",
    "FURNISHED": "مفروش",
    "PROPERTY TYPE": "نوع العقار",
    "BASEMENT": "تسوية",
    "GROUND FLOOR": "الطابق الأرضي",
    "TH FLOOR": "الطابق",
    "YEAR BUILT": "سنة البناء",
    "FOR RENT": "للإيجار",
    "FOR BUY": "للبيع",
    "ALL": "الكل",
    "PROPERTY DESCRIPTION": "وصف العقار",
    "SHOW LESS": "عرض أقل",
    "SHOW MORE": "عرض المزيد",
    "PROPERTY DETAILS": "تفاصيل العقار",
    "PRICE": "السعر",
    "PROPERTY STATUS": "نوع العرض",
    "3D TOUR": "جولة ثلاثية الأبعاد",
    "DIRECTORATE": "المديرية",
    "COUNTRY": "البلد",
    "JORDAN": "الأردن",
    "GOVERNORATE": "المحافظة",
    "VILLAGE": "القرية",
    "VIDEO": "فيديو",
    "NA": "غير متاح",
    "FEATURES": "الميزات",
    "AMENITIES": "وسائل الراحة",
    "NEARBY SIMILARS": "عقارات مشابهة",
    "GET MORE INFORMATION": "احصل على مزيد من المعلومات",
    "VIEW AGENCY": "صفحة الشركة",
    "AGENT PAGE": "صفحة المستشار العقاري",
    "VIEW DETAILS": "عرض التفاصيل",


    "SCHEDULE A TOUR": "زيارة العقار",
    "CHOOSE YOUR PREFERRED DAY": "اختر يومك المناسب لك",
    "TIME": "الوقت",
    "ENTER VALID DATE TIME": "يرجى ادخال تاريخ صحيح",
    "NAME": "الاسم",
    "PHONE": "الهاتف",
    "TERMS OF USE": "شروط الاستخدام",
    "SUBMIT A TOUR REQUEST": "ارسال طلب الزيارة",
    "DO YOU HAVE ANY PROBLEMS?": "هل لديك أي مشاكل؟",
    "REPORT THIS PROPERTY": "الإبلاغ عن هذا العقار",
    "ENTER YOUR NAME": "أدخل اسمك",
    "ENTER YOUR EMAIL": "أدخل بريدك الإلكتروني",
    "ENTER YOUR MESSAGE": "أدخل رسالتك",
    "SEND REPORT": "إرسال التقرير",
    "SELECT A REASON": "يرجى اختيار السبب",
    "NOT LOGGED IN": "لم يتم تسجيل الدخول",
    "PLEASE LOG IN TO MARK THIS AS A FAVORITE.": "يرجى تسجيل الدخول للاضافة إلى المفضلة",
    "LOGIN NOW": "تسجيل الدخول الآن",
   
    "STATUS": "الحالة",
    "LISTING STATUS": "نوع العرض",
    "DONE": "تم",
    "BEDS": "غرف النوم",
    "BATHS": "الحمامات",
    "ADVANCED": "بحث متقدم",

    "SHOWING": "عرض",
    "RESULTS": "النتائج",
    "SORT BY": "ترتيب حسب",
    "NEWEST": "الأحدث",
    "PRICE LOW": "الأقل سعراً",
    "PRICE HIGH": "الأعلى سعراً",
    "FEATURED": "مميز",
    "FOR": "ل",
    "BED": "غرفة",
    "DRAW": "رسم",
    "APPLY": "تطبيق",
    "CANCEL": "إلغاء",
    "REMOVE": "إزالة",
    "NO PROPERTIES FOUND": "لم يتم العثور على عقارات",
    "SHOW ALL TYPES": "عرض جميع الأنواع",
    "BUY": "للبيع",
    "RENT": "للايجار",
    "SAVE AND APPLY": "حفظ وتطبيق",
    "MESSAGE": "الرسالة",
    "AGENTS": "المستشارون العقاريون",
    "LISTING": "العقارات",
    "SHOW ALL PROPERTIES": "عرض جميع العقارات",
    "AGENT INFORMATION": "معلومات المستشار العقاري",

    "WE'RE ON A MISSION TO CHANGE": "نحن في مهمة للتغيير",
    "THE VIEW OF ONLINE REAL ESTATE MARKETPLACE FIELD IN JORDAN.": "نظرة المستخدمين عن سوق العقارات عبر الإنترنت في الأردن",
    "IT DOESN’T MATTER HOW ORGANIZED YOU ARE — A SURPLUS OF TOYS WILL ALWAYS ENSURE YOUR HOUSE IS A MESS WAITING TO HAPPEN. FORTUNATELY, GETTING KIDS ON BOARD WITH THE IDEA OF DITCHING THEIR STUFF IS A LOT EASIER THAN IT SOUNDS.": "البحث عن عقار هي تجربة رائعة، وبالنسبة لمعظم الناس، فهي أكبر صفقة يقومون بها على الإطلاق. أماكن هنا لمساعدة الأشخاص في الحصول على عقاراتهم المناسبة لاحتياجاتهم، من خلال ربط المشترين والمستثمرين وأصحاب العقارات والبائعين والمستأجرين والوسطاء في منصة واحدة لتسهيل المتطلبات العقارية المختلفة بطريقة سلسة وسهلة الاستخدام.",
    "MAECENAS QUIS VIVERRA METUS, ET EFFICITUR LIGULA. NAM CONGUE AUGUE ET EX CONGUE, SED LUCTUS LECTUS CONGUE. INTEGER CONVALLIS CONDIMENTUM SEM. DUIS ELEMENTUM TORTOR EGET CONDIMENTUM TEMPOR. PRESENT SOLICITUDIN LECTUS UT PHARETRA PULVINAR.": "يعد بيع وشراء العقارات عملية صعبة، لأنها مجزأة ومعقدة. تتضمن رحلتك لشراء عقار جديد العديد من الخطوات - البحث، والتسوق، والجولة، والتقييم، والتفاوض على العرض. تقوم أماكن بحل هذه المشاكل الحقيقية هذه من خلال توفير حلول سلسة. بفضل الميزات و التحسينات الجديدة التي نقدمها باستمرار على التطبيق والموقع، بالإضافة إلى التواصل مع الشركاء والوكلاء الرائعين، توفر أماكن أفضل تجربة للمستأجرين والمشترين والبائعين .",


    "MORE THAN": "أكثر من",
    "AMAZING PROPERTY": "عقار مذهل",
    "AND": "و",
    "THOUSANDS": "الآلاف",
    "OF HAPPY CUSTOMERS": "من العملاء السعداء",
    "ENTER 4 DIGIT OTP RECEIVED ON YOUR PHONE": " أدخل رمز التحقق الذي تلقيته على هاتفك",
    "ENTER OTP": "أدخل رمز التحقق",
    "COMPARE PROPERTIES": "مقارنة العقارات",
    "COMPARE": "مقارنة",
    "REMOVE ALL": "إزالة الكل",
    "PLEASE LOG IN TO MARK THIS AS A FAVORITE": "يرجى تسجيل الدخول لوضع هذا كإضافة إلى المفضلة",
    "PROFILE UPDATED!": "تم تحديث الملف الشخصي!",
    "EDIT": "تعديل",
    "ADD": "إضافة",
    "VERIFY": "تحقق",
    "VERIFY EMAIL": "تحقق من البريد الإلكتروني",
    "ADDED": "مضاف",
    "YOUR EMAIL IS NOT VERIFIED. PLEASE VERIFY YOUR EMAIL ADDRESS TO PROCEED.": " لم يتم التحقق من بريدك الإلكتروني. يرجى التحقق من عنوان بريدك الإلكتروني للمتابعة.",

    "ENTER LOCATION NAME / AREA NAME": "أدخل اسم الموقع، اسم المنطقة",
    "URL COPIED TO CLIPBOARD!": "تم نسخ الرابط",
    "INTRO VIDEO": "فيديو قصير",
    "FULL VIDEO": "فيديو كامل",
    "PLEASE LOG IN BEFORE REQUESTING THE TOUR SCHEDULE.": "يرجى تسجيل الدخول قبل طلب زيارة العقار",
    "SEND OTP": "OTP يرسل",


    // NEW ADDED
    "PLEASE DRAW ANY SHAPE": "يرجى رسم أي شكل",
    "VERIFY PHONE NUMBER": "التحقق من رقم الهاتف",
    "COMPLETE YOUR PROFILE": "أكمل ملفك الشخصي",
    "COMPLETE_PROFILE_TEXT": "يرجى إكمال ملف التعريف الخاص بك للحصول على نتائج بحث أفضل وأداء أفضل.",
    "CONTINUE": "يكمل",
    "NOT NOW": "ليس الآن",
    
    // NEWELY ADDED
    "ABOUT_PAGE_TITLE": "عن أماكن",
    "ABOUT_META_TITLE": " عن أماكن: وجهتك لأفضل الشقق والأراضي للبيع في الأردن | أماكن",
    "ABOUT_META_DESCRIPTION": "اكتشف أفضل العروض العقارية في الأردن على موقع أماكن. ابحث عن عقارات للبيع، شقق للبيع، وأراضي للبيع بسهولة ويسر. انضم الآن وابدأ رحلتك في العثور على العقار المثالي.",
    "ABOUT_KEYWORDS": "عقارات للبيع , اراضي للبيع , شقق للبيع , الباحثين عن عقارات , السوق العقاري الاردني , تسويق العقارات عبر الانترنت",
    "AGENCIES_PAGE_TITLE":"الشركات العقارية المتعاونة مع موقع اماكن",
    "AGENCIES_META_TITLE":"الشركات العقارية الأردنية المتعاونة مع موقعنا  | أماكن",
    "AGENCIES_META_DESCRIPTION":"اكتشف أفضل شركات العقارات المتعاونة معنا  في الأردن. استفد من خدمات الشركات العقارية على موقعنا لتجد الشقق الفاخرة والمنازل المستقلة في عمان والمحافظات بسهولة ويسر",
    "AGENCIES_KEYWORDS":"شركات عقارية في الاردن , أفضل شركات العقارات في الأردن , شركات عقارية في عمان",
    "SEARCH_BY_AMAKEN_PAGE_TITLE":"إبحث مع أماكن",
    "SEARCH_BY_AMAKEN_KEYWORDS": "شقق فاخرة للبيع  , شقق للبيع , منزل مستقل للبيع , الموقع على الخريطة",
    "SEARCH_BY_AMAKEN_META_TITLE" : "الخريطة التفاعلية: شقق، منازل وعقارات للبيع | أماكن",
    "SEARCH_BY_AMAKEN_META_DESCRIPTION":"اكتشف أفضل الشقق الفاخرة للبيع والمنازل المستقلة في الأردن عبر موقع أماكن. استخدم خريطتنا التفاعلية للعثور على أفضل العقارات بسهولة. تصفح الآن لتجد المنزل المثالي لك.",
    
    "BLOGS" : "مدونات",
    "NO BLOGS AVAILABLE" : "لا توجد مدونات متاحة.",
    "CONTINUE READING" : "مواصلة القراءة",
    "WRITTEN BY" : "كتب بواسطة",
    "IN" : "في",
    "VERIFIED" : "تم التحقق منه",

    "WHATS NEARBY":"ما هو قريب؟",
    "EDUCATION" : "تعليم",
    "HEALTH AND MEDICAL" : "الصحة والطب",
    "TRANSPORTATION" : "مواصلات"
}
