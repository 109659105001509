import { axios } from "./axios"
import {
  FORGOT_API,
  LOGIN_API,
  LOGOUT,
  REFRESH_TOKEN,
  RESEND_CODE,
  RESET_API
} from "../constants/paths"

/**
 * Login API Call
 * @param {LoginCredentialsDTO} data
 * @returns {Promise<any>}
 */
export const login = async (data) => {
  const response = await axios.post(LOGIN_API, data)
  return response?.data
}


/**
 * Forgot Password API Call
 * @param  data
 * @returns {Promise<any>}
 */
export const forgotPassword = async(data) => {
  const response = await axios.post(FORGOT_API, data)
  return response?.data
}

/**
 * Reset Password API Call
 *
 * @param {ResetPasswordDTO} data
 * @returns {Promise<any>}
 */
export const resetPassword = async (data,token) => {
  const response = await axios.post(RESET_API+'?token='+token,data)
  return response?.data
}


/**
 * Logout API Call
 * @param {LogoutDTO} data
 * @returns {Promise<any>}
 */
export const logout = async (data) => {
  const response = await axios.post(LOGOUT, data)
  return response?.data
}

/**
 * Resend MFA Code API Call
 *
 * @returns {Promise<any>}
 */
export const resendMFACode = async (data) => {
  const response = await axios.post(RESEND_CODE, data)
  return response?.data
}

/**
 * Refresh Token API
 *
 * @param {LogoutDTO} data
 * @returns {Promise<any>}
 */
export const refreshTokenCALL = async (data) => {
  const response =  axios.post(REFRESH_TOKEN, data)
  return response?.data
}
