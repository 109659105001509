import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CityImg from "../../assets/images/listings/city-listing-1.png";
import { nameBasedRoutes } from '../../router/public';
import { constructUrl } from '../../services/propertyService';
import { useTranslation } from 'react-i18next';
const PropertyCityCard = ({ propertyData }) => {

    const {t} = useTranslation()

    const navigate = useNavigate()
    // governorateId=1
    const navigatePath = nameBasedRoutes?.propertyList?.path;
    // HANDLE PRICE_FILTER
    const handleRedirectProperties = (event, governorateId) => {
        event.preventDefault()
        const param = { key: "governorateId", value: governorateId }
        const navigateURl = constructUrl(navigatePath, param)
        navigate(navigateURl)
    }

    return (
        <div className="item">
            <div className="feature-style1 mb30">
                <div className="feature-img">
                    <img src={propertyData?.image ?? CityImg} alt="City For You" />
                </div>
                <div className="feature-content">
                    <div className="top-area">
                        <h6 className="title mb-1">{propertyData?.capital}</h6>
                        <p className="text">{propertyData?.propertyCount} {t("PROPERTIES")}</p>
                    </div>
                    <div className="bottom-area">
                        <Link className="ud-btn2" to="#" onClick={(event)=>handleRedirectProperties(event , propertyData?.id)}>
                            {t("SEE ALL PROPERTIES")}
                            <i className="fal fa-arrow-right-long" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyCityCard