
import React, { useEffect, useState } from 'react'
import TrustedAgenciesImg from "../../assets/images/team/agency-2.png";
import OwlCarousel from "react-owl-carousel2";
import { toast } from 'react-toastify';
import { Empty, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { nameBasedRoutes } from '../../router/public';
import { getTrustedAgencies } from '../../services/homePageServices';
const TrustedAgencies = () => {

  const cookies = new Cookies()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { i18n } = useTranslation()
  const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';
  const TrustedOptions = {
    rtl: userLanguage === 'ar' ? true : false,
    items: 5,
    nav: true,
    rewind: true,
    autoplay: true,
    margin: 30,
    // responsiveClass: 'true',
    responsive: {
      0: {
        items: 2,
        nav: true
      },
      600: {
        items: 2,
        nav: false
      },
      1000: {
        items: 5,
        nav: true,
        loop: false
      }
    }
  };

  const events = {
    onDragged: function (event) { },
    onChanged: function (event) { },
  };

  const [loading, setLoading] = useState(true)
  const [agencyList, setAgencyList] = useState([])

  // FETCH PROPERTY TYPE LIST
  const fetchTrustedAgency = () => {
    setLoading(true)
    const params = {
      page: 0,
      size: 9
    }
    getTrustedAgencies(params).then((response) => {
      setLoading(false)
      setAgencyList(response?.data)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  // FETCH PROPERTY TYPES
  useEffect(() => {
    fetchTrustedAgency()
  }, [i18n?.language])

  // HANDLE AGENCY ICON NAVIGATION
  const handleAgencyNavigate = (id, slug) => {
    navigate(`${nameBasedRoutes?.agencyDetails?.path}/${id}${slug ? '/' + slug : ''}`)
  }

  return (
    <section className="our-partners pb100 pb50-md pt0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 wow fadeInUp" data-wow-delay="300ms">
            <div className="main-title text-center">
              <h2> {t("FEATURED AGENCIES")} "{t("AMAKEN")}"</h2>
            </div>
          </div>
          {
            loading === true ?
              <div className="row">
                {[...Array(5)].map((element, index) => {
                  return <div key={index + 1} className="col-xl">
                    <Skeleton.Button active={true} size={"100%"} shape={"square"} block={true} style={{ height: "200px" }} />
                  </div>
                })
                }
              </div> : agencyList && agencyList?.length > 0 ?
                agencyList?.length > 1 ?
                  <div className="col-lg-12">

                    <OwlCarousel
                      className="dots_none nav_none slider-dib-sm slider-6-grid owl-carousel owl-theme wow fadeInUp"
                      options={TrustedOptions}
                      events={events}
                    >
                      {
                        agencyList?.map((element, index) => {
                          return <div key={index + 1}>

                            <div className="item">
                              <div className="partner_item" >
                                <img src={element?.logo ?? TrustedAgenciesImg} alt="Trusted Agencies" className='cursor-pointer' onClick={() => handleAgencyNavigate(element?.id, element?.slug)} />
                              </div>
                            </div>
                          </div>
                        })
                      }
                    </OwlCarousel>
                  </div>
                  :
                  <div className="row">
                    {
                      /* IF ONE AGENCY THEN NO NEED TO DISPLAY SLIDER*/
                      agencyList?.map((element, index) => {
                        return <div key={index + 1}>
                          <div className="item">
                            <div className="partner_item" >
                              <img className='cursor-pointer' src={element?.logo ?? TrustedAgenciesImg} alt="Trusted Agencies" onClick={() => handleAgencyNavigate(element?.id, element?.slug)} />
                            </div>
                          </div>
                        </div>
                      })
                    }
                  </div>
                : <Empty description={t("NO AGENCY FOUND")} />
          }
        </div>
      </div>
    </section>
  )
}

export default TrustedAgencies
