import React from 'react'
import { Link } from 'react-router-dom'
import { nameBasedRoutes } from '../../router/public'
import { useTranslation } from 'react-i18next'
// AGENCY PROFILE INFO
const AgencyCard = ({agencyDetails}) => {
  const {t} = useTranslation()
  return (
    <div className="bgc-white mt30 default-box-shadow1">
    <div className="agency-style1 p30 mb30">
      <div className="agency-img">
        <img
          className="w-100"
          src={agencyDetails?.logo}
          alt=""
        />
      </div>
      <div className="agency-details pt40">
        <h6 className="agency-title mb-1">{agencyDetails?.name ?? ""}</h6>
        <p className="fz15">{agencyDetails?.address ?? ""}</p>
        <div className="d-grid">
          <Link
            to={`${nameBasedRoutes?.agencyDetails?.path}/${agencyDetails?.id}/${agencyDetails?.slug}`}
            className="ud-btn btn-white2"
          >
           {t("VIEW DETAILS")}
            <i className="fal fa-arrow-right-long" />
          </Link>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AgencyCard
