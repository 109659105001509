import { NEAR_BY_SIMILAR_API, PROPERTY_DETAILS_API, PROPERTY_LIKE_API, PROPERTY_LIST, PROPERTY_LIST_MAP, PROPERTY_VIEW_COUNT, REPORT_PROPERTY_API, TOUR_SCHEDULE_FORM_API } from "../constants/paths"
import { axios } from "./axios"

// API SERVICES
// PROPERTY SINGLE DETAILS API 
export const propertyDetailsApi = async (id) => {
    const response = await axios.get(`${PROPERTY_DETAILS_API}/${id}`)
    return response?.data
}
// TOUR SCHEDULE API
export const tourScheduleFormApi = async(data)=>{
    const response = await axios.post(TOUR_SCHEDULE_FORM_API,data)
    return response?.data
}

// PROPERTY LIST API WITH ADVANCE SEARCH
export const propertyListApi = async (data, params,sort) => {

    // console.log({sortString : sort})


    // TO MANAGE MULTIPLE SORT FIELD
    let apiURl = PROPERTY_LIST
    if(sort){
        apiURl = `${PROPERTY_LIST}?${sort}`
    }
    // console.log({apiURl : apiURl})
    const response = await axios.post(apiURl, data, { params })
    return response
}

// PROPERTY LIST API FOR MAP
export const propertyListMapApi = async (data, params) => {
    const response = await axios.post(`${PROPERTY_LIST_MAP}`, data, { params })
    return response
}
// PROPERTY LIKE STATUS API
export const propertyLikeStatusApi = async (id,status)=>{
    const response = await axios.put(`${PROPERTY_LIKE_API}/${id}?isLiked=${status}`)
    return response?.data
}
// PROPERTY NEAR BY API
export const propertyNearByApi = async (id)=>{
    const response = await axios.get(`${NEAR_BY_SIMILAR_API}/${id}`)
    return response?.data
}
// REPORT PROPERTY API
export const reportPropertyApi = async (data)=>{
    const response = await axios.post(REPORT_PROPERTY_API , data)
    return response?.data
}

export const countPropertyView = async (id)=>{
    const response = await axios.get(`${PROPERTY_VIEW_COUNT}/${id}`)
    return response?.data
}

// COMPONENT SERVICES

// CONSTRUCT URL FOR PROPERTY LISTING FILTER

export const constructUrl = (path, params) => {
    const prevParams = window.location.search.substring(1); // Get existing parameters
    const paramsObj = Object.fromEntries(new URLSearchParams(prevParams));

    const urlParams = new URLSearchParams(paramsObj);

    if (Array.isArray(params)) {
        params.forEach(param => {
            if (param && param.key !== undefined && param.value !== undefined) {
                // Check if the parameter key already exists in the URL
                if (urlParams.has(param.key)) {
                    // Update the existing parameter value
                    urlParams.set(param.key, param.value);
                } else {
                    // Append the new parameter
                    urlParams.append(param.key, param.value);
                }
            }
        });
    } else {
        if (params && params.key !== undefined && params.value !== undefined) {
            // Check if the parameter key already exists in the URL
            if (urlParams.has(params.key)) {
                // Update the existing parameter value
                urlParams.set(params.key, params.value);
            } else {
                // Append the new parameter
                urlParams.append(params.key, params.value);
            }
        }
    }

    return `${path}?${urlParams.toString()}`;
};

// REMOVE SEARCH FILTER
export const removeSearchParams = (path, key) => {
    const prevParams = new URLSearchParams(window.location.search); // Get existing parameters

    if (!Array.isArray(key)) {
        // Convert single key to array for unified processing
        key = [key];
    }

    // Remove each parameter with the specified keys
    key.forEach(k => prevParams.delete(k));

    // Construct the new URL
    return `${path}?${prevParams.toString()}`;
}
// COMBINE CONSTRUCT AND REMOVE URL FUNCTION
export const manipulateUrlParams = (path, paramsToAdd, paramsToRemove) => {
    const prevParams = new URLSearchParams(window.location.search); // Get existing parameters

    // Remove parameters specified in paramsToRemove array
    if (Array.isArray(paramsToRemove)) {
        paramsToRemove.forEach(key => prevParams.delete(key));
    } else if (paramsToRemove) {
        prevParams.delete(paramsToRemove);
    }

    // Add parameters specified in paramsToAdd object
    if (Array.isArray(paramsToAdd)) {
        paramsToAdd.forEach(param => {
            if (param && param.key !== undefined && param.value !== undefined) {
                prevParams.set(param.key, param.value);
            }
        });
    } else if (paramsToAdd) {
        prevParams.set(paramsToAdd.key, paramsToAdd.value);
    }

    // Construct the new URL with updated parameters
    return `${path}?${prevParams.toString()}`;
};
// CREATE PAYLOAD FOR PROPERTY LISTING FILTER
export const createSearchPayload = (searchPayload) => {


    let data = { ...searchPayload };

    for (const key in data) {
        // Object.prototype.hasOwnProperty.call(data, key)
        if ((key === "propertyTypeIdList" || key === "amenityIdList") ) {
            const value = data[key];
          
            if (value && value !== '' && value !== null) {
                let arrayValue;
                if (value.includes(',')) {
                    // Split the string by commas and parse each value into an integer
                    arrayValue = value.split(',').map(id => parseInt(id.trim()));
                } else {
                    // Create an array with a single element and parse it into an integer
                    arrayValue = [parseInt(value)];
                }
                data[key] = arrayValue;
            }else{
                delete data[key]   
            }
        }else if(key === 'page' ||  key === "formattedAddress"){
            delete data[key] 
        }else if(key === 'isMapView' || key === 'save'){
            if(data[key] == "true"){
                data[key] = true
            }else{
                delete data[key] 
            }
        }else if(key === "polyline" && (data[key] === "" || data[key] === undefined || data[key] === null)){
            data[key] = []
        }
    }
    return data
}
// CREATE INITIAL FORM DATA
export const createFormInitialData = (searchData) => {
    let data = { ...searchData }

    for (const key in data) {
      
        // Object.prototype.hasOwnProperty.call(data, key)
        if ((key === "propertyTypeIdList" || key === "amenityIdList") && (key !== 'page' && key!=='sort' && key !== "formattedAddress")) {
            const value = data[key];
            if (value) {
                let arrayValue;
                if (value.includes(',')) {
                    // Split the string by commas and parse each value into an integer
                    arrayValue = value.split(',').map(id => parseInt(id.trim()));
                } else {
                    // Create an array with a single element and parse it into an integer
                    arrayValue = [parseInt(value)];
                }
                data[key] = arrayValue;
            }else  if(key === 'page' || key==='sort' || key === "formattedAddress"){
                delete data[key]
            }
          
        }

    }
    return data


}