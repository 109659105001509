import { useRoutes } from "react-router-dom";
import { publicRoutes } from "./public";
import { protectedRoutes } from "./protected";


export default function AppRoutes() {
    //    GET USER TOKEN FROM LOCAL STORAGE
    const token = window.localStorage.getItem('US_token')
    const mainRoutes = token != null ? protectedRoutes : []
    
    const element = useRoutes([...mainRoutes, ...publicRoutes])
    return <div>{element}</div>
}


