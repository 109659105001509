import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ScrollToHome from "./ScrollToHome";
import Header from "./Home-Header/Header";
import CommonHeader from "./User-Header/Header"
import MobileNav from "./Mobile-nav/MobileNav";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { generalInfoApi } from "../../services/userService";
import { setGeneralInfo } from "../../redux/generalInfoSlice";
export const Layout = () => {


  const {i18n} = useTranslation()
  const {t} = useTranslation()
  const [isHomePage, setIsHomePage] = useState(true)

  const dispatch = useDispatch()

  const userData = useSelector((state) => state?.user?.userData);
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [showProfileModal, setShowProfileModal] = useState(false);
  const navigate = useNavigate();
  const cookies = new Cookies()
  const profileModalStatus = cookies.get("showProfileModal")

  useEffect(() => {
    if (userData && userData?.profileComplete !== true && profileModalStatus === "true") {
      const timeOutId = setTimeout(() => {
        setShowProfileModal(true);
        cookies.set("showProfileModal", false)
      }, 2000);
      return () => clearTimeout(timeOutId);
    } else {
      setShowProfileModal(false)
    }
  }, [userData]);

  useEffect(() => {
    if (
      window?.location?.pathname?.replace(/\/$/, '') === new URL(process.env.REACT_APP_WEBSITE_WEB_URL)?.pathname.replace(/\/$/, '') ||
       window?.location?.pathname?.replace(/\/$/, '') === new URL(process.env.REACT_APP_WEBSITE_WEB_URL  + "/home" )?.pathname.replace(/\/$/, '')) // Remove trailing slash
    {
      setIsHomePage(true)
    } else {
      setIsHomePage(false)
    }
  },[navigate])



	// FETCH GENERAL INFO API ON LANGUAGE CHANGE AND UPDATE IN REDUX
	const fetchGeneralInfo = () => {
		generalInfoApi().then((response) => {
      dispatch(setGeneralInfo(response))
			// setOfferingType(response?.platformUsageMap)
		}).catch((error) => {
			console.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}

  // CALL GENERAL INFO API
	useEffect(() => {
		fetchGeneralInfo()
	}, [i18n?.language])

  return (
    <div className="wrapper ovh mm-page mm-slideout" dir={i18n?.language === 'ar' ? 'rtl' : 'ltr'}>
      {/* MATCH PATHNAME IF HOME PAGE THE HOME HEADER WILL DISPLAY AS ALWAYS COMMON HEADER WILL DISPLAY */}
      {
        !isHomePage ?<CommonHeader />:  <Header /> 
      }
      {/* Mobile Nav  */}
      <MobileNav openMobileMenu={openMobileMenu} setOpenMobileMenu={setOpenMobileMenu} />
      <Outlet />
      {/* If user profile not completed then only complete profile pop-up will appear */}
      <Modal
        type="confirm"
        title={t("COMPLETE YOUR PROFILE")}
        open={showProfileModal}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setShowProfileModal(false)
        }}
      >
        <p>
        {t("COMPLETE_PROFILE_TEXT")}
        </p>
        <div >
          <button className="btn ud-btn btn-white2" onClick={() => { setShowProfileModal(false) }}>{t("NOT NOW")}</button>
          <button onClick={() => {
            setShowProfileModal(false);
            navigate("/profile-user");
          }} className="ud-btn btn-dark ms-3">{t("CONTINUE")}</button>
        </div>
      </Modal>
      <ScrollToHome />
      <div className="mm-wrapper__blocker mm-blocker mm-slideout" onClick={() => { setOpenMobileMenu(false) }}></div>
    </div>
  );
};
