import {axios} from "./axios"
import {
    CMS_PAGE_DATA_API,
    FAQ_PAGE_DATA_API
} from "../constants/paths"

// GET CMS PAGE CONTENT
export const getPageContent = async(key)=>{
    const response = await axios.get(`${CMS_PAGE_DATA_API}/${key}`)
    return response?.data
}

// GET FAQ PAGE CONTENT
export const getFaqPageContent = async(key)=>{
    const response = await axios.get(`${FAQ_PAGE_DATA_API}/${key}`)
    return response?.data
}