import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import InputText from '../common/InputText'
import InputPhoneCode from '../common/InputPhoneCode'
import { useTranslation } from 'react-i18next'
import Datepicker from '../common/Datepicker'
import { tourScheduleValidationSchema } from '../../validations/ValidationSchema'
import dayjs from 'dayjs'
import { tourScheduleFormApi } from '../../services/propertyService'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { sendOTPapi, verifyOTPapi } from '../../services/userService'
import Timer from '../timer/Timer'
import { updateUserPhoneNO } from '../../redux/userProfileSlics'
import ReactGA4 from "react-ga4"

function TourScheduleForm({ propertyId,propertyTitle }) {

  // LOGGED IN USER DATA
  const userData = useSelector((state) => state?.user?.userData)

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const currentDate = new Date();

  // Add 5 minutes to the current time
  currentDate.setMinutes(currentDate.getMinutes() + 5);

  // Convert the modified Date object to a Day.js object
  const initialTime = dayjs(currentDate);


  const [loading, setLoading] = useState(false)
  const [disableBtn, setDisableBtn] = useState(userData ? false : true)
  const [isTimerRunning, setIsTimerRunning] = useState(false)

  const [initialData, setInitialData] = useState({
    time: initialTime,
    countryCode: userData?.countryCode ?? "962",
    phoneNumber: userData?.mobileNumber ?? "",
    message: "",
    terms: false,
    isOtpSend: false,
    otp: ""
  })
  // HANDLE SUBMIT ON TOUR REQUEST
  const handleSubmit = (values) => {
    setLoading(true)
    let formData = {}
    if (userData?.mobileNumberVerified) {
      formData = {
        propertyId: propertyId ? parseInt(propertyId) : null,
        preferredDate: values?.time ? values?.time.format('YYYY-MM-DD') : null,
        preferredTime: values?.time ? values?.time.format('HH:MM') : null,
        name: userData?.firstName,
        countryCode: userData?.countryCode ?? null,
        phoneNumber: userData?.mobileNumber ?? null,
        email: userData?.email ?? '',
        message: values?.message ?? '',
      }
    } else {
      formData = {
        propertyId: propertyId ? parseInt(propertyId) : null,
        preferredDate: values?.time ? values?.time.format('YYYY-MM-DD') : null,
        preferredTime: values?.time ? values?.time.format('HH:MM') : null,
        name: userData?.firstName,
        countryCode: values?.countryCode ?? null,
        phoneNumber: values?.phoneNumber ?? null,
        email: userData?.email ?? '',
        message: values?.message ?? '',
      }
    }
    tourScheduleFormApi(formData)
      .then((response) => {

        ReactGA4.event({
          category: "request_tour",
          action: "visit",
          id :propertyId,
          item : propertyTitle,
          description :  `Request tour page accessed for ${propertyTitle}`
        });
        setLoading(false);
        toast.success(response?.message);
        setLoading(false)
        setInitialData({
          time: initialTime,
          countryCode: userData?.countryCode ?? "962",
          phoneNumber: userData?.mobileNumber ?? "",
          message: "",
          terms: false,
          isOtpSend: false,
          otp: ""
        })
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message
        );
      });
  }

  const handleSendOTP = (values) => {
    // CALL SEND OTP HERE
    if (values?.isOtpSend && values?.otp) {
			// CALL VERIFY OTP HERE
			const formData = {
				countryCode: "+" +values?.countryCode,
				phoneNumber: values?.phoneNumber,
				otpEntityType: "USER",
				otp: values?.otp
			}
			verifyOTPapi(formData).then((response) => {
				toast.success(response?.message)
       dispatch(updateUserPhoneNO({countryCode : values?.countryCode , mobileNumber : values?.phoneNumber , mobileNumberVerified : true}))
        handleSubmit(values)
			}).catch((error) => {
        setLoading(false)
				toast.error(
					error?.response?.data?.error_description
						? error?.response?.data?.error_description
						: error?.response?.data?.message ?? error?.message
				);
			})
		} else{
      const formData = {
        countryCode: values?.countryCode,
        phoneNumber: values?.phoneNumber,
        otpEntityType: "USER"
      }
      sendOTPapi(formData).then((response) => {
        toast.success(response?.message)
        setInitialData({ ...values, isOtpSend: true })
        setLoading(false)
        setIsTimerRunning(true)
      }).catch((error) => {
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
        setLoading(false)
      })
    }

   
  }

  // IF USER NOT LOGGED IN THEN DISABLE SUBMIT BUTTON
  useEffect(() => {
    if (userData && userData !== null) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
    }
  }, [userData])

  // IF TIMER STATE CHANGED THEN DO THIS
  useEffect(() => {
    if (isTimerRunning === false) {
      setInitialData({ ...initialData, isOtpSend: false })
    }
  }, [isTimerRunning])


  return (
    <div className="default-box-shadow1 bdrs12 bdr1 p30 mb30 bgc-white position-relative">
      <h4 className="form-title mb5">{t('SCHEDULE A TOUR')}</h4>
      <p className="text">{t("CHOOSE YOUR PREFERRED DAY")}</p>
      <div>
        <Formik
          initialValues={initialData}
          enableReinitialize={true}
          validationSchema={tourScheduleValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            setLoading(true)
            if (values?.action === "SUBMIT") {
              handleSubmit(values);
            } else {
              handleSendOTP(values)
            }
          }}
        >
          {({ isSubmitting, handleSubmit,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            setFieldError,
            touched,
            isValid,
            errors, }) => (
            <Form className="form-style1">
             
              <div className="row">
                <div className="col-md-12">
                  <div className="mb20 overflow-hidden">
                    <Datepicker
                      controlId="time"
                      label={t("TIME")}
                      type="text"
                      variant="outlined"
                      value={values?.time}
                      name="time"
                      minDateTime={dayjs(new Date())}
                      maxLength="300"
                      errorsField={errors?.time}
                      touched={touched?.time}
                      
                      handleDateChange={(value) => { setFieldValue("time", value ?? "") }}
                      handleError={(error, value) => {
                        setFieldError("time", t("ENTER VALID DATE TIME"))
                      }}
                      handleBlur={handleBlur}
                      className="form-control" />
                  </div>
                </div>
                {
                  userData?.mobileNumberVerified === false ?
                    <div className="col-lg-12">
                      <div className="row ">
                        <div className="col-md-5 col-xl-5">
                          <InputPhoneCode
                            id="countryCode"
                            controlId="countryCode"
                            name="countryCode"
                            type="number"
                            country="jo"
                            placeholder={t("CODE")}
                            handleChange={(code) => { setFieldValue("countryCode", code) }}
                            handleBlur={handleBlur}
                            value={values?.countryCode}
                            className={` ${touched?.countryCode && errors?.countryCode ? "error" : ""
                              }`}
                          />
                        </div>
                        <div className="col-md-7 col-xl-7 ">
                          <InputText
                            controlId="phoneNumber"
                            label={t("PHONE")}
                            type="number"
                            variant="outlined"
                            value={values?.phoneNumber}
                            name="phoneNumber"
                            maxLength="300"
                            errorsField={errors?.phoneNumber}
                            touched={touched?.phoneNumber}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            className="w-100" />
                        </div>
                      </div>
                    </div> : ""
                }
                <div className="col-md-12">
                  <div className="mb20">
                    <InputText
                      controlId="message"
                      label={t("MESSAGE")}
                      type="text"
                      variant="outlined"
                      value={values?.message}
                      name="message"
                      maxLength="300"
                      errorsField={errors?.message}
                      touched={touched?.message}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      multiline={true}
                      rows={4}
                      className="w-100" />
                  </div>
                </div>
                {
                  values?.isOtpSend === true ?
                    <div className="col-md-12">
                      <InputText
                        controlId="otp"
                        type="number"
                        id="otp"
                        label={t("ENTER OTP")}
                        placeholder={t("ENTER OTP")}
                        variant="outlined"
                        value={values?.otp}
                        name="otp"
                        handleChange={handleChange}
                        errorsField={errors?.otp}
                        touched={touched?.otp}
                        handleBlur={handleBlur}
                        className="w-100" />
                    </div>
                    : ""
                }

                <div className="col-md-12 mb10">
                  <div className="checkbox-style1  d-block d-sm-flex align-items-center justify-content-between ">
                    <label className="custom_checkbox fz14 ff-heading">
                      <a className="fw-bold">{t("TERMS OF USE")}</a>
                      <input
                        name="terms"
                        type="checkbox"
                        id="terms"
                        checked={values?.terms ?? false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  {
                    errors?.terms ? <p className='text-danger mb-0'>{errors?.terms}</p> : ""
                  }
                </div>

                {/* btn-disabled */}
                <div className="col-md-12">
                  {
                    disableBtn ?
                      <p className='text-danger fw-bold mb-2'><p className='text-danger fw-bold mb-2'>{t("PLEASE LOG IN BEFORE REQUESTING THE TOUR SCHEDULE.")}</p> </p> : ""
                  }
                  <div className="d-grid">
                    {
                      userData?.mobileNumberVerified ?
                        <button type="submit" disabled={isSubmitting || disableBtn || loading} onClick={() => {
                          setFieldValue('action', 'SUBMIT')
                        }} className={`ud-btn ${isSubmitting || loading || disableBtn ? 'btn-disabled' : 'btn-thm'}`}>
                          {t("SUBMIT A TOUR REQUEST")}
                          <i className="fal fa-arrow-right-long" />
                        </button> :

                        
                          <button type="submit" onClick={() => {
                            setFieldValue('action', 'SEND_OTP')
                          }} className={`ud-btn ${isSubmitting || loading || disableBtn ? 'btn-disabled' : 'btn-thm'}`}>
                            {values?.isOtpSend === false ?  t("SEND OTP") : t("VERIFY OTP")}
                            <i className="fal fa-arrow-right-long" />
                          </button> 
                       }
                    <div className="text-center">
                      {
                        isTimerRunning ? <Timer isTimerRunning={isTimerRunning} setIsTimerRunning={setIsTimerRunning} /> : ""
                      }
                    </div>
                  </div>
                </div>

              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default TourScheduleForm
