import React from 'react'
import { Link } from 'react-router-dom'
import { nameBasedRoutes } from '../../router/public'
import blogImage from "../../assets/images/listings/city-listing-2.png"
import { MdArrowRightAlt } from "react-icons/md"
import { useTranslation } from 'react-i18next'
import moment from "moment"
import Cookies from 'universal-cookie'
import DefaultImg from "../../assets/images/default-image.jpg"


const BlogCard = ({ blogDetails }) => {
  const { i18n } = useTranslation()
  const { t } = useTranslation()
  const cookies = new Cookies()
  const userLanguage = i18n?.language ?? cookies?.get("userLangKey") ?? 'en'
  // DISPLAY TIME IN ARABIC
  moment.locale(userLanguage);

  return (
    <div className="blog-listing d-flex flex-column h-100">
      <div className="blog-img text-center">
        {
          blogDetails?.thumbnailImage ?
            <img src={blogDetails?.thumbnailImage} alt="Blog thumb" /> : <img src={DefaultImg} alt="blog thumb" />
        }

      </div>
      <div className="blog-content d-flex flex-column flex-grow-1 text-break">
        <div className='d-flex justify-content-between align-items-center'>
          <p className='text-muted'>{blogDetails?.author} </p>
          <p className='fw500 text-muted'>{moment(blogDetails?.createdAt).format('MMMM DD, YYYY')}</p>
        </div>
        <p className='fs-18 text-justify text-truncate-line-3'>{blogDetails?.title}</p>
        <Link to={`${nameBasedRoutes?.blogDetails?.path}/${blogDetails?.id}/${blogDetails?.slug}`} className='theme-text fw-bold mt-auto'>{t("CONTINUE READING")}
          <MdArrowRightAlt className='ms-1 mb-1' size={22} /></Link>
      </div>
    </div>
  )
}

export default BlogCard