import Agencyjump1 from "../../assets/images/about/element-5.png";
import Agencyjump2 from "../../assets/images/about/element-7.png";
import Agencysinglebg from "../../assets/images/team/agency-2.png";
import agentDefaultImage from "../../assets/images/team/agent-3.jpg";
import { Footer } from "../../components/layouts/Footer/Footer";
import { useDispatch } from "react-redux";
import { setHeaderClass } from "../../redux/layoutSlice";
import { useEffect, useState } from "react";
import { Collapse } from 'react-bootstrap'
import { agencyViewCountApi, getAgencyById } from "../../services/agencyService";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { splitParagraph } from "../../services/componentServices";
import Cookies from "universal-cookie";
import PropertyListing from "../../components/agencySingle/PropertyListing";
import ContactForm from "../../components/agencySingle/ContactForm";
import LoaderMu from "../../components/common/LoaderMu";
import { nameBasedRoutes } from "../../router/public";
import SeoComponent from "../../components/seo/SeoComponent";


export const Agencysingle = () => {

  const { id } = useParams()
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const cookies = new Cookies()

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [agencyData, setAgencyDetails] = useState([])
  const [agentListOfAgencyData, setAgentListOfAgencyData] = useState([])

  const userLanguage = i18n?.language ?? cookies?.get("userLangKey") ?? 'en'

  // FETCH AGENCY DETAILS BY ID
  const fetchAgencyData = () => {
    setLoading(true)
    let params = id
    getAgencyById(params).then(response => {
      setAgencyDetails(response)
      setAgentListOfAgencyData(response?.agentVMS)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    }).finally(() => {
      setLoading(false)
    })
  }

  // FETCH AGENCY DETAILS BY ID
  const agencyViewCount = () => {
    setLoading(true)
    let params = id
    agencyViewCountApi(params).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchAgencyData()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, [id, i18n?.language])


  useEffect(() => {
    dispatch(setHeaderClass({ className: "container" }))
    agencyViewCount()
  }, [])

  //SHOW MORE COLLAPSE STATE
  const [open, setOpen] = useState(false) //SHOW MORE COLLAPSE STATE
  // SPLIT PROPERTY DESCRIPTION INTO TWO PARTS
  const [firstThreeLines, remainingLines] = agencyData?.about && agencyData?.about[userLanguage] ? splitParagraph(agencyData?.about[userLanguage]) : '';
  return (
    <div className="body_content">
      {/* SEO IMPLEMENTATION */}
      <SeoComponent
        isDefault={true}
        canonical={process.env.REACT_APP_WEBSITE_WEB_URL + nameBasedRoutes?.agencyDetails?.path + "/" + id + agencyData?.slug ? agencyData?.slug : ''}
      />
      <LoaderMu loading={loading} />
      {/* Agent Section Area */}
      <section className="agent-single pt60">
        <div className="cta-agent bgc-thm-light mx-auto maxw1600 pt60 pb60 bdrs12 position-relative mx20-lg">
          <div className="container">
            {/* AGENT PROFILE SECTION */}
            <div className="row align-items-center">
              <div className="col-xl-7">
                <div className="agent-single d-sm-flex align-items-center">
                  <div className="bdrs24 default-box-shadow1 mb30-sm">
                    <img
                      className="mw-290 bdrs24 "
                      src={agencyData.logo != null ? agencyData.logo : Agencysinglebg}
                      alt="Agencysingle"
                    />
                  </div>
                  <div className="single-contant ml30 ml0-xs">
                    <h2 className="title mb-0">{agencyData?.name}</h2>
                    <p className="fz15">
                      {agencyData?.address}
                    </p>
                    {
                      agencyData?.mobileNumber ? <div className="agent-meta mb15 d-md-flex align-items-center">
                        {
                          agencyData?.phoneCode ? <span className="text fz15 pe-2 bdrr1">
                            <i className="flaticon-call pe-1" />
                            {agencyData?.phoneCode}
                          </span> : ""
                        }
                        <span className="text fz15 pe-2 ps-2 bdrr1">
                          <i className="flaticon-smartphone pe-1" />
                          {agencyData?.mobileNumber ?? ''}
                        </span>
                      </div> : ""
                    }

                  </div>
                </div>
                <div className="img-box-11 position-relative d-none d-xl-block">
                  <img
                    className="img-2 bounce-x"
                    src={Agencyjump1}
                    alt="Agencyjump1"
                  />
                  <img
                    className="img-3 bounce-y"
                    src={Agencyjump2}
                    alt="Agencyjump2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* AGENCY PAGE ABOUT SECTION */}
        <div className="container">
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            <div className="col-lg-8 pr40 pr20-lg">
              <div className="row">
                <div className="col-lg-12">
                  <div className="agent-single-details mt30 pb30 bdrb1">
                    <h6 className="fz17 mb30">{t("ABOUT")} {agencyData?.name} {t("REAL ESTATE")} </h6>
                    <p className="text text-justify mb10">
                      {firstThreeLines ?? ''}
                    </p>
                    {
                      agencyData?.about && remainingLines ?
                        <div className="agent-single-accordion">
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div >
                              <Collapse in={open} dimension="height">
                                <div id="flush-collapseOne">
                                  <p className="text text-justify">
                                    {remainingLines ?? ''}
                                  </p>
                                </div>
                              </Collapse>
                            </div>
                            <button
                              onClick={() => setOpen(!open)}
                              aria-controls="flush-collapseOne"
                              aria-expanded={open}
                              className="accordion-button  p-0 collapsed"
                            >
                              {
                                open ? t("SHOW LESS") : t('SHOW MORE')
                              }
                            </button>
                          </div>
                        </div> : ""
                    }
                  </div>
                </div>
              </div>
              {/* AGENT PROPERTY LISTING */}
              <PropertyListing agencyId={agencyData?.id} />
              {/* Agent Listing */}
              <div className="row pt30 bdrb1">
                <div className="col-lg-12">
                  <h6 className="fz17">{t("AGENTS")}</h6>
                </div>
                {
                  agentListOfAgencyData.map((item, index) => {
                    return <div key={index + 1} className="col-sm-6 col-md-4">
                      <div className="feature-style2 mb20">
                        <div className="feature-img">
                          <img
                            className="bdrs12"
                            src={agencyData.imageUrl != null ? agencyData.imageUrl : agentDefaultImage}
                            alt="Agencysingleagent"
                          />
                        </div>
                        <div className="feature-content pt20">
                          <Link
                            to={`${nameBasedRoutes?.agentDetails?.path + "/" + item?.id}${item.slug ? '/' + item?.slug : ''}`}
                            className="fz16 fw600 mb-1"
                          >
                            {item.firstName}
                          </Link>
                          <p className="text fz15">{t("AGENT")}</p>
                        </div>
                      </div>
                    </div>
                  })
                }
              </div>
            </div>
            {/* CONTACT FORM */}
            <div className="col-lg-4">
              <div className="agent-single-form home8-contact-form default-box-shadow1 bdrs12 bdr1 p30 mb30-md bgc-white position-relative">
                <h4 className="form-title mb25">{t("CONTACT FORM")}</h4>
                <ContactForm agencyId={id} />
              </div>
              {/* AGENCY INFORMATION CARD */}
              <div className="agen-personal-info position-relative bgc-white default-box-shadow1 bdrs12 p30 mt30">
                <div className="widget-wrapper mb-0">
                  <h6 className="title fz17 mb35">{t("AGENCY INFORMATION")}</h6>
                  <div className="list-news-style d-flex align-items-center justify-content-between mb10">
                    <div className="flex-shrink-0">
                      <h6 className="fz14 mb-0">{t("ADDRESS")}</h6>
                    </div>
                    <div className="news-content flex-shrink-1 ms-3 text-end">
                      <p className="text mb-0 fz14">{agencyData?.address}</p>
                    </div>
                  </div>
                  {/* <div className="list-news-style d-flex align-items-center justify-content-between mb10">
                    <div className="flex-shrink-0">
                      <h6 className="fz14 mb-0">{t("OFFICE")}</h6>
                    </div>
                    <div className="news-content flex-shrink-1 ms-3 text-end">
                      <p className="text mb-0 fz14">{agencyData?.mobileNumber}</p>
                    </div>
                  </div> */}

                  {
                    agencyData?.mobileNumber ?
                      <div className="list-news-style d-flex align-items-center justify-content-between mb10">
                        <div className="flex-shrink-0">
                          <h6 className="fz14 mb-0">{t("MOBILE")}</h6>
                        </div>
                        <div className="news-content flex-shrink-1 ms-3 text-end">
                          <p className="text mb-0 fz14">{agencyData?.mobileNumber}</p>
                        </div>
                      </div> : ""
                  }

                  {
                    agencyData?.email ? <div className="list-news-style d-flex align-items-center justify-content-between mb10">
                      <div className="flex-shrink-0">
                        <h6 className="fz14 mb-0">{t("EMAIL")}</h6>
                      </div>
                      <div className="news-content flex-shrink-1 ms-3 text-end">
                        <p className="text mb-0 fz14">{agencyData?.email}</p>
                      </div>
                    </div> : ""
                  }
                  {
                    agencyData?.websiteUrl ? <div className="list-news-style d-flex align-items-center justify-content-between mb10">
                      <div className="flex-shrink-0">
                        <h6 className="fz14 mb-0">{t("WEBSITE")}</h6>
                      </div>
                      <div className="news-content flex-shrink-1 ms-3 text-end text-decoration-underline">
                        <a href={agencyData?.websiteUrl} target="_blank" className="text mb-0 fz14">{agencyData?.websiteUrl}</a>
                      </div>
                    </div> : ""
                  }
                  {
                    agencyData?.facebookUrl ? <div className="list-news-style d-flex align-items-center justify-content-between mb10">
                      <div className="flex-shrink-0">
                        <h6 className="fz14 mb-0">{t("FACEBOOK URL")}</h6>
                      </div>
                      <div className="news-content flex-shrink-1 ms-3 text-end text-decoration-underline word-br">
                        <a href={agencyData?.facebookUrl} target="_blank" className="text mb-0 fz14">{agencyData?.facebookUrl}</a>
                      </div>
                    </div> : ""
                  }
                  {
                    agencyData?.instagramUrl ? <div className="list-news-style d-flex align-items-center justify-content-between mb10">
                      <div className="flex-shrink-0">
                        <h6 className="fz14 mb-0">{t("INSTAGRAM URL")}</h6>
                      </div>
                      <div className="news-content flex-shrink-1 ms-3 text-end text-decoration-underline word-br">
                        <a href={agencyData?.instagramUrl} target="_blank" className="text mb-0 fz14">{agencyData?.instagramUrl}</a>
                      </div>
                    </div> : ""
                  }
                  {
                    agencyData?.linkedinUrl ? <div className="list-news-style d-flex align-items-center justify-content-between mb10">
                      <div className="flex-shrink-0">
                        <h6 className="fz14 mb-0">{t("LINKEDIN URL")}</h6>
                      </div>
                      <div className="news-content flex-shrink-1 ms-3 text-end text-decoration-underline word-br">
                        <a href={agencyData?.linkedinUrl} target="_blank" className="text mb-0 fz14">{agencyData?.linkedinUrl}</a>
                      </div>
                    </div> : ""
                  }
                  {/* <div className="list-news-style d-flex align-items-center justify-content-between">
                    <div className="flex-shrink-0">
                      <h6 className="fz14 mb-0">{t("MEMBER SINCE")}</h6>
                    </div>
                    <div className="news-content flex-shrink-1 ms-3 text-end">
                      <p className="text mb-0 fz14">{t("NOT GIVEN")}</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {/* Our Footer */}
    </div>
  );
};
