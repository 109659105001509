import React, { useEffect, useState } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPageContent } from '../../services/cmsPageService';
import LoaderMu from '../../components/common/LoaderMu';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
export const PrivacyPolicyMobile = () => {

  const [loading, setLoading] = useState(false)

  const [pageContent, setPageContent] = useState({})

  const { i18n } = useTranslation() 

  const cookies = new Cookies()


  const location = useLocation()

  const params = new URLSearchParams(location?.search)

  const navigate = useNavigate()

  const lang = params.get("lang") === "ar" ? "ar" : "en"

  useEffect(() => {
    cookies.remove("userLangKey")
    cookies.set("userLangKey", lang)
    i18n.changeLanguage(lang)
  }, [lang])

  // FETCH CMS DATA
  const fetchCmsData = () => {
    setLoading(true)
    getPageContent("privacy-policy").then(response => {
      setLoading(false)
      setPageContent(response)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message
      );
    })
  }

  // FETCH CMS DATA
  useEffect(() => {
    fetchCmsData()
  }, [lang])

  // LANGUAGE FEATURE IS NOT IMPLEMENTED SO FOR NOW DATA IS SHOWING IN ENGLISH ONLY
  return (
    <div className="body_content"  dir={lang === "ar"? "rtl" : "ltr"}>
    <LoaderMu loading={loading}/>
      {/* Our Contact With Map */}
      <section className="breadcumb-section pt60 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">{pageContent?.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 pb60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb30">
              <div className="static-data text-justify fs-16 mt-2" dangerouslySetInnerHTML={{ __html: pageContent?.content }} />
            </div>
          </div>
        </div>
      </section>
      {/* <Footer/> */}
    </div>
  )
}
