import React, { useState } from "react";
import logoImg2 from "../../../assets/images/Amaken-logo2.svg";
import { Link, useNavigate } from "react-router-dom";
import logoar2 from "../../../assets/images/Amaken-logo2-ar.svg"
import LoginModal from "../../common/LoginModal";
import { useDispatch, useSelector } from "react-redux";
import userImg from "../../../assets/images/resource/defaultuser.png";
import { logoutUser } from "../../../redux/userProfileSlics";
import storage from "../../../helpers/storage";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
function Header() {

  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const cookies = new Cookies()
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [showLogin, setShowLogin] = useState(false);
  const userData = useSelector((state) => state?.user?.userData);
  const headerClass = useSelector((state) => state?.layout?.headerClass)

  // GET USER PREFERRED LANGUAGE
  const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

  // Execute log out task and remove storage
  const handleLogout = () => {
    dispatch(logoutUser());
    storage.clearToken();
    localStorage.removeItem('userType');
    localStorage.removeItem('US_token');
    localStorage.removeItem('user-info');
    localStorage.removeItem('refresh-token');
    // navigate(nameBasedRoutes?.home?.path)
  };

  return (
    <div>
      <header className="header-nav nav-innerpage-style bdrb1 main-menu">
        {/* Ace Responsive Menu */}
        <nav className="posr">
          <div className={`${headerClass} posr menu_bdrt1`}>
            <div className="row align-items-center justify-content-between">
              <div className="col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="logos">
                    {
                      userLanguage === "en" ? <Link className="header-logo" to="/">
                        <img src={logoImg2} alt="Header Logo" />
                      </Link> : <Link className="header-logo" to="/">
                        <img src={logoar2} alt="Header Logo" />
                      </Link>
                    }
                  </div>
                  {/* Responsive Menu Structure*/}
                  <ul
                    id="respMenu"
                    className="ace-responsive-menu mx40 mx10-lg"
                    data-menu-style="horizontal"
                  >
                    <li className="visible_list">
                      <Link className="list-item" to="/">
                        <span className="title">{t('HOME')}</span>
                      </Link>
                    </li>
                    <li className="megamenu_style">
                      <Link className="list-item" to="/search-by-amaken-map">
                        <span className="title">{t('SEARCH BY AMAKEN MAP')}</span>
                      </Link>
                    </li>
                    <li className="visible_list">
                      <Link className="list-item" to="/agencies">
                        <span className="title">{t('AGENCIES')}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {userData && Object.keys(userData)?.length !== 0 ? (
                <div className="col-auto">
                  <div className="d-flex align-items-center">
                    <div className="text-center text-lg-end header_right_widgets">
                      <ul className="mb0 d-flex justify-content-center justify-content-sm-end p-0">
                        <li className=" user_setting">
                          <div className="dropdown">
                            <a
                              className="btn"
                              href="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {
                                userData?.imageUrl && userData?.imageUrl !== '' ? <img
                                  className="bdrs24 userimg-sm"
                                  src={userData?.imageUrl ?? userImg}
                                  alt="user-img"
                                /> : <img
                                  className="bdrs24 userimg-sm"
                                  src={userImg}
                                  alt="user-img"
                                />
                              }

                            </a>
                            <div className={`${i18n.language === 'ar' ? 'text-right' : ''} dropdown-menu`}>
                              <div className="user_setting_content">
                                <p className="fz12 fw400 ff-heading ">
                                  {t("WELCOME")}:{" "}
                                  <span className="fz16 fw600">{userData?.firstName}</span>
                                </p>
                                <p className="fz14 fw400 ff-heading text-gray mb-1 bb1">
                                  {t("MANAGE ACCOUNT")}
                                </p>
                                <Link className="dropdown-item lh0 mb5" to="/favorites">
                                  <i className="flaticon-protection mr10"></i>
                                  {t('MY FAVORITES')}
                                </Link>
                                <Link
                                  className="dropdown-item lh0 mb5"
                                  to="/profile-user"
                                >
                                  <i className="flaticon-user mr10"></i>
                                  {t('MY PROFILE (USER)')}
                                </Link>

                                <Link
                                  className="dropdown-item lh0 mb5"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleLogout();
                                  }}
                                >
                                  <i className="flaticon-logout mr10"></i>{t("LOGOUT")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              ) : (
                <div className="col-auto">
                  <LoginModal
                    show={showLogin}
                    close={() => setShowLogin(false)}
                  />
                  <div className="d-flex align-items-center">
                    <Link
                      className="login-info d-flex align-items-center"
                      // data-bs-toggle="modal"
                      to={process.env.REACT_APP_WEBSITE_WEB_URL}
                      role="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowLogin(true);
                      }}
                    >
                      <i className="far fa-user-circle fz16 me-2" />{" "}
                      <span>{t('SIGN IN')} / {t('SIGN UP')}</span>
                    </Link>
                    <Link
                      className="ud-btn btn-transparent add-property bdrs60 mx-2 mx-xl-4"
                      to={process.env.REACT_APP_AGENCY_WEB_URL}
                    >
                      {t('AGENCIES LOGIN')}
                      <i className="fal fa-arrow-right-long" />
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
