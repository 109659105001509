export const FloorNoDropdownList = [
    {
        id: 1,
        value: -1,
        label: {
            en: 'Basement',
            ar: 'الطابق السفلي'
        }
    },
    {
        id: 2,
        value: 0,
        label: {
            en: 'Ground Floor',
            ar: 'الطابق الأرضي'
        }
    },
    {
        id: 3,
        value: 1,
        label: {
            en: '1st Floor',
            ar: 'الطابق الأول'
        }
    },
    {
        id: 4,
        value: 2,
        label: {
            en: '2nd Floor',
            ar: 'الطابق الثاني'
        }
    },
    {
        id: 5,
        value: 3,
        label: {
            en: '3rd Floor',
            ar: 'الطابق الثالث'
        }
    },
    {
        id: 6,
        value: 4,
        label: {
            en: '4th Floor',
            ar: 'الطابق الرابع'
        }
    },
    {
        id: 7,
        value: 5,
        label: {
            en: '5th Floor',
            ar: 'الطابق الخامس'
        }
    },
    {
        id: 8,
        value: 6,
        label: {
            en: '6th Floor',
            ar: 'الطابق السادس'
        }
    },
    {
        id: 9,
        value: 7,
        label: {
            en: '7th Floor',
            ar: 'الطابق السابع'
        }
    },
    {
        id: 10,
        value: 8,
        label: {
            en: '8th Floor',
            ar: 'الطابق الثامن'
        }
    },
    {
        id: 11,
        value: 9,
        label: {
            en: '9th Floor',
            ar: 'الطابق التاسع'
        }
    },
    {
        id: 12,
        value: 10,
        label: {
            en: '10th Floor',
            ar: 'الطابق العاشر'
        }
    },
    {
        id: 13,
        value: 11,
        label: {
            en: '11th Floor',
            ar: 'الطابق الحادي عشر'
        }
    },
    {
        id: 14,
        value: 12,
        label: {
            en: '12th Floor',
            ar: 'الطابق الثاني عشر'
        }
    },
    {
        id: 15,
        value: 13,
        label: {
            en: '13th Floor',
            ar: 'الطابق الثالث عشر'
        }
    },
    {
        id: 16,
        value: 14,
        label: {
            en: '14th Floor',
            ar: 'الطابق الرابع عشر'
        }
    },
    {
        id: 17,
        value: 15,
        label: {
            en: '15th Floor',
            ar: 'الطابق الخامس عشر'
        }
    }
]