import { Link } from "react-router-dom";
import UploadProfile from "../../components/userProfile/UploadProfile";
import UpdateProfileForm from "../../components/userProfile/UpdateProfileForm";
import ChangePassword from "../../components/userProfile/ChangePassword";
import DeleteAccount from "../../components/userProfile/DeleteAccount";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setHeaderClass } from "../../redux/layoutSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { LoginRequiredError } from "../../components/LoginRequiredError";
import { nameBasedRoutes } from "../../router/public";
import SeoComponent from "../../components/seo/SeoComponent";

export const Profileuser = () => {

  const { t } = useTranslation()

  // GETTING USER DATA FROM REDUX STATE
  const userData = useSelector((state) => state?.user?.userData)
  const dispatch = useDispatch()


  useEffect(() => {
    // SETTING HEADER
    dispatch(setHeaderClass({ className: "container" }))
  }, [])

  // STATE EMAIL WARNING IS DISPLAYED OR NOT
  const [errorDisplayed, setErrorDisplayed] = useState(false)

  useEffect(() => {
    if (userData?.emailVerified === false && !errorDisplayed) {
      toast.warn(t("YOUR EMAIL IS NOT VERIFIED. PLEASE VERIFY YOUR EMAIL ADDRESS TO PROCEED."))
      setErrorDisplayed(true)
    }
  }, [])


  if (!userData) {
    return <LoginRequiredError />
  } else {
    return (
      <div className="body_content">
        <SeoComponent
          isDefault={true}
        />
        <section className="breadcumb-section pt60 pb30">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <h2 className="title">{t("USER PROFILE")}</h2>
                  <div className="breadcumb-list">
                    <Link to="/">{t('HOME')}</Link>
                    <Link to="/profile-user" className="ms-1">
                      {t("USER PROFILE")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="ps-widget mt-3 bdr1 bdrs12 p30 mb30 overflow-hidden position-relative">
                    {/* UPLOAD PROFILE COMPONENT */}
                    <UploadProfile />
                    {/* UPDATE PROFILE DETAILS FORM */}
                    <UpdateProfileForm />
                  </div>
                  {/* CHANGE USER PASSWORD COMPONENT */}
                  {
                    userData?.passwordSet ? <ChangePassword /> : ""
                  }
                  {/* DELETE USER ACCOUNT COMPONENT */}
                  <DeleteAccount />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
