import { Modal, Button, Radio, Checkbox, Space, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import UserInputText from '../common/UserInputText';
import { Form, Formik } from 'formik';
import RangeSlider from '../common/RangeSlider';
import SelectAntd from '../common/SelectAntd';
import Collapse from 'react-bootstrap/Collapse';
import { agencyAgentListApi, governorateListApi, propertyInfoApi, propertyTypeListApi } from '../../services/advanceSearch';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { createFormInitialData, manipulateUrlParams } from '../../services/propertyService';
import { useLocation, useNavigate } from 'react-router-dom';
import { advancedSearchValidation } from '../../validations/ValidationSchema';

function AdvanceSearchModal({ isOpen, setModalOpen, offeringType, setPropCategoryList, propertyCategory, navigatePath, ...rest }) {

	const { t } = useTranslation()
	const { i18n } = useTranslation()
	const handleCancel = () => {
		setModalOpen(false);
	};

	const location = useLocation()

	const params = new URLSearchParams(location.search)


	// const navigatePath = nameBasedRoutes?.propertyList?.path;


	// GET ALL SEARCH PARAMS
	const paramsObj = Object.fromEntries(new URLSearchParams(params));





	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)


	const [priceRangeVal, setPriceRangeVal] = useState([0, 0])

	const [openOtherFeatures, setOpenOtherFeatures] = useState(false)

	const [propertyInfoData, setPropertyInfoData] = useState({})

	const [propertyTypeDropdownList, setPropertyTypeDropdownList] = useState([])

	const [agencyAgentDropList, setAgencyAgentDropList] = useState([])

	const [governorateList, setGovernorateList] = useState([])

	const [isSave, setIsSave] = useState(false)

	const [initialData, setInitialData] = useState({
		propertyOfferingType: offeringType ?? "",
		propertyCategory: propertyCategory ?? '',
		// minPrice : priceRangeVal[0],
		// maxPrice : priceRangeVal[1],
		propertyTypeIdList: [],
		governorateId: '',
		bedroom: "any",
		bathroom: "any",
		minAreaSqm: "",
		maxAreaSqm: "",
		propertyId: '',
		agency_agent: '',
		searchKeyword: paramsObj?.keyword ?? "",
		have3DTour: false,
	})


	// FETCH PROPERTY INFO DATA
	const fetchPropertyInfoData = () => {
		propertyInfoApi().then((response) => {
			setPropertyInfoData(response)
			const propCategoryArr = Object.keys(response?.propertyCategory).map((element) => ({ value: element, label: response?.propertyCategory[element] }))
			setPriceRangeVal([paramsObj?.minPrice ? parseInt(paramsObj?.minPrice) : response?.minPrice,
			paramsObj?.maxPrice ? parseInt(paramsObj?.maxPrice) : response?.maxPrice])
			if (setPropCategoryList) {
				setPropCategoryList(propCategoryArr)
			}
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message ?? error?.message)
		})
	}

	// FETCH PROPERTY DROPDOWN LIST DATA
	const fetchPropertyListData = () => {
		propertyTypeListApi().then((response) => {

			const transformedData = response.reduce((acc, item) => {
				const categoryIndex = acc.findIndex(category => category?.label === item?.propertyCategory);
				if (categoryIndex !== -1) {
					acc[categoryIndex].options.push({
						label: item?.propertyType
						, value: item.id
					});
				} else {
					acc.push({
						label: item.propertyCategory,
						options: [{ label: item?.propertyType, value: item.id }]
					});
				}
				return acc;
			}, []);

			setPropertyTypeDropdownList(transformedData)
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message ?? error?.message)
		})
	}

	// FETCH AGENCY AGENT DROPDOWN LIST  

	const fetchAgencyAgentData = (searchValue) => {

		const params = {
			key: searchValue
		}

		agencyAgentListApi(params).then((response) => {
			const dropList = response?.map((element) => {
				return { label: element?.name, value: element?.entityId }
			})
			setAgencyAgentDropList(dropList)
			setLoading(false)

		}).catch((error) => {
			setLoading(false)
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message ?? error?.message)
		})
	}

	// FETCH GOVERNORATE (LOCATION) DROPDOWN LIST
	const governorateListData = () => {
		setLoading(false)
		governorateListApi().then((response) => {
			const dropList = response?.map((element) => {
				return { label: element?.capital, value: element?.id }
			})
			setGovernorateList(dropList)
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message ?? error?.message)
		})
	}

	useEffect(() => {
		fetchPropertyInfoData()
		fetchPropertyListData()
		governorateListData()
	}, [i18n?.language])

	// SET INITIAL VALUE FOR ADVANCE SEARCH MODAL

	useEffect(() => {
		setInitialData((prev) => ({
			...prev, propertyOfferingType: offeringType ?? null,
			propertyCategory: propertyCategory ? propertyCategory : null
		}))

	}, [propertyCategory, offeringType])

	// SCROLL LOCK WHEN ADVANCE MODAL OPEN
	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('body-scroll-lock');
		} else {
			document.body.classList.remove('body-scroll-lock');
		}
	}, [isOpen])

	useEffect(() => {
		if (location?.search !== '') {
			const searchInitialData = createFormInitialData(paramsObj)

			if (searchInitialData?.entityName && searchInitialData?.entityId) {
				//  fetchAgencyAgentData(searchInitialData?.entityName)
				setAgencyAgentDropList(() => ([{ label: searchInitialData?.entityName, value: searchInitialData?.entityId }]))
			}
			setInitialData({
				searchKeyword: searchInitialData?.keyword ?? "",
				propertyOfferingType: (searchInitialData?.propertyOfferingType ? searchInitialData?.propertyOfferingType : offeringType ?? ""),
				propertyCategory: searchInitialData?.propertyCategory ?? '',
				propertyTypeIdList: searchInitialData?.propertyTypeIdList ?? [],
				governorateId: searchInitialData?.governorateId ? parseInt(searchInitialData?.governorateId) : '',
				bedroom: searchInitialData?.bedroom ?? "any",
				bathroom: searchInitialData?.bathroom ?? "any",
				minAreaSqm: searchInitialData?.minAreaSqm ? parseInt(searchInitialData?.minAreaSqm) : "",
				maxAreaSqm: searchInitialData?.maxAreaSqm ? parseInt(searchInitialData?.maxAreaSqm) : "",
				propertyId: searchInitialData?.propertyId ?? '',
				agency_agent: searchInitialData?.entityId ?? '',
				have3DTour: searchInitialData?.have3DTour == "true" ? true : false,
			})


			if (searchInitialData?.minPrice && searchInitialData?.maxPrice) {
				setPriceRangeVal([parseInt(searchInitialData?.minPrice), parseInt(searchInitialData?.maxPrice)])
			}


		}
	}, [location?.search])


	// HANDLE SUBMIT
	const handleSubmit = (values) => {

		const formData = {
			keyword: values?.searchKeyword && values?.searchKeyword !== '' ?
				values?.searchKeyword : null,
			propertyOfferingType: values?.propertyOfferingType ?? offeringType?.toUpperCase() ?? null,
			propertyTypeIdList: values?.propertyTypeIdList && values?.propertyTypeIdList?.length > 0 ?
				values?.propertyTypeIdList : null,
			propertyCategory: values?.propertyCategory ?? null,
			minPrice: priceRangeVal[0] ?? 0,
			maxPrice: priceRangeVal[1] ?? 0,
			governorateId: values?.governorateId ?? null,
			bedroom: values?.bedroom && values?.bedroom !== 'any' ? values?.bedroom : null,
			bathroom: values?.bathroom && values?.bathroom !== 'any' ? values?.bathroom : null,
			minAreaSqm: values?.minAreaSqm ?? null,
			maxAreaSqm: values?.maxAreaSqm ?? null,
			amakenId: values?.propertyId ?? null,
			have3DTour: values?.have3DTour === true ? true : null,
			entityId: values?.agency_agent ?? null,
			amenityIdList: values?.amenities && values?.amenities?.length > 0 ?
				values?.amenities : null,
			save: isSave ?? false
		}
		const selectedagent = agencyAgentDropList.filter((element) => element?.value === values?.agency_agent)

		if (selectedagent && selectedagent?.length > 0) {
			formData.entityName = selectedagent[0]?.label
		}
		const filteredFormData = Object.entries(formData)
			.filter(([key, value]) => (value !== null && value !== '')) // Filter out properties with null values
			.map(([key, value]) => ({ key, value })); // Map each key-value pair to an object
		const filteredKeys = filteredFormData.map(item => item.key);
		const formDataKeys = Object.keys(formData);
		// KEYS TO REMOVE FROM PAGINATION
		const remainingKeys = formDataKeys.filter(key => !filteredKeys.includes(key))
			.map(key => (key))

		const navigateURl = manipulateUrlParams(navigatePath, filteredFormData, remainingKeys)
		setModalOpen(false)
		setIsSave(false)
		navigate(navigateURl)
	}

	// HANDLE RESET FILTER
	const handleResetFilter = () => {
		// navigate(navigatePath)
		// setModalOpen(false)

		setInitialData({
			keyword: "",
			propertyOfferingType: offeringType ?? "",
			propertyCategory: propertyCategory ?? '',
			// minPrice : priceRangeVal[0],
			// maxPrice : priceRangeVal[1],
			propertyTypeIdList: [],
			governorateId: '',
			bedroom: "any",
			bathroom: "any",
			minAreaSqm: "",
			maxAreaSqm: "",
			propertyId: '',
			agency_agent: '',
			searchKeyword: "",
			have3DTour: false,
		})
	}


	return (
		<Modal title={<h5 className='modal-title' >{t('ADVANCE SEARCH')}</h5>} width={700} open={isOpen} onCancel={handleCancel} footer={false}  className={`advance-search-modal top10 ${i18n?.language === 'ar' ? 'direction-rtl' : ''}`}
		>
			<div className="advance-feature-modal" dir={i18n?.language === 'ar' ? 'rtl' : 'ltr'}>
				{/* Modal */}
				<div className="modal-body">
					<Formik
						initialValues={initialData}
						onSubmit={(values, actions) => {
							actions.setSubmitting(false);
							handleSubmit(values);
						}}
						validationSchema={advancedSearchValidation}
						enableReinitialize={true}
					>
						{({
							handleSubmit,
							handleChange,
							handleBlur,
							values,
							setFieldValue,
							setFieldTouched,
							touched,
							isValid,
							errors,
							setFieldError
						}) => (
							<Form>
								<div className="col-lg-12">
									{/* SEARCH KEYWORD */}
									<div className="row">
										<div className="col-12">
											<div className="widget-wrapper">
												<h6 className="list-title">{t('SEARCH KEYWORD')}</h6>
												<div className="form-style2">
													<UserInputText
														type="text"
														placeholder={t("SEARCH")}
														id="searchKeyword"
														name="searchKeyword"
														handleChange={handleChange}
														handleBlur={handleBlur}
														value={values?.searchKeyword}
														className={`form-control ${touched?.searchKeyword && errors?.searchKeyword
															? "error"
															: ""
															}`}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="widget-wrapper">
												<h6 className="list-title">{t('PROPERTY STATUS')}</h6>
												<div className="radio-element">
													<div className="form-check p-0 mb10 antdRadiocircle">
														<Radio.Group
															id={'propertyOfferingType'}
															name={'propertyOfferingType'}
															onChange={(event) => setFieldValue("propertyOfferingType", event?.target?.value)}
															value={values?.propertyOfferingType}
														>
															<Space direction="vertical">
																{
																	propertyInfoData && propertyInfoData?.propertyOfferingType && Object.keys(propertyInfoData?.propertyOfferingType).map((key, index) => (
																		<Radio key={index + 1} value={key}>{propertyInfoData?.propertyOfferingType[key]}</Radio>
																	))
																}
															</Space>
														</Radio.Group>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="widget-wrapper">
												<h6 className="list-title">{t("PROPERTY CATEGORY")}</h6>
												<div className="form-check p-0 mb10 antdRadiocircle">
													<Radio.Group
														name="propertyCategory"
														id="propertyCategory"
														onChange={(event) => {
															setFieldValue("propertyCategory", event?.target?.value);
														}}
														value={values?.propertyCategory}
														className="w-100"
													>
														<Space direction="vertical">
															{
																propertyInfoData && propertyInfoData?.propertyCategory && Object.keys(propertyInfoData?.propertyCategory).map((key, index) => (
																	<Radio key={index + 1} value={key}>{propertyInfoData?.propertyCategory[key]}</Radio>
																))
															}
														</Space>
													</Radio.Group>
												</div>
											</div>
										</div>
									</div>
									{/* PRICE RANGE SLIDER AND VALUES */}
									<div className="row">
										<div className="col-lg-12">
											<div className="widget-wrapper">
												<h6 className="list-title">{t('PRICE RANGE')}</h6>
												{/* Range Slider Mobile Version */}
												<div className="range-slider-style modal-version">
													<div className='ps-3'>
														<RangeSlider
															name="price"
															id="price"
															handleChange={(event) => setPriceRangeVal(event.target.value)}
															value={priceRangeVal}
															min={propertyInfoData?.minPrice}
															max={propertyInfoData?.maxPrice}
														/>
													</div>
													<div className="range-wrapper">
														<div className="mb30 mt35" id="slider" />
														<div className="d-flex align-items-center">
															<UserInputText
																id="minPrice"
																name="minPrice"
																value={'JOD ' + priceRangeVal[0] ?? 'JOD0'}
																disabled={true}
																className={`form-control ${touched?.minPrice && errors?.minPrice
																	? "error"
																	: ""
																	}`}
															/>
															<i className="fa-sharp fa-solid fa-minus mx-2 dark-color icon mb20" />
															<UserInputText
																id="maxPrice"
																name="maxPrice"
																value={'JOD ' + priceRangeVal[1] ?? 'JOD1000000'}
																disabled={true}
																className={`form-control ${touched?.maxPrice && errors?.maxPrice
																	? "error"
																	: ""
																	}`}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* PROPERTY TYPE AND LOCATION */}
									<div className="row">
										<div className="col-sm-6">
											<div className="widget-wrapper">
												<h6 className="list-title">{t('TYPE')}</h6>
												<div>
													<SelectAntd
														id="propertyTypeIdList"
														name="propertyTypeIdList"
														className={`form-control min-h-55  h-auto w-100 ${touched?.propertyTypeIdList && errors?.propertyTypeIdList
															? "error"
															: ""
															}`}
														mode={"multiple"}
														value={values?.propertyTypeIdList}
														handleChange={(value, option) => {
															setFieldValue("propertyTypeIdList", value)
															setFieldValue("propertyCategory", null)
														}}
														options={propertyTypeDropdownList ?? []}
														placeholder={t("SELECT")}
														showSearch={true}
														filterOption={(input, option) =>
															(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
													/>
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="widget-wrapper">
												<h6 className="list-title">{t("LOCATION")}</h6>
												<div>
													<SelectAntd
														filterOption={(input, option) =>
															(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
														id="governorateId"
														name="governorateId"
														className={`form-control w-100 ${touched?.governorateId && errors?.governorateId
															? "error"
															: ""
															}`}
														handleChange={(value) => setFieldValue("governorateId", value)}
														value={values?.governorateId}
														options={governorateList}
														placeholder={"Select"}
														showSearch={true}
													/>

												</div>
											</div>
										</div>
									</div>
									{/* NO OF BEDROOMS AND BATHROOMS */}
									<div className="row">
										<div className="col-sm-6">
											<div className="widget-wrapper">
												<h6 className="list-title">{t("BEDROOMS")}</h6>
												<Radio.Group
													name="bedroom"
													id="bedroom"
													onChange={(event) => {
														setFieldValue("bedroom", event?.target?.value);
													}}
													onBlur={handleBlur}
													value={values?.bedroom}
													className='antdRadioButton'
												>
													<Radio.Button value="any">{t('ANY')}</Radio.Button>
													{
														propertyInfoData?.bedroom ?
															Object.keys(propertyInfoData?.bedroom)?.map((element, index) => {
																return <Radio.Button key={index + 1} value={element}>
																	{propertyInfoData?.bedroom[element]}
																</Radio.Button>

															}) : ""
													}
												</Radio.Group>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="widget-wrapper">
												<h6 className="list-title">{t('BATHROOMS')}</h6>
												<Radio.Group
													name="bathroom"
													id="bathroom"
													onChange={(event) => {
														setFieldValue("bathroom", event?.target?.value);
													}}
													onBlur={handleBlur}
													value={values?.bathroom}
													className='antdRadioButton'>
													<Radio.Button value="any">{t('ANY')}</Radio.Button>
													{
														propertyInfoData?.bathroom ?
															Object.keys(propertyInfoData?.bathroom)?.map((element, index) => {
																return <Radio.Button key={index + 1} value={element}>
																	{propertyInfoData?.bathroom[element]}
																</Radio.Button>

															}) : ""
													}
												</Radio.Group>
											</div>
										</div>
									</div>
									{/* SQUARE METER , PROPERTY ID ,3D TOUR*/}
									<div className="row">
										<div className="col-sm-6">
											<div className="widget-wrapper">
												<h6 className="list-title">{t('SQUARE METER')}</h6>
												<div className="space-area">
													<div className="row">
														<div className="col form-style2">
															<UserInputText
																id="minAreaSqm"
																name="minAreaSqm"
																type="number"
																placeholder={t("MIN.")}
																handleChange={handleChange}
																handleBlur={handleBlur}
																value={values?.minAreaSqm}
																className={`form-control ${touched?.minAreaSqm && errors?.minAreaSqm
																	? "error"
																	: ""
																	}`}
															/>
														</div>
														{/* <i className="fa-sharp fa-solid fa-minus mx-2 dark-color icon mb10" /> */}
														<div className="col form-style2">
															<UserInputText
																id="maxAreaSqm"
																name="maxAreaSqm"
																type="number"
																placeholder={t("MAX.")}
																handleChange={handleChange}
																handleBlur={handleBlur}
																value={values?.maxAreaSqm}
																className={`form-control ${touched?.maxAreaSqm && errors?.maxAreaSqm
																	? "error"
																	: ""
																	}`}
															/>
														</div>
													</div>

												</div>

											</div>
										</div>
										<div className="col-sm-6">
											<div className="row">
												<div className="col-6">
													<div className="widget-wrapper">
														<h6 className="list-title">{t('PROPERTY ID')}</h6>
														<div className="form-style2">
															<UserInputText
																id="propertyId"
																name="propertyId"
																type="text"
																placeholder="Property Id"
																handleChange={handleChange}
																handleBlur={handleBlur}
																value={values?.propertyId}
																className={`form-control ${touched?.propertyId && errors?.propertyId
																	? "error"
																	: ""
																	}`}
															/>
														</div>
													</div>
												</div>
												<div className="col-6">
													<div className="widget-wrapper">
														<h6 className="list-title">{t('HAVE 3D TOUR')}</h6>
														<div className="form-style2">
															<div className="switch-style1">
																<div className="form-check form-switch mb20 mt25">
																	<input
																		className="form-check-input"
																		type="checkbox"
																		checked={values?.have3DTour}
																		// value={values?.have3DTour}
																		onChange={(event) => setFieldValue("have3DTour", event?.target?.checked)}
																		id="have3DTour"
																		name="have3DTour"
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* AGENCY AGENT */}
									<div className="row">
										<div className="col-sm-6">
											<div className="widget-wrapper">
												<h6 className="list-title">{t('AGENCY/AGENT')}</h6>
												<div className="form-style2 w-100">
													<SelectAntd
														id="agency_agent"
														name="agency_agent"
														showSearch={true}
														handleSearch={fetchAgencyAgentData}
														filterOption={false}
														value={values?.agency_agent}
														handleChange={(value) => setFieldValue("agency_agent", value)}
														options={agencyAgentDropList ?? []}
														className={`form-control w-100 ${touched?.agency_agent && errors?.agency_agent
															? "error"
															: ""
															}`}
													>
													</SelectAntd>
												</div>
											</div>
										</div>
									</div>
									<div className="widget-wrapper">
										<div className="feature-accordion">
											<div className="accordion" id="accordionExample">
												<div className="accordion-item border-none">
													<h2 className="accordion-header" id="headingOne">
														<Button
															onClick={() => setOpenOtherFeatures(!openOtherFeatures)}
															aria-controls="collapseOne"
															aria-expanded={openOtherFeatures}
															className="accordion-button border-none p-0 after-none feature-button"
														>
															<span className="flaticon-settings" />{t("OTHER FEATURES")}
														</Button>
													</h2>
													<Collapse in={openOtherFeatures}>
														<div
															id="collapseOne"
															aria-labelledby="headingOne"
															data-bs-parent="#accordionExample"
														>
															<div className="accordion-body p-0 mt15">
																<Checkbox.Group
																	name="amenities"
																	id="amenities"
																	onChange={(value) => {
																		setFieldValue("amenities", value);
																	}}
																	onBlur={handleBlur}
																	value={values?.amenities}
																	className="w-100"
																>
																	<div className="row w-100">
																		{propertyInfoData?.amenities?.map((option) => {
																			if (option?.status === true) {
																				return <div key={option?.id} className="col-sm-6 col-lg-6 col-xxl-4 widget-wrapper mb20">
																					<div className="checkbox-style1">
																						<Checkbox checked={false}
																							value={option?.id}>
																							{option?.title}
																						</Checkbox>
																					</div>
																				</div>
																			}
																		}
																		)}
																		<span className="text-danger">{errors?.amenities}</span>
																	</div>
																</Checkbox.Group>
															</div>
														</div>
													</Collapse>
												</div>
											</div>
										</div>
									</div>
									<div className="modal-footer justify-content-between">
										<button type="reset" className="p-0 btn reset-button" onClick={handleResetFilter}>
											<span className="flaticon-turn-back" />
											<span className='text-decoration-underline'>{t("RESET ALL FILTERS")}</span>
										</button>
										<div className="btn-area">
											{/* SAVE AND APPLY BUTTON */}
											<button type="submit" onClick={() => {
												setIsSave(true)
												handleSubmit()
											}} className="ud-btn btn-thm me-2 my-1">
												{t('SAVE AND APPLY')}
												<i className="fal fa-arrow-right-long" />
											</button>
											<button type="submit" onClick={handleSubmit} className="btn ud-btn btn-white2 my-1 me-2">
												{t('APPLY')}
												<i className="fal fa-arrow-right-long" />
											</button>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</Modal>
	)
}

export default AdvanceSearchModal
