// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { arabicLang } from './arabic';
import { englishLang } from './english';
import Cookies from 'universal-cookie';

const cookies = new Cookies()


const userLanguage = cookies?.get("userLangKey")


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: userLanguage ?? 'en', // set the default language
    resources: {
      // TRANSLATIONS
      en: {
        translation: englishLang
      },
      ar : {
        translation : arabicLang
      }
      // add more languages as needed
    },
    fallbackLng: 'en', // use the default language if translation is missing
    interpolation: {
      escapeValue: false, // react already escapes by default
    },
  });

export default i18n;