
import React, { useState } from "react";
import InputText from "../../components/common/InputText";
import { ContactUsFormSchema } from "../../validations/ValidationSchema";
import { contactUsFormAPI } from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { toast } from "react-toastify";
import LoaderMu from "../../components/common/LoaderMu";
import { useTranslation } from "react-i18next";

// THIS FORM IS USING FOR AGENCY AND AGENT BOTH
const ContactForm = (props) => {

  const { t } = useTranslation()

  const agencyId = props.agencyId;  
  const [loading, setLoading] = useState(false);

  const initialDataValues = {
    firstName:  "",
    lastName:  "",
    email:  "",
    textMessage:  "",
  }

  // Submit onboarding handleChange
  const handleSubmit = async (value, actions) => {
    submitForm(value,actions);
  };

  // Sign up
  const submitForm = async (value,actions) => {
    
    setLoading(true)
    const agencyID = agencyId;
    const firstName = value.firstName;
    const lastName = value.lastName;
    const email = value.email;
    const textMessage = value.textMessage;

    contactUsFormAPI({
        agencyId: agencyID.toString(),
        fullName: firstName.toString()+' '+lastName.toString(),
        email: email.toString(),
        message: textMessage.toString(),
        deviceType: "BROWSER",
    })
      .then((response) => {
       
         setLoading(false);
         toast.success(response.message);
         //navigate(0)// refresh
        // Reset the form to its initial state
        actions.resetForm({
            values: initialDataValues,
        });
         
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  };

  

  return (
    <div className="d-flex flex-column pageContainer  h-100 overflow-hidden">
    <LoaderMu loading={loading}/> 
      <Formik
        validationSchema={ContactUsFormSchema}
        initialValues={initialDataValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
          <div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="mb20">
                    <label className="heading-color ff-heading fw600 mb10">
                      {t("FIRST NAME")}
                    </label>
                    <InputText controlId="firstName"
                      label={t("FIRST NAME")}
                      type="text"
                      variant="outlined"
                      value={values?.firstName}
                      name="firstName"
                      maxLength="300"
                      errorsField={errors?.firstName}
                      touched={touched?.firstName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      className="w-100" />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="mb20">
                    <label className="heading-color ff-heading fw600 mb10">
                        {t("LAST NAME")}
                    </label>
                    <InputText controlId="lastName"
                      label={t("LAST NAME")}
                      type="text"
                      variant="outlined"
                      value={values?.lastName}
                      name="lastName"
                      maxLength="300"
                      errorsField={errors?.lastName}
                      touched={touched?.lastName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      className="w-100" />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="mb20">
                    <label className="heading-color ff-heading fw600 mb10">
                    {t("EMAIL")}
                    </label>
                    <InputText controlId="email"
                      label={t("EMAIL")}
                      type="text"
                      variant="outlined"
                      value={values?.email}
                      name="email"
                      maxLength="300"
                      errorsField={errors?.email}
                      touched={touched?.email}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      className="w-100" />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="mb10">
                    <label className="heading-color ff-heading fw600 mb10">
                    {t("MESSAGE")}
                    </label>
                    <InputText controlId="textMessage"
                      label={t("MESSAGE")}
                      type="text"
                      variant="outlined"
                      value={values.textMessage}
                      name="textMessage"
                      maxLength="300"
                      errorsField={errors?.textMessage}
                      touched={touched?.textMessage}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      multiline={true}
                      rows={4}
                      className="w-100" />
                    
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="d-grid">
                    
                        <button
                            onClick={handleSubmit}
                            className="ud-btn btn-thm"
                            type="submit"
                        >
                            {t("SUBMIT")}
                            <i className="fal fa-arrow-right-long" />
                        </button>
                    </div>
                </div>
                </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
