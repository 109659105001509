import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import SignupModal from "../common/SignupModal";
import SignInModal from "./SignInModal";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

const LoginModal = (props) => {

  const {t} = useTranslation()
  const {i18n} = useTranslation()
  const cookies = new Cookies()
  const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

  
  return (
      <Modal
        show={props?.show}
        onHide={props?.close}
        className="signup-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dir={userLanguage=== "ar"?"rtl":"ltr"}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalToggleLabel">
           {t('WELCOME TO AMAKEN')}
          </h5>
          <button
            type="button"
            className="btn-close m-0"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick = {()=>props?.close()}
          />
        </div>
        <div className="modal-body">
          <div className="log-reg-form">
            <div className="navtab-style2">
              <nav>
                <div className="nav nav-tabs mb20" id="nav-tab2" role="tablist">
                  <button
                    className="nav-link active fw600"
                    id="navhometab"
                    data-bs-toggle="tab"
                    data-bs-target="#navhome"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                   {t('SIGN IN')}
                  </button>
                  <button
                    className="nav-link fw600"
                    id="navprofiletab"
                    data-bs-toggle="tab"
                    data-bs-target="#navprofile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    {t('SIGN UP')}
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent2">
              {/*USER SIGN IN FORM */}
                <div
                  className="tab-pane fade show active fz15"
                  id="navhome"
                  role="tabpanel"
                  aria-labelledby="navhometab"
                >
                  <div className="form-style1">
                    <SignInModal close ={props?.close} redirectProfile={props?.redirectProfile}/>
                  </div>
                </div>
                {/* USER SIGN UP MODAL */}
                <div
                  className="tab-pane fade fz15"
                  id="navprofile"
                  role="tabpanel"
                  aria-labelledby="navprofiletab"
                >
                  <div className="form-style1">
                    <SignupModal close ={props?.close} redirectProfile={props?.redirectProfile}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
  );
};

export default LoginModal;