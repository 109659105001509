import React from "react";
import { FormLabel } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { Select } from "antd";
import './ant_select.scss'
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
function UserSelect({
  controlId,
  label,
  options,
  disabled,
  placeholder,
  handleChange,
  handleBlur,
  value,
  rightIcon,
  size,
  name,
  className,
  mode,
  ...rest
}) {
  const { i18n } = useTranslation()
  const cookies = new Cookies()

  const currentLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

  return (
    <div className="mb20">
      <FormLabel className="heading-color ff-heading fw600 mb10">
        {label}
      </FormLabel>
      <div className="location-area user-select mt5">
        <Select
          placeholder={placeholder}
          id={name}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          mode={mode}
          value={value}
          className={className}
          disabled={disabled}
          options={options}
          popupClassName={`${currentLanguage === 'ar' ? 'direction-rtl' : ''}`}
        />

        {/* <Select
          placeholder={placeholder}
          id={name}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          className={className}
          disabled={disabled}
        >
          {options?.map((option) => (
            <Option key={option?.value} value={option?.value}>{option?.label}</Option>
          ))}
        </Select> */}
        <ErrorMessage name={name} component="div" className="text-danger" />
      </div>
    </div>
  );
}

export default UserSelect;
