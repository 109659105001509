import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { nameBasedRoutes } from '../../router/public'
import { useTranslation } from 'react-i18next'

import BlogCard from '../../components/blogs/BlogCard'
import { blogsListApi } from '../../services/blogsService'
import { toast } from 'react-toastify'
import { Empty, Skeleton } from 'antd'
import { Pagination } from '@mui/material'
const Blogs = () => {
  const { t } = useTranslation()

  // FOR MANAGING PAGINATION USING URL
  const location = useLocation();

  const params = new URLSearchParams(location?.search)

  const currentPage = params.get("page") ?? 1
  const search = params.get("search") ?? ''

  const [pageSize] = useState(10)
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [blogList, setBlogList] = useState([])
  const [searchValue, setSearchValue] = useState(search ?? '');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);

  //const [currentPage, setCurrentPage] = useState(0)
  const [totalResults, setTotalResults] = useState(0);
  const [sortingData, setSortingData] = useState({});

  // THIS IS FOR PAGE CHANGE
  const handlePageChange = (event, page) => {
    navigate(`${nameBasedRoutes?.blog?.path}?page=${page}${search !== '' ? '&search=' + search : ''}`)
  }
  // GET BLOG DATA LIST FOR TABLE
  const fetchBlogsListing = () => {
    setLoading(true)
    const params = {
      size: pageSize ?? 10,
      search: searchValue ?? '',
    }
    if (currentPage) {
      params.page = currentPage - 1
    }
    if (sortingData && Object.keys(sortingData).length !== 0) {
      params["sort"] = `${sortingData?.field},${sortingData?.sort?.toUpperCase()}`
    }
    blogsListApi(params).then(response => {
      setTotalResults(response?.headers["x-total-count"])
      setBlogList(response?.data)
    }).catch((error) => {
      if (error?.status == 500) {
        toast.error('Unauthorized or server issue. Please refresh or try later. ')
      } else {
        toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  // DEBOUNCE MECHANISM FOR SEARCH OPTIMIZATION
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  // FETCH BLOG LIST DATA
  useEffect(() => {
    fetchBlogsListing()
  }, [currentPage, pageSize, debouncedSearchValue, sortingData])


  return (
    <div className="body_content">
      {/* <LoaderMu loading={loading} /> */}
      <section className="breadcumb-section bgc-white pt30 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">  {t("BLOGS")}</h2>
                <div className="breadcumb-list">
                  <Link to="/">{t("HOME")}</Link>
                  <Link to={nameBasedRoutes?.blog?.path}> {t("BLOGS")}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* BLOGS LIST SECTION AREA */}
      <section className="our-agents bgc-white pt-0">
        <div className="container">
          {/* SEARCH BLOGS */}
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="widget-wrapper">
                <div className="form-style2">
                  <input id="search-input"
                    type="text"
                    className={`form-control`}
                    placeholder={"Search blogs"}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {
              loading === true ?
                [...Array(4)].map((element, index) => {
                  return <div key={index + 1} className="col-md-6 col-lg-4 my-3">
                    <Skeleton.Button active={true} size={"100%"} shape={"square"} block={true} style={{ height: "400px" }} />
                  </div>
                })
                :
                blogList && blogList?.length > 0 ? blogList.map((blog, index) => {
                  return <div key={index + 1} className="col-md-6 col-lg-4 my-3"><BlogCard blogDetails={blog} /></div>
                }) : <Empty description={<span>{t("NO BLOGS AVAILABLE")}</span>} />
            }
          </div>
          {/* PAGINATION */}
          {
            (blogList && blogList?.length > 0) ?
              <div className="row">
                <div className="col-12">
                  <div className="mbp_pagination text-center mt30 dataTable_pagination">
                    <Pagination
                      count={Math.ceil(totalResults / pageSize)}
                      siblingCount={1} page={parseInt(currentPage)}
                      className="d-inline-block" size="large"
                      onChange={(event, value) => handlePageChange(event, value)}
                    />
                  </div>
                </div>
              </div> : ""
          }
        </div>
      </section>
    </div>
  )
}

export default Blogs