
import Cookies from "universal-cookie";
import React, { useState } from "react";
import InputText from "../../components/common/InputText";
import { Singup } from "../../validations/ValidationSchema";
import { getUserDataApi, signUpAPI } from "../../services/userService";
import { Link } from "react-router-dom";
import { Form, Formik, getIn } from "formik";
import { toast } from "react-toastify";
import storage from "../../helpers/storage";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/userProfileSlics";

import { FaEye, FaEyeSlash } from "react-icons/fa";

import GoogleSignupButtonDefault from "../common/googleSignupButtonDefault"
import FacebookLoginButton from "../common/facebookLoginButton";
import LoaderMu from "./LoaderMu";
import { useTranslation } from "react-i18next";
import { nameBasedRoutes } from "../../router/public";


const SignupModal = ({ close, redirectProfile }) => {

  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();

  // STATE FOR PASSWORD VISIBLE TOGGLE
  const [showEye, setShowEye] = useState(false);
  const [showEyeConfirmPass, setShowEyeConfirmPass] = useState(false);

  const { t } = useTranslation()

  // TOGGLE EYE BUTTON FOR PASSWORD
  const showToggle = (event) => {
    setShowEye(event);
  };

  // TOGGLE EYE BUTTON FOR PASSWORD
  const showToggleConfirmPass = (event) => {
    setShowEyeConfirmPass(event);
  };

  // Submit onboarding handleChange
  const handleSubmit = async (value, actions) => {
    submitForm(value);
  };

  // Sign up
  const submitForm = async (value) => {
    setLoading(true)
    const firstName = value.firstName;
    const lastName = value.lastName;
    const email = value.email;
    const password = value.password;

    signUpAPI({
      firstName: firstName.toString(),
      lastName: lastName.toString(),
      email: email.toString(),
      password: password.toString(),
      deviceType: "BROWSER",
    })
      .then((response) => {
        setLoading(false);

        storage.setToken(response?.id_token);
        storage.setData("userType", "USER");
        storage.setData("refresh-token", response?.id_token);

        getUserDataApi()
          .then((userApiResponse) => {
            setLoading(false);
            dispatch(
              setUser({ userData: userApiResponse, token: response?.id_token })
            );
            close()
            if (userApiResponse?.profileComplete === false) {
              cookies.set("showProfileModal", true)
            } else {
              cookies.set("showProfileModal", false)
            }
            // navigate("/");
          })
          .catch((error) => {
            setLoading(false);
            toast.error(
              error?.response?.data?.error_description
                ? error?.response?.data?.error_description
                : error?.response?.data?.message ?? error?.message
            );
          });

        toast.success("Sign-up successful");

      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  };

  //***********Google & facebook Start****************** */
  /**
  * On facebook and google login response
  * @param {any} response
  */
  const onSocialGoogle = (response) => {
    //handleLoginResponse(response);
    toast.success('Social signin successful')
    close()
    // if (redirectProfile) {
    //   navigate("/profile-user");
    // } else {
    //   navigate("/");
    // }
  }


  //*************Google & facebook End*****************/

  return (
    <div className="container d-flex flex-column pageContainer  h-100 overflow-auto">
      <LoaderMu loading={loading} />
      <Formik
        validationSchema={Singup}
        initialValues={{
          i_agree: data.i_agree ? data.i_agree : "",
          firstName: data.firstName ? data.firstName : "",
          lastName: data.lastName ? data.lastName : "",
          email: data.email ? data.email : "",
          password: data.password ? data.password : "",
          confirm_password: data.confirm_password
            ? data.confirm_password
            : "",
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="form-style1" onSubmit={handleSubmit}>
            <div className="d-flex">
              <div className="col-md-6 px-1">
                <InputText
                  controlId="firstName"
                  label={t("FIRST NAME")}
                  value={values.firstName}
                  name="firstName"
                  maxLength="300"
                  errorsField={errors.firstName}
                  touched={touched.firstName}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  className="form-control"
                />
              </div>
              <div className="col-md-6 px-1">
                <InputText
                  controlId="lastName"
                  label={t("LAST NAME")}
                  value={values.lastName}
                  name="lastName"
                  maxLength="300"
                  errorsField={errors.lastName}
                  touched={touched.lastName}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  className="form-control"
                />
              </div>
            </div>
            <div className="d-flex mt20 ">
              <div className="col-12 px-1">
                <InputText
                  controlId="email"
                  label={t("EMAIL ADDRESS")}
                  value={values.email}
                  name="email"
                  maxLength="300"
                  errorsField={errors.email}
                  touched={touched.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-12 col-xl-12 px-1 mt20">

              <div
                className={`position-relative ${showEye ? "form-right-icon" : ""
                  }`}
              >
                <InputText
                  controlId="password"
                  label={t("PASSWORD")}
                  value={values.password}
                  name="password"
                  maxLength="300"
                  type={showEye ? "text" : "password"}
                  errorsField={errors.password}
                  touched={touched.password}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  className="form-control"
                />
                <div onClick={() => setShowEye(!showEye)}>
                  {showEye ? (
                    <FaEye
                      size="22"
                      className="eyePosition"
                      onClick={() => showToggle(true)}
                    />
                  ) : (
                    <FaEyeSlash
                      size="22"
                      className="eyePosition"
                      onClick={() => showToggle(true)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-12 px-1 mt20">
              <div
                className={`position-relative ${showEyeConfirmPass ? "form-right-icon" : ""
                  }`}
              >
                <InputText
                  controlId="confirm_password"
                  label={t("CONFIRM PASSWORD")}
                  value={values.confirm_password}
                  name="confirm_password"
                  maxLength="300"
                  type={showEyeConfirmPass ? "text" : "password"}
                  errorsField={errors.confirm_password}
                  touched={touched.confirm_password}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  className="form-control"
                />
                <div onClick={() => setShowEyeConfirmPass(!showEyeConfirmPass)}>
                  {showEyeConfirmPass ? (
                    <FaEye
                      size="22"
                      className="eyePosition"
                      onClick={() => showToggleConfirmPass(true)}
                    />
                  ) : (
                    <FaEyeSlash
                      size="22"
                      className="eyePosition"
                      onClick={() => showToggleConfirmPass(true)}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="checkbox-style1 px10 mt20">
              <label className="custom_checkbox fz14 ff-heading">
                {t('I AGREE')}
                <Link to={`${nameBasedRoutes?.termsConditions?.path}`} className="fw600 ms-2">
                  {t('TERMS AND CONDITIONS')}
                </Link>
                .
                <input onChange={(e) => { setFieldValue('i_agree', e.target.checked) }} type="checkbox" defaultValue={1} />
                <span className="checkmark" />
              </label>
              <span className='text-danger answer-type-error fs-11'>{getIn(errors, `i_agree`)}</span>
            </div>
            <span className="form-label fz12 px10 fw600 text-danger" />
            <div className="d-grid mb20 mt5">
              <button
                onClick={handleSubmit}
                className="ud-btn btn-thm"
                type="submit"
              >
                {t('CREATE ACCOUNT')}
                <i className="fal fa-arrow-right-long" />
              </button>
            </div>
            <div className="hr_content mb20">
              <hr />
              <span className="hr_top_text">{t('OR')}</span>
            </div>

            <div className="mb-2 row justify-content-center">
            <div className="col-11 mb-2">
                <GoogleSignupButtonDefault
                  onLogin={onSocialGoogle}
                  setLoading={setLoading}
                >
                </GoogleSignupButtonDefault>
              </div>
              <div className="col-11">
                <FacebookLoginButton
                  onLogin={onSocialGoogle}
                  setLoading={setLoading}
                  type="0"
                />
              </div>
            </div>

          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignupModal;
