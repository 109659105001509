import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import 'moment/locale/ar'; // Import Arabic locale
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { propertyLikeStatusApi } from '../../services/propertyService';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Popconfirm } from 'antd';
import LoginModal from '../common/LoginModal';
import { nameBasedRoutes } from '../../router/public';
function HeaderInfo({ propertyData }) {
  // TO CHECK WHEATHER THE USER IS LOGGED IN OR NOT
  const userToken = useSelector((state) => state?.user?.userData)
  const { t } = useTranslation()

  const { i18n } = useTranslation()

  const cookies = new Cookies()
  // 
  const userLanguage = i18n?.language ?? cookies?.get("userLangKey") ?? 'en'

  const [showLogin, setShowLogin] = useState(false);

  const [isLiked, setIsLiked] = useState(propertyData?.isLiked)

  useEffect(() => {
    setIsLiked(propertyData?.isLiked)
  }, [propertyData?.isLiked])

  // DISPLAY TIME IN ARABIC
  moment.locale(userLanguage);
  const handleLikeClick = (isLike) => {
    propertyLikeStatusApi(propertyData?.id, isLike).then((response) => {
      toast.success(response?.message)
      setIsLiked(!isLiked)
    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  const handlePropertyShare = async () => {
    const shareUrl = `${process.env.REACT_APP_WEBSITE_WEB_URL}${nameBasedRoutes?.propertyDetails?.path}/${propertyData?.id}`
    await navigator.clipboard.writeText(shareUrl);
    toast.success(t('URL COPIED TO CLIPBOARD!'))
  }
  return (
    <div className="row wow fadeInUp" data-wow-delay="100ms">
      <div className="col-lg-8">
        <div className="single-property-content mb30-md">
          <h2 className="sp-lg-title">
            {propertyData?.title ?? ''}
          </h2>
          <p className="text fz15 mb-0 bdrr1 pr10 bdrrn-sm">
            {propertyData?.googleGeocodeData?.formattedAddress ?? ''}
          </p>
          <div className="pd-meta mb15 d-md-flex align-items-center">
            <Link
              className="ff-heading text-thm fz15 bdrr1 pr10 ml0-sm  bdrrn-sm"
              to="#"
            >
              <i className="fas fa-circle fz10 pe-2" />
              {propertyData?.propertyOfferingType === "BUY" ? t('FOR BUY') : t('FOR RENT')}
            </Link>
            <span className="ff-heading bdrr1 fz15 pr10 ml10 ml0-sm bdrrn-sm">
              <i className="far fa-clock pe-2" /> {propertyData?.listingDate ?
                moment(propertyData?.listingDate).fromNow() : ''
              }
            </span>
            <Link className="ff-heading ml10 ml0-sm pr10 fz15 bdrr1" to="#">
              <i className="fal fa-hashtag px-1 align-text-top" />
              {
                propertyData?.amakenId ?? ''
              }
            </Link>
            <span className="ff-heading fz15 pr10 ml10 ml0-sm bdrrn-sm">
            {propertyData?.agencyRefNo ?? ""}
            </span>
          </div>
          <div className="property-meta d-flex align-items-center">
            {
              propertyData?.propertyDetail?.noOfBedRooms ?
                <span className="text fz15">
                  <i className="flaticon-bed pe-2 align-text-top" />
                  {
                    propertyData?.propertyDetail?.noOfBedRooms + " " + t("BED")
                  }
                </span> : ""
            }
            {
              propertyData?.propertyDetail?.noOfBathRooms ?
                <span className="text ml20 fz15">
                  <i className="flaticon-shower pe-2 align-text-top" />
                  {
                    propertyData?.propertyDetail?.noOfBathRooms + " " + t("BATH")
                  }
                </span> : ""
            }
            {
              propertyData?.propertyDetail?.areaSqm ?
                <span className="text ml20 fz15">
                  <i className="flaticon-expand pe-2 align-text-top" />
                  {
                    propertyData?.propertyDetail?.areaSqm + " " + t("SQ.M.")
                  }
                </span> : ""
            }
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="single-property-content">
          <div className="property-action text-lg-start text-xl-end">
            <div className="d-flex mb20 mb10-md align-items-center justify-content-lg-end">
              <button className="icon mr10 bg-none" onClick={handlePropertyShare}>
                <span className="flaticon-share-1" />
              </button>
              {
                userToken ? <button className="icon mr10 bg-none" onClick={() => handleLikeClick(!isLiked)}>
                  {isLiked !== true ?
                    <span className="fal fa-heart" /> : <span className="fa-solid fa-heart text-danger" />
                  }
                </button> :
                  <Popconfirm
                    title={t("NOT LOGGED IN")}
                    description={t("PLEASE LOG IN TO MARK THIS AS A FAVORITE.")}
                    onConfirm={() => { setShowLogin(!showLogin) }}
                    okText={t("LOGIN NOW")}
                    okButtonProps={{ className: "bgc-thm" }}
                    cancelText={t("CANCEL")}
                  ><button className="icon mr10 bg-none">
                      <span className="fal fa-heart" />
                    </button></Popconfirm>
              }
            </div>
            {
              propertyData?.propertyOfferingType === "RENT" ?

                <h3 className="price mb-0">{t("JOD")} {propertyData?.priceInJod ? propertyData?.priceInJod + '/yr' : ''}</h3> :
                <h3 className="price mb-0">{t("JOD")} {propertyData?.priceInJod ?? ''}</h3>
            }
            {
              propertyData?.propertyOfferingType === "BUY" && propertyData?.priceInJod && propertyData?.propertyDetail?.areaSqm ?

                <p className="text space fz15">{t("JOD")}
                  {" " +
                    (propertyData.priceInJod / propertyData.propertyDetail.areaSqm).toFixed(2)
                  }
                  /{t("Sq.M.")}
                </p> : ""
            }
          </div>
        </div>
      </div>
      <LoginModal
        show={showLogin}
        close={() => setShowLogin(false)}
      />
    </div>
  )
}

export default HeaderInfo
