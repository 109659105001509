import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getPropertyListing, getPropertyListingAgent } from '../../services/agencyService'
import { toast } from 'react-toastify'
import LoaderMu from '../common/LoaderMu'
import PropertyCard from '../Property/PropertyCard'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import { constructUrl } from '../../services/propertyService'
import { nameBasedRoutes } from '../../router/public'
const PropertyListing = ({ agentId }) => {

	const [loading, setLoading] = useState(true)

	const navigate = useNavigate()

	const { t } = useTranslation()

	const {i18n} = useTranslation()

	const [propertiesList, setPropertiesList] = useState([])

	const [activeFilter, setActiveFilter] = useState("ALL")
	// CALL API TO GET PROPERTY DETAILS
	const fetchPropertiesListing = () => {
		setLoading(true)
		const params = { sort: 'listingDate,desc', page: 0, size: 24 }
		// IF OFFERING TYPE SELECTED
		if (["BUY", "RENT"].includes(activeFilter)) {
			params.propertyOfferingType = activeFilter
		}
		// CALL PROPERTY LISTING API
		getPropertyListingAgent(agentId, params).then((response) => {
			setLoading(false)
			setPropertiesList(response)
		}).catch((error) => {
			setLoading(false)
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}
	// PROPERTY LISTING API CALL
	useEffect(() => {
		if (agentId) {
			fetchPropertiesListing()
		}
	}, [agentId, activeFilter,i18n?.language])

	const handleShowAll = () => {
		// AG-11
		const entityId = `AT-${agentId}`

		const params = []
		if (entityId) {
			params.push({ key: "entityId", value: entityId })
		}
		if (["BUY", "RENT"].includes(activeFilter)) {
			params.push({ key: "propertyOfferingType", value: activeFilter })
		}
		const navigatePath = nameBasedRoutes?.propertyList?.path;
		const navigateURl = constructUrl(navigatePath, params)
		navigate(navigateURl)
	}

	return (
		<div className="row align-items-center mt20">
			<LoaderMu loading={loading} />
			<div className="col-sm-4">
				<h6 className="fz17">{t("LISTING")}</h6>
			</div>
			<div className="col-sm-8">
				<div className="dark-light-navtab style4 mt-0 mt-lg-4 mb30">
					<ul
						className="nav nav-pills justify-content-start justify-content-sm-end"
						id="pills-tab"
						role="tablist"
					>
						<li className="nav-item" >
							<button
								className={`nav-link ${activeFilter === "ALL" ? 'active' : ''}`}
								onClick={() => setActiveFilter("ALL")}
							>
								{t("ALL")}
							</button>
						</li>
						<li className="nav-item">
							<button
								className={`nav-link ${activeFilter === "RENT" ? 'active' : ''}`}
								onClick={() => setActiveFilter("RENT")}
							>
								{t("FOR RENT")}
							</button>
						</li>
						<li className="nav-item">
							<button
								className={`nav-link ${activeFilter === "BUY" ? 'active' : ''}`}
								onClick={() => setActiveFilter("BUY")}
							>
								{t("FOR BUY")}
							</button>
						</li>
					</ul>
				</div>
			</div>
			<div className="col-lg-12">
				{
					propertiesList && propertiesList?.length > 0 ?
						<div className="row">
							{
								propertiesList?.map((property, index) => {
									return <div key={index + 1} className="col-md-6">
										<PropertyCard propertyDetails={property} />
									</div>
								})
							}
							<div className="d-grid pb30 bdrb1">
								<button className="ud-btn btn-white2" onClick={handleShowAll}>
									{t("SHOW ALL PROPERTIES")}
									<i className="fal fa-arrow-right-long" />
								</button>
							</div>
						</div>
						: loading === false ? <Empty description={t("NO PROPERTIES FOUND")} /> : ''
				}
			</div>
		</div>
	)
}

export default PropertyListing
