import React, { useEffect, useState } from 'react'
import { Empty, Select, Skeleton } from 'antd';
import PropertyCard from '../Property/PropertyCard';
import { Pagination } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { constructUrl } from '../../services/propertyService';
import { nameBasedRoutes } from '../../router/public';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
function PropertyList({ propertyGridData, totalRowCount, pageSize, loading }) {

	const location = useLocation()

	const { t } = useTranslation()
	const { i18n } = useTranslation()
  const cookies = new Cookies()

  const currentLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';


	const params = new URLSearchParams(location?.search)
	const navigate = useNavigate()

	const sortParam = params.get("sort")

	const [sortField, setSortField] = useState(sortParam ?? 'listingDate,DESC')

	useEffect(() => {

		if (sortParam && sortParam !== "UNSORTED") {
			const splitFields = sortParam.split(',')
			// Filter out elements containing 'isFeatured'
			const filteredArray = splitFields.filter(item => !item.includes('isFeatured'));

			// Join the remaining elements into a single string and replace ";" with ","
			const outputString = filteredArray.join(',').replace(/: /g, ',');

			setSortField(outputString)
		} else {
			setSortField("listingDate,DESC")
		}

	}, [sortParam])


	const currentPage = params.get("page") ?? 1
	// const [pageSize, setPageSize] = useState(4)

	const pageOffset = (currentPage - 1) * pageSize + 1
	// HANDLE PAGE CHANGE
	const handlePageChange = (event, value) => {
		const redirect_path = nameBasedRoutes?.searchByMap?.path;
		const navigateURl = constructUrl(redirect_path, { key: "page", value })
		navigate(navigateURl, { replace: true })
	}

	// HANDLE SORTING CHANGE
	const handleSortingChange = (value) => {
		const redirect_path = nameBasedRoutes?.searchByMap?.path;
		const navigateURl = constructUrl(redirect_path, { key: "sort", value })
		navigate(navigateURl, { replace: true })
	}

	return (
		<div className="col-xl-5">
			<div className="half_map_area_content mt30">
				<div className="row align-items-center mb10">
					<div className="col-sm-6">
						<div>
							{
								totalRowCount < pageSize ?
									<p className="pagination_page_count mb-0">
										{t('SHOWING')}  {" " + pageOffset}–{totalRowCount} {t("OF")} {totalRowCount} {t("RESULTS")}
									</p> : <p className="pagination_page_count mb-0">
										{t("SHOWING")}
										{" " + pageOffset} – {currentPage * pageSize} {t("OF")} {totalRowCount} {t("RESULTS")}
									</p>
							}
						</div>
					</div>
					<div className="col-sm-6">
						<div className="page_control_shorting d-flex align-items-center justify-content-center justify-content-sm-end">
							<div className="pcs_dropdown pr10 ">
								<span className='me-2'>{t("SORT BY")} : </span>
								<Select
									value={sortField ?? 'listingDate,DESC'}
									options={[
										{ value: "listingDate,DESC", label: t("NEWEST") },
										{ value: "priceInJod,ASC", label: t("PRICE LOW") },
										{ value: "priceInJod,DESC", label: t("PRICE HIGH") }
									]}
									popupClassName={`${currentLanguage === 'ar' ? 'direction-rtl' : ''}`}
									onChange={handleSortingChange}
									className='w-150'
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					{
						(propertyGridData && propertyGridData?.length) ?
							propertyGridData.map((element, index) => {
								return <div key={index + 1} className="col-sm-6">
									<PropertyCard propertyDetails={element} isCompare={true} />
								</div>
							}) : loading === true ? [...Array(4)].map((element, index) => {
								return <div key={index + 1} className="col-sm-6 my-2">
									<Skeleton.Button active={true} size={"100%"} shape={"square"} block={true} style={{ height: "400px" }} />
								</div>
							}) : <Empty description={t("NO PROPERTIES FOUND")} />
					}
				</div>
				{
					(propertyGridData && propertyGridData?.length) ?
						<div className="row">
							<div className="col-12">
								<div className="mbp_pagination text-center mt30 dataTable_pagination">
									<Pagination count={Math.ceil(totalRowCount / pageSize)} siblingCount={1} page={parseInt(currentPage)} className="d-inline-block" size="large" onChange={(event, value) => handlePageChange(event, value)} />
								</div>
							</div>
						</div> : ""
				}
			</div>
		</div>
	)
}

export default PropertyList
