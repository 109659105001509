import { Popconfirm } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { deleteUserAccount } from "../../services/userService";
import storage from "../../helpers/storage";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/userProfileSlics";
import { toast } from "react-toastify";
// import { Link } from "react-router-dom";

function DeleteAccount() {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const handleDeleteAccount = ()=>{
    deleteUserAccount().then(response =>{
      dispatch(logoutUser())
      storage.clearToken()
      localStorage.removeItem('userType');
      localStorage.removeItem('US_token');
      localStorage.removeItem('user-info');
      localStorage.removeItem('refresh-token');
      // navigate("/")
    }).catch((error)=>{
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  return (
    <div className="ps-widget bdr1 bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
      <h4 className="title fz17 mb30">{t('DELETE ACCOUNT')}</h4>
      <form className="form-style1">
        <div className="row">
          <div className=" col-xl-8">
            <div className="mb20">
              <p>
                {t("BEFORE YOU PROCEED WITH ACCOUNT DELETING, PLEASE TAKE A MOMENT TO CONSIDER THAT ALL YOUR DATA, INCLUDING PROFILE INFORMATION, WILL BE PERMANENTLY DELETED AND CANNOT BE RECOVERED. IF YOU CHANGE YOUR MIND LATER, YOU WILL NEED TO CREATE A NEW ACCOUNT FROM SCRATCH.")}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="text-end">

              {/* toast.info("UNDER DEVELOPMENT") */}

              {/* <button type="button" className="ud-btn btn-dark" onClick={()=>{
                toast.info("UNDER DEVELOPMENT") 
              }}>
                {t("DELETE ACCOUNT")}
                <i className="fal fa-arrow-right-long" />
              </button> */}

              <Popconfirm
                title="Delete Account"
                description="Are you sure to delete this account?"
                // onConfirm={handleDeleteAccount}
                // onCancel={cancel}
                onConfirm={handleDeleteAccount}
                okText="Yes"
                cancelText="No"
              >
                <button type="button" className="ud-btn btn-dark" >
                  {t("DELETE ACCOUNT")}
                  <i className="fal fa-arrow-right-long" />
                </button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default DeleteAccount;
