import Cookies from "universal-cookie";

// Function to translate text based on the user's language preference

function translateText(arabicText, englishText) {
const cookies = new Cookies()
const userLanguage = cookies.get('userLangKey') ?? 'en';
    return userLanguage === 'ar' ? arabicText : englishText;
}

export const USER_NAME_REQUIRED = translateText("اسم المستخدم مطلوب.", "Username is required.");
export const EMAIL_REQUIRED = translateText("عنوان البريد الإلكتروني مطلوب.", "Email address is required.");
export const CURRENT_PASSWORD_REQUIRED = translateText("كلمة المرور الحالية مطلوبة.", "Current password is required.");
export const NEW_PASSWORD_REQUIRED = translateText("كلمة المرور الجديدة مطلوبة.", "New password is required.");
export const PASSWORD_REQUIRED = translateText("كلمة المرور مطلوبة.", "Password is required.");
export const CONFIRM_PASSWORD_REQUIRED = translateText("تأكيد كلمة المرور مطلوب.", "Confirm password is required.");
export const CONFIRM_PASSWORD_MATCH = translateText("يجب أن تتطابق كلمة المرور المؤكدة مع كلمة المرور الجديدة.", "Confirm password must match the new password.");
export const MINIMUM_8_CHAR = translateText("يجب أن يكون الحد الأدنى 8 أحرف.", "Must be a minimum of 8 characters.");
export const MAXIMUM_30_CHAR = translateText("لا يمكن أن يزيد عن 14 حرفًا.", " Cannot be more than 14 characters.");
export const MINIMUM_3_CHAR = translateText("يجب أن يكون الحد الأدنى 3 أحرف.", "Must be a minimum of 3 characters.");
export const MAXIMUM_255_CHAR = translateText("لا يمكن أن يزيد عن 255 حرفًا.", "Can not be more than 255 characters.");
export const PASSWORD_REGEX = translateText("يجب أن تكون كلمة المرور بين 8-14 حرفًا، وتشمل حرفًا كبيرًا واحدًا، وحرفًا صغيرًا واحدًا، وحرفًا خاصًا واحدًا، ورقمًا واحدًا.", "Password must be 8-14 characters, including 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number");
export const PASSWORD_MUST_MATCH = translateText("يجب أن تتطابق كلمة المرور المؤكدة مع كلمة المرور.", " Confirm password must match the password.");
export const VALID_EMAIL = translateText("يجب أن يكون البريد الإلكتروني صالحًا.", "Email must be a valid email.");
export const SITE_CODE_REQUIRED = translateText("رمز الموقع مطلوب.", "Site code is required.");
export const SITE_NAME_REQUIRED = translateText("اسم الموقع مطلوب.", "Site name is required.");
export const CONTACT_FIRST_NAME_REQUIRED = translateText("الاسم الأول مطلوب.", "First name is required.");
export const CONTACT_LAST_NAME_REQUIRED = translateText("اسم العائلة مطلوب.", "Last name is required.");
export const PHONE_NO_REQUIRED = translateText("رقم الهاتف مطلوب.", "Phone number is required.");
export const CHAR_MAX_10 = translateText("لا يمكن أن يتجاوز 10 أحرف.", "Cannot exceed 10 characters.");
export const CHAR_MAX_20 = translateText("لا يمكن أن يتجاوز 20 أحرف.", "Cannot exceed 20 characters.");
export const CHAR_MAX_50 = translateText("لا يمكن أن يتجاوز 50 أحرف.", "Cannot exceed 50 characters.");
export const CHAR_MAX_300 = translateText("لا يمكن أن يتجاوز 300 أحرف.", "Cannot exceed 300 characters.");
export const PHONE_NUM_LENGTH = translateText("يجب أن يكون رقم الهاتف مكونًا من 10 أرقام.", "Phone number should have 10 characters.");
export const FIRST_NAME_REQUIRED = translateText("الاسم الأول مطلوب.", "First name is required.");
export const LAST_NAME_REQUIRED = translateText("اسم العائلة مطلوب.", "Last name is required.");
export const MINIMUM_7_DIGIT = translateText("يجب أن يكون الحد الأدنى 7 أرقام.", "Must be a minimum of 7 digits.");
export const MINIMUM_2_DIGIT = translateText("يجب أن يكون الحد الأدنى 2 أرقام.", "Must be a minimum of 2 digits.");
export const MAXIMUM_10_DIGIT = translateText("لا يمكن أن يزيد عن 10 أرقام.", "Cannot be more than 10 digits.");
export const MAXIMUM_15_DIGIT = translateText("لا يمكن أن يزيد عن 15 رقمًا.", "Cannot be more than 15 digits.");
export const PHONE_NUMBER_VALIDATION = translateText("يجب أن يحتوي رقم الهاتف على أرقام فقط.", "Phone number must contain only digits.");
export const LANG_KEY_REQUIRED = translateText("اللغة المفضلة مطلوبة.", "Preferred language is required.");
export const I_AGREE_REQUIRED = translateText("قبول الشروط والأحكام.", "Accept the terms and conditions.");
export const TEXT_MESSAGE_REQUIRED = translateText("الرسالة مطلوبة.", "Message is required.");
export const NO_NEGATIVE_VALUE = translateText("القيمة لا يمكن أن تكون أقل من 0.", "Value cannot be less than 0.");
export const TIME_REQUIRED = translateText("الوقت مطلوب.", "Time is required.");
export const NAME_REQUIRED = translateText("الاسم مطلوب.", "Name is required.");
export const COUNTRY_CODE_REQUIRED = translateText("رمز البلد مطلوب.", "Country code is required.")
export const INVALID_COUNTRY_CODE = translateText("رمز البلد غير مسموح به","Country code not allowed!")
export const COMPLAINT_REASON_REQUIRED = translateText("سبب الشكوى مطلوب.","Complaint reason is required.")
export const OTP_REQUIRED = translateText("الرجاء إدخال رمز التحقق.","Please enter OTP.")
export const COMPARE_ERROR = translateText("لا يمكنك مقارنة أكثر من 4 عقارات!"," You cannot compare more than 4 properties.")

export const AGENCY_NAME_REQUIRED = translateText("اسم الوكالة مطلوب", "Agency name is required");
export const CONTACT_NAME_REQUIRED = translateText("اسم جهة الاتصال مطلوب", "Contact name is required ");
export const AGENCY_ADDRESS_NAME_REQUIRED = translateText("عنوان الوكالة مطلوب", "Agency address is required ");
export const COMMERCIAL_REGISTRATION_REQUIRED = translateText("يشترط التسجيل التجاري", "Commercial registeration is required ");
export const COMPANY_NATIONAL_ID_REQUIRED = translateText("مطلوب الهوية الوطنية للشركة", "Company national-id is required ");
export const AGENT_FIRST_NAME_REQUIRED = translateText("الاسم الأول للوكيل مطلوب", "Agent first name is required ");
export const AGENT_LAST_NAME_REQUIRED = translateText("الاسم الأخير للوكيل مطلوب", "Agent last name is required ");
export const AGENT_EMAIL_REQUIRED = translateText("مطلوب البريد الإلكتروني للوكيل", "Agent email is required ");