import { createSlice } from "@reduxjs/toolkit";
import { generalInfoApi } from "../services/userService";

// An asynchronous function to fetch user data
const fetchInitialData = async () => {
    try {
        const response = await generalInfoApi();
        return response;
    } catch (error) {
        return null;
    }
};

// Fetch the user initial data before creating the slice
const generalInfoData = await fetchInitialData();

const generalInfoSlice = createSlice({
    name: "generalInfo",
    initialState: generalInfoData,
    reducers : {
        setGeneralInfo :(state,action)=>{
            return action?.payload
            // console.log(action?.payload)
            // state = action?.payload?.data
            // console.log({updated : state})
        }
    }
})
export const {setGeneralInfo} = generalInfoSlice?.actions
export default generalInfoSlice?.reducer