import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { getUserDataApi, soicalSignIn, soicalSignUp } from '../../services/userService';
import './facebookLoginButton.scss';
import storage from "../../helpers/storage"
import { useNavigate } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { setUser } from '../../redux/userProfileSlics';

const FacebookLoginButton = ({ setLoading, onLogin, type }) => {

    const {t} = useTranslation()

    const SCRIPT_ID = 'fb-script';
    const ENABLE_FACEBOOK = true
    const [isMounted, setIsMounted] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const cookies = new Cookies()

    const { i18n } = useTranslation()

    /**
     * Hook on mount and unmount app.
     */
    useEffect(() => {
        setIsMounted(true);
        return () => {
            if (isMounted) {
                removeScript();
            }
        };

    }, [isMounted]);

    useEffect(() => {
        if (ENABLE_FACEBOOK) {
            const script = document.getElementById(SCRIPT_ID);
            if (!script) {
                loadScript();
            }
        }
    }, [ENABLE_FACEBOOK])

    /**
     * Load facebook login script.
     */
    const loadScript = () => {
        const script = document.createElement('script')
        script.src = `https://connect.facebook.net/en_US/sdk.js`
        script.id = SCRIPT_ID;
        script.async = true;
        script.onload = () => onLoad();
        document.body.appendChild(script);
    }
    /**
     * on Load facebook initialise the facebook instance.
     */
    const onLoad = () => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: process.env.REACT_APP_FACEBOOK_APP_ID,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v17.0'
                // version:'v20.0'
            });
        }
        setIsLoaded(true);
    }

    /**
     * Remove script when component is unmounted
     */
    const removeScript = () => {
        const script = document.getElementById(SCRIPT_ID);
        if (script) {
            script.remove();
        }
    }

    /**
     * Handles successful Facebook login.
     * 
     * @function
     * @name onSuccess
     * @param {FacebookResponse} response - The response from Facebook login.
     * @returns {void}
     */
    const onSuccess = (fbResponse) => {
        setLoading(true);
        // Calling social sign-in api
        soicalSignIn({ ...fbResponse }).then((response) => {
            if (response.id_token) {
                storage.setToken(response?.id_token)
                storage.setData("userType", "USER");
                storage.setData("refresh-token", response?.id_token);
                getUserDataApi()
                    .then((userApiResponse) => {
                        setLoading(false);

                        dispatch(
                            setUser({ userData: userApiResponse, token: response?.id_token })
                        );
                        // SET USER LANGUAGE KEY

                        cookies.remove("userLangKey")
                        cookies.set("userLangKey", userApiResponse?.langKey)
                        i18n.changeLanguage(userApiResponse?.langKey)

                        if (userApiResponse?.profileComplete === false) {
                            cookies.set("showProfileModal", true)
                        } else {
                            cookies.set("showProfileModal", false)
                        }

                        onLogin(response);
                        // navigate("/");



                    })
                    .catch((error) => {
                        setLoading(false);
                        toast.error(
                            error?.response?.data?.error_description
                                ? error?.response?.data?.error_description
                                : error?.response?.data?.message
                        );
                    });
                //onLogin(response);
            }
            else {
                // Calling social sign-up api
                soicalSignUp({ ...fbResponse }).then((response) => {

                    storage.setToken(response?.id_token)
                    storage.setData("userType", "USER");
                    storage.setData("refresh-token", response?.id_token);
                    getUserDataApi()
                        .then((userApiResponse) => {
                            setLoading(false);

                            dispatch(
                                setUser({ userData: userApiResponse, token: response?.id_token })
                            );
                            // SET USER LANGUAGE KEY

                            cookies.remove("userLangKey")
                            cookies.set("userLangKey", userApiResponse?.langKey)
                            i18n.changeLanguage(userApiResponse?.langKey)

                            if (userApiResponse?.profileComplete === false) {
                                cookies.set("showProfileModal", true)
                            } else {
                                cookies.set("showProfileModal", false)
                            }

                            onLogin(response);
                            // navigate("/");
                        })
                        .catch((error) => {
                            setLoading(false);
                            toast.error(
                                error?.response?.data?.error_description
                                    ? error?.response?.data?.error_description
                                    : error?.response?.data?.message
                            );
                        });
                    //onLogin(response);
                }, (error) => {
                    setLoading(false);
                    toast(error.data.msg, { type: toast.TYPE.ERROR });
                });
            }


        }, (error) => {
            // Calling social sign-up api
            soicalSignUp({ ...fbResponse }).then((response) => {
                storage.setToken(response?.id_token)
                storage.setData("userType", "USER");
                storage.setData("refresh-token", response?.id_token);
                getUserDataApi()
                    .then((userApiResponse) => {
                        setLoading(false);

                        dispatch(
                            setUser({ userData: userApiResponse, token: response?.id_token })
                        );
                        // SET USER LANGUAGE KEY

                        cookies.remove("userLangKey")
                        cookies.set("userLangKey", userApiResponse?.langKey)
                        i18n.changeLanguage(userApiResponse?.langKey)

                        if (userApiResponse?.profileComplete === false) {
                            cookies.set("showProfileModal", true)
                        } else {
                            cookies.set("showProfileModal", false)
                        }

                        onLogin(response);
                        // navigate("/");
                    })
                    .catch((error) => {
                        setLoading(false);
                        toast.error(
                            error?.response?.data?.error_description
                                ? error?.response?.data?.error_description
                                : error?.response?.data?.message
                        );
                    });
                //onLogin(response);
            }, (error) => {
                setLoading(false);
                toast(error.data.msg, { type: toast.TYPE.ERROR });
            });
        });
    }

    /**
     * Handles errors that occur during Facebook login.
     * 
     * @function
     * @name onError
     * @param {FacebookResponse} response - The response from Facebook login.
     * @returns {void}
     */
    const onError = ({ status }) => {
        setLoading(false);
    }

    /**
     * Executes Facebook login process.
     * 
     * @function
     * @name onFacebookLogin
     * @returns {void}
     */
    const onFacebookLogin = () => {
        window.FB.login((response) => {
            if (response.authResponse) {
                // Geting data
                window.FB.api('/me?fields=name,email,picture,first_name,last_name', (user) => {

                    const fbResponse = {};

                    fbResponse.socialUserId = user.id;
                    fbResponse.firstName = user.first_name;
                    fbResponse.lastName = user.last_name;
                    fbResponse.email = user.email;
                    fbResponse.socialUserName = user.name;
                    fbResponse.photoUrl = `https =//graph.facebook.com/1.1/${user.id}/picture?type=normal`;
                    fbResponse.facebook = user;
                    fbResponse.accessToken = response.authResponse.accessToken;

                    fbResponse.socialNetwork = 'FACEBOOK'
                    fbResponse.deviceType = 'BROWSER'
                    fbResponse.signup = true


                    onSuccess(fbResponse)
                })
                // calling below function on scuess 
                //onSuccess(response)
            } else {
                onError(response);
            }
        }, { scope: 'email', return_scopes: true, enable_profile_selector: true });
    }

    return (

        <button className="ud-btn btn-fb facebookButtonPadding" type="button" onClick={onFacebookLogin}>
            {/* <i className="fab fa-facebook-f" /> With Facebook */}
            <FaFacebookF /> {t("WITH FACEBOOK")}
        </button>

    );
}


/**
 * Defining propTypes
 */
FacebookLoginButton.propTypes = {
    setLoading: PropTypes.func.isRequired,
    onLogin: PropTypes.func.isRequired,
    type: PropTypes.string
}

export default FacebookLoginButton
