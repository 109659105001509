import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { blogsDetailsApi } from '../../services/blogsService'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'
import moment from "moment"
import { Footer } from '../../components/layouts/Footer/Footer'
import LoaderMu from '../../components/common/LoaderMu'
import SeoComponent from '../../components/seo/SeoComponent'
import { nameBasedRoutes } from '../../router/public'


const BlogDetails = () => {

  const { i18n } = useTranslation()
  const { t } = useTranslation()
  const cookies = new Cookies()
  const userLanguage = i18n?.language ?? cookies?.get("userLangKey") ?? 'en'
  // DISPLAY TIME IN ARABIC
  moment.locale(userLanguage);

  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [blogDetails, setBlogDetails] = useState({})
  const [blogTags, setBlogTags] = useState([])

  // FETCH BLOG DETAILS FROM API
  const fetchBlogDetails = () => {
    blogsDetailsApi(id).then((response) => {
      setBlogDetails(response)
      const tags = (response?.tags).split(",")
      setBlogTags(tags)
    }).catch((error) => {
      if (error?.status == 500) {
        toast.error('Unauthorized or server issue. Please refresh or try later. ')
      } else {
        toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchBlogDetails()
  }, [id])

  return (
    <div className="body_content">
      {
        loading === true ?
          <LoaderMu loading={true} /> :
          <div>
            <SeoComponent
              title={blogDetails?.metaTitle}
              meta_title={blogDetails?.metaTitle}
              description={blogDetails?.metaDescription}
              keywords={blogDetails?.metaKeywords}
              canonical={`${process.env.REACT_APP_WEBSITE_WEB_URL}${nameBasedRoutes?.blogDetails?.path}/${id}/${blogDetails?.slug}`}
            />
            {/* BLOG DETAILS PAGE SECTION */}
            <section className="bgc-white pt30">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <h1>{blogDetails?.title}</h1>
                    <p>
                      <span className='fw500 fs-16 ms-2'>{blogDetails.createdAt ? moment(blogDetails?.createdAt).format('MMMM DD, YYYY') : ""}</span>
                      <span className='circle-space'></span>
                      <span className='fw500 text-muted'>{t("WRITTEN BY")}</span>
                      <span className='theme-text fw-bold ms-2'>{blogDetails?.author}</span>
                    </p>
                    {/* BLOG TAGS */}
                    <div className='d-flex align-items-center mb20'>
                      {
                        blogTags && blogTags?.length > 0 ? blogTags?.map((tag, index) => (<span className='blog-tags me-2 text-capitalize fw500' key={index + 1}>{tag}</span>)) : ""
                      }
                    </div>

                    {
                      blogDetails?.image ?
                        <div className='blog-img my-2'>
                          <img src={blogDetails?.image} className='img-fluid bdrs12' alt="blogImage" />
                        </div> : ""
                    }
                    {
                      blogDetails?.content && <div className="sun-editor sun-editor-editable text-justify" dangerouslySetInnerHTML={{ __html: blogDetails?.content }}></div>
                    }
                    <img />
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
      }
    </div>
  )
}

export default BlogDetails