
import logoImg2 from "../../../assets/images/Amaken-logo2.svg";
import forgotBg from "../../../assets/images/icon/login-page-icon.svg";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getEmailVerificationAPI } from "../../../services/userService";
import { toast } from "react-toastify";
import LoaderMu from "../../../components/common/LoaderMu";
import { nameBasedRoutes } from "../../../router/public";
import SeoComponent from "../../../components/seo/SeoComponent";

export const EmailVerify = () => {
  const [loading, setLoading] = useState();
  const location = useLocation();
  const [received, setReceived] = useState("");

  const params = new URLSearchParams(location.search);
  const verificationKey = params.get("key");

  // CALL EMAIL VERIFICATION API
  const fetchData = (params) => {
    getEmailVerificationAPI({ ...params })
      .then((response) => {
        setReceived(response.message
          ? response.message
          : '')
        setLoading(false);
        toast.success(response.message);
        //navigate("/forgot-password");
      })
      .catch((error) => {
        setReceived(error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message)
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  };

  // Get onboarding
  useEffect(() => {
    setLoading(true);
    fetchData({
      key: verificationKey,
    });
  }, []);

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <LoaderMu loading={loading} />
      <SeoComponent
        isDefault={true}
      />
      <div className="wrapper ovh">
        <div className="body_content">
          {/* Our Compare Area */}
          <section className="our-compare pt60 pb60">
            <img
              className="login-bg-icon wow fadeInLeft"
              src={forgotBg}
              alt="Forgot bg"
            />
            <div className="container">
              <div
                className="row wow fadeInRight"
                data-wow-delay="300ms"
              >
                <div className="col-lg-6">
                  <div className="log-reg-form signup-modal form-style1 bgc-white p50 p30-sm default-box-shadow2 bdrs12">
                    <div className="header-logo text-center mb40">
                      <Link to={nameBasedRoutes?.home?.path}>
                        <img
                          className="mb25"
                          src={logoImg2}
                          alt="forgot logo"
                        />
                      </Link>
                      <h2>Email Verification</h2>
                      <p className="text">
                        {received}
                      </p>
                    </div>
                    <div className="d-grid mb20">
                      <Link
                        className="ud-btn btn-white"
                        type="button"
                        to={nameBasedRoutes?.home?.path}
                      >
                        Back <i className="fal fa-arrow-left-long" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </div>

  );
};
