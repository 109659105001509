import React, { useEffect, useState } from 'react'
import {  Button,  Collapse } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';


const AgentAbout = ({agentInfo}) => {
  
  const cookies = new Cookies()
  const {t} = useTranslation()
  const {i18n} = useTranslation()
  const [agencyAboutBrif, setAgencyAboutBrief] = useState(t('NA'))
  const [open, setOpen] = useState(false) //SHOW MORE COLLAPSE STATE
  
  const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

  // AGENCY ABOUT IN BRIEF
    const fetchAboutBrief = async () => {
        try {
          const brief = await getAboutBrief(userLanguage);
            setAgencyAboutBrief(brief);
        } catch (error) {
            console.error('Error fetching about brief:', error);
        }
    };

    const getAboutBrief = (language) => {
        return new Promise((resolve, reject) => {
            if (language === 'en') {
                resolve(agentInfo?.about?.en || t('NA'));
            } else {
                resolve(agentInfo?.about?.ar || t('NA'));
            }
        });
    };

  
  useEffect(() => {
      fetchAboutBrief();
  }, []);

  // SPLIT PROPERTY DESCRIPTION INTO TWO PARTS
  const [firstThreeLines, remainingLines] = agencyAboutBrif ? splitParagraph(agencyAboutBrif) : '';

  return (
    <div className="agent-single-details mt30 pb30 bdrb1">
      <h6 className="fz17 mb30">{t('ABOUT')} {agentInfo?.agencyDTO?.name ?? t('NA')} {t('AGENT')}</h6>
      <p className="text">
         {firstThreeLines ?? ''}
      </p>
      
      <div className="agent-single-accordion">

        {
           remainingLines ?
            <div className="agent-single-accordion">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div >
                  <Collapse in={open} dimension="height">
                    <div id="flush-collapseOne">
                      <p className="text text-justify">
                        {remainingLines ?? ''}
                      </p>
                    </div>
                  </Collapse>
                </div>
                <button
                  onClick={() => setOpen(!open)}
                  aria-controls="flush-collapseOne"
                  aria-expanded={open}
                  className="accordion-button  p-0 collapsed"
                >
                  {
                    open ? t("SHOW LESS") : t('SHOW MORE')
                  }
                </button>
              </div>
            </div> : ""
        }
      </div>
    </div>
  )
}

export default AgentAbout

// SPLIT AGENCY INFO PARAGRAPH

function splitParagraph(paragraph) {

  const lines = paragraph?.split('. ');
  const firstThreeLines = lines.slice(0, 3).join('. ') + '.';
  const remainingLines = lines.slice(3).join('. ');
  return [firstThreeLines, remainingLines];
}
