import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'

function PropertyOverview({ propertyData }) {

  const cookies = new Cookies()

  const { i18n } = useTranslation()

  const {t} = useTranslation()

  const userLanguage = i18n?.language ?? cookies?.get("userLangKey") ?? 'en'

  const [visibleDetails, setVisibleDetails] = useState([])

  useEffect(() => {
    if (propertyData?.propertyType?.propertyDetail) {
      const visibleData = propertyData?.propertyType?.propertyDetail.filter((element) => element?.visible === true).map((element, index) => {
        return { field_name: element?.field_name, label: element?.field_level[userLanguage] }
      })
      setVisibleDetails(visibleData)
    }
  }, [propertyData])

  return (
    <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden    position-relative">
      <h4 className="title fz17 mb30">{t("OVERVIEW")}</h4>
      <div className="row">
        {visibleDetails?.map((element) => (element?.field_name)).includes('noOfBedRooms') &&
          propertyData?.propertyDetail?.noOfBedRooms ?
          <div className="col-sm-6 col-lg-4"> <div className="overview-element mb25 d-flex align-items-center">
            <span className="icon flaticon-bed" />
            <div className="ml15">
              <h6 className="mb-0">{t("BEDROOM")}</h6>
              <p className="text mb-0 fz15">{propertyData?.propertyDetail?.noOfBedRooms ?? ''}</p>
            </div>
          </div>
          </div> : ""
        }

        {visibleDetails?.map((element) => (element?.field_name)).includes('noOfBathRooms') &&
          propertyData?.propertyDetail?.noOfBathRooms ? <div className="col-sm-6 col-lg-4">
          <div className="overview-element mb25 d-flex align-items-center">
            <span className="icon flaticon-shower" />
            <div className="ml15">
              <h6 className="mb-0">{t("BATH")}</h6>
              <p className="text mb-0 fz15">{propertyData?.propertyDetail?.noOfBathRooms ?? ''}</p>
            </div>
          </div>
        </div> : ""
        }

        {visibleDetails?.map((element) => (element?.field_name)).includes('furnished') &&
          propertyData?.propertyDetail?.furnished ?
          <div className="col-sm-6 col-lg-4">
            <div className="overview-element mb25 d-flex align-items-center">
              <span className="icon flaticon-bed" />
              <div className="ml15">
                <h6 className="mb-0">{t("FURNISHED")}</h6>
                {
                  propertyData?.propertyDetail?.furnished === true ?
                    <p className="text mb-0 fz15 text-success"> {t("YES")}</p> :
                    <p className="text mb-0 fz15 text-danger"> {t("NO")}</p>
                }
              </div>
            </div>
          </div> : ""}
        {visibleDetails?.map((element) => (element?.field_name)).includes('areaSqm') &&
          propertyData?.propertyDetail?.areaSqm ?
            <div className="col-sm-6 col-lg-4">
              <div className="overview-element mb25-xs d-flex align-items-center">
                <span className="icon flaticon-expand" />
                <div className="ml15">
                  <h6 className="mb-0">{t("Sq.M.")}</h6>
                  <p className="text mb-0 fz15">{propertyData?.propertyDetail?.areaSqm ?? ''}</p>
                </div>
              </div>
            </div> : ""
        }
        <div className="col-sm-6 col-lg-4">
          <div className="overview-element d-flex align-items-center">
            <span className={`icon ${propertyData?.propertyType?.iconClassName ?? 'flaticon-home-1'}`} />
            <div className="ml15">
              <h6 className="mb-0">{t("PROPERTY TYPE")}</h6>
              <p className="text mb-0 fz15 text-capitalize">{propertyData?.propertyType?.propertyType?? ''}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyOverview
