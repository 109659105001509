import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/ace-responsive-menu.css";
import "./assets/css/menu.css";
import "./assets/css/fontawesome.css";
import "./assets/css/flaticon.css";
import "./assets/css/bootstrap-select.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/animate.css";
import "./assets/css/slider.css"; 
import "./assets/css/ud-custom-spacing.css";
// import "./assets/rtl/css/ud-custom-spacing.css";
import "./assets/css/style.css";
import "react-select2-wrapper/css/select2.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';



import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material"
import Loader from "./components/common/Loader"
import { BrowserRouter as Router } from "react-router-dom"

import { Provider } from "react-redux";
import store from "./redux/store";

import { I18nextProvider } from "react-i18next"
import i18nConfig from "./language/i18"



const root = ReactDOM.createRoot(document.getElementById("root"));
const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: '#17B0B2',
    },
    text: {
      primary: '#000000',
      secondary: '#777777',
    }
  }
});


const baseUrl = process.env.REACT_APP_BASE_URL

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <I18nextProvider i18n={i18nConfig}>
        <React.Suspense fallback={<Loader isLoading={true} />}>
          <ThemeProvider theme={customTheme}>
            <Router basename={baseUrl}>
              <App />
            </Router>
          </ThemeProvider>
        </React.Suspense>
      </I18nextProvider>
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
