import React, { useEffect, useState } from "react";

import { Image } from "antd";
import { useTranslation } from "react-i18next";
function PropertyImageSection({ propertyImages }) {

  const {t} = useTranslation()

  const [previewVisible, setPreviewVisible] = useState(false)

  const [mainImage, setMainImage] = useState({})

  const [previewItems, setPreviewItems] = useState([])
  const [currentPreview, setCurrentPreview] = useState(0)

  const handleButtonClick = () => {
    setCurrentPreview(0)
    setPreviewVisible(true)
  };

  useEffect(() => {
    // FILTER MAIN IMAGE AND SET IN STATE
    if (propertyImages && propertyImages?.length > 0) {
      const mainImage = propertyImages.find((image) => image?.mainImage === true)
      setMainImage(mainImage)
      const previewItems = propertyImages.filter((image) => image?.mainImage === false).map((image) => image?.imageName)
      setPreviewItems([mainImage?.imageName, ...previewItems])
    }
  }, [propertyImages])

  return (
    <div className="row mb30 mt30 wow fadeInUp" data-wow-delay="300ms">
      <div className="col-sm-6">
        <div className="sp-img-content mb15-md">
          {/* MAIN IMAGE */}
          <div
            className="popup-img preview-img-1 sp-img"
          >
            <Image.PreviewGroup items={previewItems} mask={false} preview={
              {
                visible: previewVisible, current: currentPreview,
                onChange: (e) => { setCurrentPreview(e); },
                onVisibleChange: (state) => {
                  setCurrentPreview(0)
                  setPreviewVisible(state)
                }
              }
            } >
              <Image
                src={mainImage?.imageName}
                className="w-100"
                alt="Properties For You"
                preview={
                  { src: mainImage?.imageName, mask: false }
                }
              />
            </Image.PreviewGroup>

          </div>
        </div>
      </div>
      {/* ALL REMAINING IMAGES */}

      <div className="col-sm-6">
        <div className="row">
          {
            propertyImages && propertyImages?.length > 0 ?

              propertyImages.filter((image) => image?.mainImage === false).slice(0, 4).map((image, index) => (
                <div key={image?.id} className="col-6 ps-sm-0">
                  <div className="sp-img-content">

                    <div
                      className="popup-img preview-img-2 sp-img mb10"
                      href="images/listings/listing-single-2.jpg"
                    >
                    {
                      index === 3 ?  <button
                        // href="images/listings/listing-single-6.jpg"
                        className="all-tag popup-img border-none z-3"
                        onClick={handleButtonClick}
                      >
                        {t("SEE ALL")}
                      </button> : ''
                    }
                     
                      <Image.PreviewGroup items={previewItems}
                        preview={
                          {
                            current: currentPreview,
                            onChange: (e) => { setCurrentPreview(e) },
                            onVisibleChange: () => setCurrentPreview(index + 1)
                          }
                        }
                      >
                        <Image
                          src={image?.thumbnailImageName}
                          className="w-100"
                          alt="Properties For You"
                          preview={{ src: image?.imageName, mask: false }}
                        />
                      </Image.PreviewGroup>
                    </div>
                  </div>
                </div>
              )) : ""}


        </div>

      </div>

    </div>
  );
}

export default PropertyImageSection;
