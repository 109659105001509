import { Modal } from 'antd';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { MdOutlineFullscreen } from "react-icons/md";
function Tour3d({ virtualTourUrl }) {

  const { t } = useTranslation()

  const [tourModalOpen, setTourModalOpen] = useState(false)

  return (
    <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative ">
      <div className='d-flex justify-content-between align-items-center mb30'>
        <h4 className="title fz17">
          <i className="fal fa-cube mr10" />
          {t('3D TOUR')}
        </h4>
        <button onClick={() => { setTourModalOpen(true) }} className='btn btn-sm btn-outline-dark'>
          <MdOutlineFullscreen size={24} className='mb-1' />
          Full Screen</button>
      </div>

      <div className="row">
        <div className="col-md-12">

        </div>
        <div className="col-md-12">
          <iframe src={virtualTourUrl} height="400px" className='w-100 bdrs12' />
        </div>
      </div>
      <Modal
        type="confirm"
        title=" "
        open={tourModalOpen}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setTourModalOpen(false)
        }}
        style={{ top: 20 }}
        className='videoModal'
        width={"100%"}

      >
        <div>
          {
            virtualTourUrl ? <iframe src={virtualTourUrl} className='w-100 bdrs12  h-80vh' /> : ""
          }

        </div>

      </Modal>
    </div>
  )
}

export default Tour3d
