import React, { useEffect, useState } from 'react'
import AdvanceSearchModal from '../modal/AdvanceSearchModal'
import { Dropdown } from 'react-bootstrap'
import { Checkbox, Radio, Space } from 'antd'
import RangeSlider from '../common/RangeSlider'
import { propertyInfoApi, propertyTypeListApi } from '../../services/advanceSearch'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { constructUrl, createSearchPayload, removeSearchParams } from '../../services/propertyService'
import { useDispatch} from 'react-redux'
import Cookies from 'universal-cookie'

const QuickFilter = ({ navigatePath }) => {

	const location = useLocation()

	const params = new URLSearchParams(location?.search)


	// GET ALL SEARCH PARAMS
	const paramsObj = Object.fromEntries(new URLSearchParams(params));
	// CREATE SEARCH PAYLOAD HERE
	const searchData = createSearchPayload(paramsObj)
	// const navigatePath = nameBasedRoutes?.propertyList?.path;

	const { t } = useTranslation()

	const { i18n } = useTranslation()
  const cookies = new Cookies()

  const currentLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

	const [loading, setLoading] = useState(false)

	const navigate = useNavigate()

	const [advanceMenuOpen, setAdvanceMenuOpen] = useState(false)

	const [propertyInfoData, setPropertyInfoData] = useState({})

	const [propertyTypeList, setPropertyTypeList] = useState([])

	// STATES FOR DATA FILTER

	const [propertyStatus, setPropertyStatus] = useState(searchData?.propertyOfferingType ?? null)
	const [propertyType, setPropertyType] = useState(searchData?.propertyTypeIdList
		?? [])

	const [priceRangeVal, setPriceRangeVal] = useState([50000, 1000000])
	const [bed, setBed] = useState(searchData?.bedroom ?? 'any')
	const [bath, setBath] = useState(searchData?.bathroom ?? 'any')

	useEffect(() => {
		setPropertyStatus(searchData?.propertyOfferingType ?? null)
		setPropertyType(searchData?.propertyTypeIdList ?? [])
		setBed(searchData?.bedroom ?? 'any')
		setBath(searchData?.bathroom ?? 'any')
		setPriceRangeVal([searchData?.minPrice ? parseInt(searchData?.minPrice) : propertyInfoData?.minPrice ?? 1, searchData?.maxPrice ? parseInt(searchData?.maxPrice) : propertyInfoData?.maxPrice ?? 1000000])

	}, [location?.search])

	// FETCH PROPERTY INFO DATA
	const fetchPropertyInfoData = () => {
		propertyInfoApi().then((response) => {
			setPropertyInfoData(response)
			const propCategoryArr = Object.keys(response?.propertyCategory).map((element) => ({ value: element, label: response?.propertyCategory[element] }))
			setPriceRangeVal([searchData?.minPrice ?? response?.minPrice, searchData?.maxPrice ?? response?.maxPrice])
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message ?? error?.message)
		})
	}

	// FETCH PROPERTY LIST DATA	
	const fetchPropertyListData = () => {
		propertyTypeListApi().then((response) => {
			setPropertyTypeList(response)
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message ?? error?.message)
		})
	}


	useEffect(() => {
		fetchPropertyInfoData()
		fetchPropertyListData()
	}, [])

	// HANDLE STATUS_FILTER

	const handleStatusFilter = () => {
		let navigateURl;
		if (propertyStatus) {
			const param = { key: "propertyOfferingType", value: propertyStatus }
			navigateURl = constructUrl(navigatePath, param)
		} else {
			navigateURl = removeSearchParams(navigatePath, "propertyOfferingType")
		}
		navigate(navigateURl)
	}

	// HANDLE TYPE_FILTER
	const handleTypeFilter = () => {

		let navigateURl;
		if (propertyType && propertyType?.length > 0) {
			const typeString = propertyType.join(',')
			const param = { key: "propertyTypeIdList", value: typeString }
			navigateURl = constructUrl(navigatePath, param)
		} else {
			navigateURl = removeSearchParams(navigatePath, "propertyTypeIdList")
		}
		navigate(navigateURl)
	}
	// HANDLE PRICE_FILTER
	const handlePriceFilter = () => {
		if (priceRangeVal && priceRangeVal?.length > 1) {
			const param = [{ key: "minPrice", value: priceRangeVal[0] }, { key: "maxPrice", value: priceRangeVal[[1]] }]
			const navigateURl = constructUrl(navigatePath, param)
			navigate(navigateURl)
		} else {
			toast.warning('Select Price range!')
		}
	}

	// HANDLE BED AND BATH FILTER
	const handleBedAndBath = () => {

		const params = [];
		const removeParams = []

		// CHECK FOR BEDROOM FILTERS
		if (bed && bed !== '' && bed !== 'any') {
			params.push({ key: 'bedroom', value: bed });
		} else {
			removeParams.push('bedroom');
		}

		// CHECK FOR BATHROOM FILTERS
		if (bath && bath !== '' && bath !== 'any') {
			params.push({ key: 'bathroom', value: bath });
		} else {
			removeParams.push('bathroom');
		}
		// CONSTRUCT URL IF NEW FILTER APPLY
		if (params?.length > 0) {
			const navigateURl = constructUrl(navigatePath, params)
			navigate(navigateURl)
		}
		// REMOVE FILTER IF CLEARED BY USER
		if (removeParams?.length > 0) {
			const navigateURl = removeSearchParams(navigatePath, removeParams)
			navigate(navigateURl)
		}
	}

	return (
		<div className="dropdown-lists d-md-flex align-items-start justify-content-between">
			<ul className="p-0 mb-0">
				{/* STATUS */}
				<li className="list-inline-item position-relative">
					<Dropdown>
						<Dropdown.Toggle variant='none' className={`open-btn dropdown-toggle ${searchData?.propertyOfferingType && 'active'}`} id="dropdown-basic">
							{t("STATUS")} <i className="fa fa-angle-down ms-2" />
						</Dropdown.Toggle>
						<Dropdown.Menu className={`${currentLanguage === "ar" ? 'text-right' : ''}`}>
							<div className="widget-wrapper bdrb1 pb25 mb0 pl20">
								<h6 className="list-title">{t("PROPERTY STATUS")}</h6>
								<div className="radio-element">
									<div className="form-check p-0 mb10 antdRadiocircle">
										<Radio.Group
											id={'propertyStatus'}
											name={'propertyStatus'}
											onChange={(event) => setPropertyStatus(event?.target?.value)}
											value={propertyStatus}
										>
											<Space direction="vertical">
												{
													propertyInfoData && propertyInfoData?.propertyOfferingType && Object.keys(propertyInfoData?.propertyOfferingType).map((key, index) => (
														<Radio key={index + 1} value={key}>{propertyInfoData?.propertyOfferingType[key]}</Radio>
													))
												}
											</Space>
										</Radio.Group>
									</div>
								</div>
							</div>
							{/* BUTTON FOR FILTER ON STATUS */}
							<Dropdown.Item className="text-end mt10 pr10 quickFilter-dropdownBtn">
								<button
									as="button"
									className="done-btn ud-btn btn-thm drop_btn"
									onClick={handleStatusFilter}
								>
									{t("DONE")}
								</button>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</li>
				{/* PROPERTY TYPE*/}
				<li className="list-inline-item position-relative">
					<Dropdown>
						<Dropdown.Toggle variant='none'
							className={`open-btn dropdown-toggle ${searchData?.propertyTypeIdList && 'active'}`}>
							{t("PROPERTY TYPE")} <i className="fa fa-angle-down ms-2" />
						</Dropdown.Toggle>
						<Dropdown.Menu className={`${currentLanguage === "ar" ? 'text-right' : ''}`}>
							<div className="widget-wrapper propTypeFilter bdrb1 pb25 mb0 pl20">
								<h6 className="list-title">{t("PROPERTY TYPE")}</h6>
								<Checkbox.Group
									name="property"
									id="property"
									onChange={(value) => {
										setPropertyType(value);
									}}
									className="w-100"
									value={propertyType ?? []}
								>
									<div className="checkbox-style1">
										{
											(propertyTypeList && propertyTypeList?.length) && propertyTypeList.map((type, index) => (
												<Checkbox key={index + 1} value={type?.id} className='mb-2'>
													{type?.propertyType}
												</Checkbox>
											))
										}
									</div>
								</Checkbox.Group>
							</div>
							{/* FILTER ON PROPERTY CATEGORY */}
							<Dropdown.Item className="text-end mt10 pr10 quickFilter-dropdownBtn">
								<button
									type="button"
									className="done-btn ud-btn btn-thm drop_btn2"
									onClick={handleTypeFilter}
								>
									{t("DONE")}
								</button>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</li>
				{/* PRICE RANGE SLIDER DROPDOWN */}
				<li className="list-inline-item position-relative">
					<Dropdown >
						<Dropdown.Toggle variant='none'
							className={`open-btn dropdown-toggle ${(searchData?.minPrice || searchData?.maxPrice) && 'active'}`}>
							{t("PRICE")} <i className="fa fa-angle-down ms-2" />
						</Dropdown.Toggle>
						<Dropdown.Menu className={`${currentLanguage === "ar" ? 'text-right' : ''} w-400 `}>
							<div className="widget-wrapper bdrb1 pb25 mb0 pl20 pr20">
								<h6 className="list-title">{t("PRICE RANGE")}</h6>
								{/* Range Slider Desktop Version */}
								<div className="">
									<div className="range-wrapper">
										<RangeSlider
											handleChange={(event) => setPriceRangeVal(event.target.value)}
											value={priceRangeVal}
											min={propertyInfoData?.minPrice}
											max={propertyInfoData?.maxPrice}
										/>

										<div className="row align-items-center ">
											<div className="col">
												<input
													type="text"
													className="amount w-100"
													placeholder="JOD"
													value={priceRangeVal[0]}
													readOnly={true}
												/>
											</div>
											<div className="col-auto">
												<span className="fa-sharp fa-solid fa-minus mx-1 dark-color" />
											</div>
											<div className="col">
												<input
													type="text"
													readOnly={true}
													className="amount2 w-100"
													placeholder="JOD"
													value={priceRangeVal[1]}
												/></div>
										</div>
									</div>
								</div>
							</div>
							{/* HANDLE PRICE CHANGE FILTER */}
							<Dropdown.Item className="text-end mt10 pr10 quickFilter-dropdownBtn">
								<button
									type="button"
									className="done-btn ud-btn btn-thm drop_btn3"
									onClick={handlePriceFilter}
								>
									{t("DONE")}
								</button>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</li>
				{/* NO OF BEDROOMS AND BATHROOMS */}
				<li className="list-inline-item position-relative">
					<Dropdown>
						<Dropdown.Toggle variant='none'
							className={`open-btn dropdown-toggle ${(searchData?.bedroom || searchData?.bathroom) && 'active'}`}>
							{t("BEDS")} / {t("BATHS")} <i className="fa fa-angle-down ms-2" />
						</Dropdown.Toggle>
						<Dropdown.Menu className={`${currentLanguage === "ar" ? 'text-right' : ''} w-350`}>
							<div className="widget-wrapper pl20 pr20">
								<h6 className="list-title">{t("BEDROOMS")}</h6>
								<Radio.Group
									name="bedroom"
									id="bedroom"
									onChange={(event) => {
										setBed(event?.target?.value);
									}}
									value={bed}
									className='antdRadioButton'
								>
									<Radio.Button value="any">{t('ANY')}</Radio.Button>
									{
										propertyInfoData?.bedroom ?
											Object.keys(propertyInfoData?.bedroom)?.map((element, index) => {
												return <Radio.Button key={index + 1} value={element}>
													{propertyInfoData?.bedroom[element]}
												</Radio.Button>

											}) : ""
									}
								</Radio.Group>
							</div>
							<div className="widget-wrapper bdrb1 pb25 mb0 pl20 pr20">
								<h6 className="list-title">{t("BATHROOMS")}</h6>
								<Radio.Group
									name="bathroom"
									id="bathroom"
									onChange={(event) => {
										setBath(event?.target?.value);
									}}
									value={bath}
									className='antdRadioButton'>
									<Radio.Button value="any">{t('ANY')}</Radio.Button>
									{
										propertyInfoData?.bathroom ?
											Object.keys(propertyInfoData?.bathroom)?.map((element, index) => {
												return <Radio.Button key={index + 1} value={element}>
													{propertyInfoData?.bathroom[element]}
												</Radio.Button>

											}) : ""
									}
								</Radio.Group>
							</div>
							{/* HANDLE BED AND BATH */}
							<Dropdown.Item className=" text-end mt10 pr10 quickFilter-dropdownBtn">
								<button
									type="button"
									className="done-btn ud-btn btn-thm drop_btn4"
									onClick={handleBedAndBath}
								>
									{t("DONE")}
								</button>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</li>
				<li className="list-inline-item">
					{/* Advance Features modal trigger */}

					<button
						type="button"
						className="open-btn mb15 me-2"
						onClick={() => { setAdvanceMenuOpen(true) }}
					>
						<i className="flaticon-settings me-2" /> {t("ADVANCED")}
					</button>
				</li>
			</ul>
			

			<AdvanceSearchModal isOpen={advanceMenuOpen} setModalOpen={setAdvanceMenuOpen} navigatePath={navigatePath} />
		</div>
	)
}

export default QuickFilter


