import React from 'react'
import { useTranslation } from 'react-i18next'

function FeatureAmenitites({ amenities }) {


    const {t} = useTranslation()
    return (
        <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <h4 className="title fz17 mb30">
                <i className="fal fa-list me-2" />
                {t("FEATURES")} &amp; {t("AMENITIES")}
            </h4>
            <div className="row">
                {
                    amenities && amenities?.length > 0 ?

                        amenities?.map((amenity, index) => {
                            return <div key={index + 1} className="col-sm-6 col-md-4">
                                <div className="pd-list mb10-sm">
                                    <p className="text mb10 text-capitalize">
                                        <i className="fas fa-circle fz6 align-middle pe-2 " />
                                        {amenity?.title}
                                    </p>
                                </div>
                            </div>
                        })

                        : ""
                }


            </div>
        </div>
    )
}

export default FeatureAmenitites
