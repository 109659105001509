import { createSlice } from "@reduxjs/toolkit";

const headerSlice = createSlice({
    name : "header",
    initialState : {
        headerClass : "container"
    },
    reducers : {
        setHeaderClass :(state,action)=>{
            state.headerClass = action.payload.className
        }
    }
})

export const {setHeaderClass} = headerSlice?.actions

export default headerSlice?.reducer