import React from 'react'
import Agencyjump1 from "../../assets/images/about/element-5.png"
import Agencyjump2 from "../../assets/images/about/element-7.png"
import Agencysingleagent1 from "../../assets/images/team/agent-page-1.jpg"
import { useTranslation } from "react-i18next";
const AgentProfileInfo = ({agentInfo}) => {
  const { t } = useTranslation()
  return (
    <div className="cta-agent bgc-thm-light mx-auto maxw1600 pt60 pb60 bdrs12 position-relative mx20-lg">
    <div className="container">
       <div className="row align-items-center">
        <div className="col-xl-7">
          <div className="agent-single d-sm-flex align-items-center">
            <div className="bdrs24 default-box-shadow1 mb30-sm">
              <img className="bdrs24 mw-290" src={agentInfo?.imageUrl || Agencysingleagent1} alt="Agencysingleagent" />
            </div>
            <div className="single-contant ml30 ml0-xs">
              <h2 className="title mb-0">{agentInfo?.firstName ?? t('NA')} {agentInfo?.lastName ?? t('NA')}</h2>
              <p className="fz15">{agentInfo?.address ?? t('NA')}</p>
              <div className="agent-meta mb15 d-md-flex align-items-center">
                {
                  agentInfo?.countryCode ?  <span className="text fz15 pe-2 bdrr1">
                  <i className="flaticon-call pe-1" />
                  {agentInfo?.countryCode }  </span> : ""
                }
             
               
                <span className="text fz15 pe-2 ps-2 bdrr1" >
                  <i className="flaticon-smartphone pe-1" />
                  {agentInfo?.mobileNumber || '0000000000'}
                </span>
              </div>
            </div>
          </div>
          <div className="img-box-11 position-relative d-none d-xl-block">
            <img className="img-2 bounce-x" src={Agencyjump1} alt="Agencyjump1" />
            <img className="img-3 bounce-y" src={Agencyjump2} alt="Agencyjump2" />
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AgentProfileInfo
