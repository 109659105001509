import { useTranslation } from 'react-i18next';
import React from 'react'
import moment from 'moment'
import 'moment/locale/ar'; // Import Arabic locale
import Cookies from 'universal-cookie';

const AgentInformation = ({ agentInfo }) => {

  const { i18n } = useTranslation()
  const cookies = new Cookies()
  const userLanguage = i18n?.language ?? cookies?.get("userLangKey") ?? 'en'
  // DISPLAY TIME IN ARABIC
  moment.locale(userLanguage);
  const { t } = useTranslation()

  return (
    <div className="agen-personal-info position-relative bgc-white default-box-shadow1 bdrs12 p30 mt30">
      <div className="widget-wrapper mb-0">
        <h6 className="title fz17 mb35">{t("AGENT INFORMATION")}</h6>
        <div className="list-news-style d-flex align-items-center justify-content-between mb10">
          <div className="flex-shrink-0">
            <h6 className="fz14 mb-0">{t('ADDRESS')}</h6>
          </div>
          <div className="news-content flex-shrink-1 ms-3 text-end">
            <p className="text mb-0 fz14">{agentInfo.address ?? t('NA')}</p>
          </div>
        </div>
        <div className="list-news-style d-flex align-items-center justify-content-between mb10">
          <div className="flex-shrink-0">
            <h6 className="fz14 mb-0">{t('MOBILE')}</h6>
          </div>
          <div className="news-content flex-shrink-1 ms-3 text-end">
            <p className="text mb-0 fz14">{agentInfo?.mobileNumber ? "+" + agentInfo?.countryCode + " " + agentInfo?.mobileNumber:  t('NA')}</p>
          </div>
        </div>
        <div className="list-news-style d-flex align-items-center justify-content-between mb10">
          <div className="flex-shrink-0">
            <h6 className="fz14 mb-0">{t('EMAIL')}</h6>
          </div>
          <div className="news-content flex-shrink-1 ms-3 text-end">
            <p className="text mb-0 fz14">{agentInfo?.email ?? t('NA')}</p>
          </div>
        </div>
        <div className="list-news-style d-flex align-items-center justify-content-between mb10">
          <div className="flex-shrink-0">
            <h6 className="fz14 mb-0">{t('WEBSITE')}</h6>
          </div>
          <div className="news-content flex-shrink-1 ms-3 text-end">
            <p className="text mb-0 fz14">{agentInfo?.agencyDTO?.websiteUrl ?? t('NA')}</p>
          </div>
        </div>
        <div className="list-news-style d-flex align-items-center justify-content-between">
          <div className="flex-shrink-0">
            <h6 className="fz14 mb-0">{t('MEMBER SINCE')}</h6>
          </div>
          <div className="news-content flex-shrink-1 ms-3 text-end">
            <p className="text mb-0 fz14">{moment().subtract(agentInfo?.createdAt, 'year').format('YYYY')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentInformation
