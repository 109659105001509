import { BLOG_DETAILS_API, BLOG_LIST_API } from "../constants/paths"
import { axios } from "./axios"


// BLOG LIST API
export const blogsListApi = async (params) => {
  const response = await axios.get(BLOG_LIST_API, { params })
  return response
}

// BLOG DETAILS API
export const blogsDetailsApi = async (id) => {
  const response = await axios.get(`${BLOG_DETAILS_API}/${id}`)
  return response?.data
}
