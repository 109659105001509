import React, { useState } from "react";
import userImg from "../../assets/images/resource/defaultuser.png";
import { Upload } from "antd";
import {
  deleteUserProfilePic,
  uploadUserProfilePic,
} from "../../services/userService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { removeUserImage, updateUserImage } from "../../redux/userProfileSlics";
import { Popconfirm } from "antd";
import Loader from "../common/Loader";
import { useTranslation } from "react-i18next";

function UploadProfile() {

  const userData = useSelector((state) => state?.user?.userData);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const {t} = useTranslation()

  // HANDLE IMAGE UPLOAD
  const handleUpload = (fileData) => {

    setLoading(true);
    // ALLOWED FILE TYPE ARE JPG AND PNG ONLY
    const allowedFileTypes = ["image/jpeg", "image/png"];
    const isAllowedType = allowedFileTypes.includes(fileData?.file?.type);
    // ALLOWED FILE SIZE IN 10MB
    const allowedFileSize = 1024 * 1024 * 10
    const isAllowedSize = fileData?.file?.size <= allowedFileSize
    // IF FILE MATHES ALL VALIDATION THEN ONLY UPLOAD IT
    if (!isAllowedType) {
      setLoading(false);
      toast.error("You can only upload JPG or PNG files!");
    } else if (!isAllowedSize) {
      setLoading(false);
      toast.error("You can upload file up to 10mb!");
    } else {
      let formData = new FormData();
      formData.append("profilePicture", fileData?.file);
      // CALLING API FOR UPLOAD PROFILE PICTURE
      uploadUserProfilePic(formData)
        .then((response) => {
          setLoading(false);
          dispatch(updateUserImage({ imageUrl: response?.imageUrl }));
          toast.success("Profile image updated!");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            error?.response?.data?.error_description
              ? error?.response?.data?.error_description
              : error?.response?.data?.message ?? error?.message
          );
        });
    }
  };
  // ON PROFILE DELETE
  const handleDelete = () => {
    setLoading(true);
    // CALLING API FOR DELETE PROFILE PICTURE
    deleteUserProfilePic()
      .then((response) => {
        setLoading(false);
        // UPDATE REDUX STATE ALSO
        dispatch(removeUserImage());
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  };
  return (
    <div className="col-xl-7">
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="profile-box position-relative d-md-flex align-items-end mb50">
          {userData?.imageUrl && userData?.imageUrl !== "" ? (
            <div className="profile-img position-relative overflow-hidden bdrs12 mb20-sm ">
              <img
                src={userData?.imageUrl}
                className="mxh-250"
                alt="Profile"
              />
              {/* w-100 */}
              {/* DISPLAY THE POP UP THAT USER WANT TO DELETE PROFILE PICTURE OR NOT */}
              <Popconfirm
                title={t('DELETE THE IMAGE')}
                description={t("ARE YOU SURE TO DELETE PROFILE PICTURE?")}
                onConfirm={handleDelete}
                okText={t("YES")}
                cancelText={t("NO")}
              >
                <button className="tag-del border-none">
                  <span className="fas fa-trash-can" />
                </button>
              </Popconfirm>{" "}
            </div>
          ) : ( // DEFAULT IMAGE IF NO IMAGE UPLOADED BY USER
            <img
              src={userImg}
              className=""
              alt="Profile"
            />
          )}
          <div className="profile-content ml30 ml0-sm">
            {/* UPLOAD PROFILE PICTURE FROM HERE */}
            <Upload
              name="profilePicture"
              onChange={handleUpload}
              beforeUpload={() => false}
              showUploadList={false}
              accept=".jpg, .jpeg, .png"
            >
              <button className="btn ud-btn btn-white2 mb30">
                {t('UPLOAD PROFILE PICTURE')} <i className="fal fa-arrow-right-long" />
              </button>
            </Upload>
            {/* HELPER TEXT */}
            <p className="text">{t('JPEG OR PNG FORMAT')}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadProfile;
