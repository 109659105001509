import React, { useState } from 'react'
import AdvanceSearchModal from '../modal/AdvanceSearchModal'
import { useTranslation } from 'react-i18next'
import { nameBasedRoutes } from '../../router/public'
import QuickFilter from './QuickFilter'
import { removeAllProperties } from '../../redux/comparePropertySlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
function PropertyPageMenu() {

	const { t } = useTranslation()

	const navigatePath = nameBasedRoutes?.propertyList?.path;


	const [advanceMenuOpen, setAdvanceMenuOpen] = useState(false)


	const dispatch = useDispatch()
	const navigate = useNavigate()

	const compareProps = useSelector((state) => state?.compareProps)

	// NAVIGATE TO COMPARE PROPERTIES

	const handleCompareClick = () => {
		navigate(nameBasedRoutes?.compareProperties?.path)
	}

	// REMOVE ALL COMPARE PROPERTIES

	const handleRemoveAllCompare = () => {
		dispatch(removeAllProperties())
	}

	return (
		<div className="row mb-2">
			<div className="col-8 d-none d-xl-block ">
				<QuickFilter navigatePath={navigatePath} />
			</div>
			<div className="col-4 text-end">
				{
					compareProps?.length > 1 ?
						<div className='sticky-compare-btn'>
							<ul className="p-0 mb-0">
								<li className="list-inline-item">
									<button className='ud-btn btn-thm me-2 my-1' onClick={handleCompareClick}>{t("COMPARE")}</button>
									<button className='ud-btn btn-dark my-1' onClick={handleRemoveAllCompare}>{t("REMOVE ALL")}</button>
								</li>
							</ul>
						</div> : ""
				}
			</div>
			<div className="col-6 d-block d-xl-none">
				<div className="dropdown-lists">
					<ul className="p-0 text-start">
						<li className="list-inline-item">
							{/* Advance Features modal trigger */}
							<button
								type="button"
								className="open-btn mb15"
								onClick={() => { setAdvanceMenuOpen(true) }}
							>
								{" "}
								<i className="flaticon-settings me-2" /> {t("ADVANCED")}
							</button>
						</li>
					</ul>
				</div>
			</div>

			<AdvanceSearchModal isOpen={advanceMenuOpen} setModalOpen={setAdvanceMenuOpen} navigatePath={navigatePath} />
		</div>

	)
}

export default PropertyPageMenu
