import React, { useEffect, useState } from 'react'

import OwlCarousel from "react-owl-carousel2";
import { Link, useNavigate } from 'react-router-dom';
import { propertyTypeListApi } from '../../services/advanceSearch';
import { toast } from 'react-toastify';
import { Empty, Skeleton } from 'antd';
import { constructUrl } from '../../services/propertyService';
import { nameBasedRoutes } from '../../router/public';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
const ExploreProperties = () => {

  const cookies = new Cookies()

  const { t } = useTranslation()

  const { i18n } = useTranslation()

  const userLanguage = i18n?.language ?? cookies.get('userLangKey') ?? 'en';

  const options = {
    rtl: userLanguage === 'ar' ? true : false,
    items: 5,
    nav: true,
    rewind: true,
    autoplay: true,
    margin: 30,

    // responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: true
      },
      600: {
        items: 2,
        nav: false
      },
      1000: {
        items: 5,
        nav: true,
        loop: false
      }
    }
  };

  const events = {
    onDragged: function (event) { },
    onChanged: function (event) { },
  };


  const [loading, setLoading] = useState(true)
  const [propertyTypeList, setPropertyTypeList] = useState([])

  // FETCH PROPERTY TYPE LIST
  const fetchPropertyTypeList = () => {
    setLoading(true)
    propertyTypeListApi().then((response) => {
      setLoading(false)
      setPropertyTypeList(response)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  // FETCH PROPERTY TYPES
  useEffect(() => {
    fetchPropertyTypeList()
  }, [i18n?.language])

  // HANDLE NAVIGATEION

  const navigate = useNavigate()
  const navigatePath = nameBasedRoutes?.propertyList?.path;
  // HANDLE PROPERTY TYPE FILTER
  const handleRedirectProperties = (event, typeId) => {
    event.preventDefault()
    const param = { key: "propertyTypeIdList", value: typeId }
    const navigateURl = constructUrl(navigatePath, param)
    navigate(navigateURl)
  }


  return (
    <section className="p0 home2-section2">
      <div className="container">
        <div
          className="row justify-content-center wow fadeInUp"
          data-wow-delay="300ms"
        >
          <div className="col-lg-12">
            {
              loading === true ?
                <div className="row">
                  {[...Array(5)].map((element, index) => {
                    return <div key={index + 1} className="col-xl">
                      <Skeleton.Button active={true} size={"100%"} shape={"square"} block={true} style={{ height: "200px" }} />
                    </div>
                  })
                  }
                </div> :
                propertyTypeList && propertyTypeList?.length > 0 ?
                  propertyTypeList && propertyTypeList?.length > 1 ?
                    <OwlCarousel
                      className="dots_none nav_none slider-dib-sm slider-5-grid owl-theme owl-carousel owl-theme"
                      options={options}
                      events={events}
                    >
                      {
                        propertyTypeList?.map((element, index) => {
                          return <div key={index + 1} className="item">
                            <Link to="#" onClick={(event) => handleRedirectProperties(event, element?.id)}>
                              <div className="iconbox-style4">
                                <span className={`icon ${element?.iconClassName ?? 'icon flaticon-home'} `} />
                                <div className="iconbox-content">
                                  <h6 className="title">{element?.propertyType}</h6>
                                  <p className="text mb-0">{element?.propertyCount}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        })
                      }
                    </OwlCarousel> :
                    <div className="row justify-content-center">
                      {
                        propertyTypeList?.map((element, index) => {
                          return <div key={index + 1} className="col-xl-2 item">
                            <Link key={index + 1} to="#" onClick={(event) => handleRedirectProperties(event, element?.id)}>
                              <div className="iconbox-style4">
                                <span className={`icon ${element?.iconClassName ?? 'icon flaticon-home'} `} />
                                <div className="iconbox-content">
                                  <h6 className="title">{element?.propertyType}</h6>
                                  <p className="text mb-0">{element?.propertyCount}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        })
                      }

                    </div> : <Empty description={t("NO PROPERTIES FOUND")} />
            }

          </div>
        </div>
      </div>
    </section>
  )
}

export default ExploreProperties
