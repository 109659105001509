export const SIGNUP_API = `/v1/user/sign-up`;
export const LOGIN_API = `/v1/sign-in`;
export const VERIFICATION_EMAIL_API = `/v1/account/verify-email`;
export const FORGOT_API = `/v1/account/reset-password/init`;
export const RESET_API = `/v1/account/reset-password/finish`;
export const REFRESH_TOKEN = `/auth/refresh-tokens`;
export const VERIFICATION = `/auth/mfa`;
export const LOGOUT = `/auth/logout`;
export const RESEND_CODE = `/auth/mfa-resend`;
export const SOCIAL_SIGNUP_API = `/v1/user/social-sign-up`
export const SOCIAL_SIGNIN_API = `/v1/user/social-sign-in`

export const SINGLE_AGENCY_API = `/v1/user/agency/front-page/agency`
export const AGENCY_LIST_API = `/v1/user/agency/front-page/agency-list`
export const AGENT_DETAIL_API =`/v1/user/agent-detail`  // api/v1/user/agent-detail/{AGENT_ID}


export const GET_DASHBOARD_COUNT_API = `/dashboard/get-dashboard-count`;
export const GET_CMS_LIST_API = `/dashboard/cms/list`;
export const ADD_CMS_API = `/dashboard/cms/submit`;
export const VIEW_CMS_API = `/dashboard/cms/view`;
export const GET_CONTACTUS_LIST_API = `/dashboard/contact/list`;
export const REPLY_CONTACTUS_API = `/dashboard/contact/reply`;
export const VIEW_CONTACTUS_API = `/dashboard/contact/view`;
export const GET_QOUTES_LIST_API = `/dashboard/qoutes/list`;
export const IMAGE_UPLOAD_API = `/user/image/upload`
export const VIDEO_UPLOAD_API = `/user/video/upload`
export const CHANGE_PASSWORD_API = `/v1/account/change-password`
export const UPDATE_USER_PROFILE_API = `/v1/user/account`
export const GET_USER_DATA_API = `/v1/user/account`
export const UPLOAD_USER_PROFILE_PIC = `/v1/user/account/upload-profile`
export const DELETE_USER_PROFILE_PIC = `/v1/user/account/delete-profile-picture`
export const AGENT_SIGNUP_API = `/v1/agency/agent-sign-up`
export const GENERAL_INFO_API = '/v1/user/general-info'

// CMS PAGE API
export const CMS_PAGE_DATA_API = `/v1/user/cms/cms-by-key`
export const FAQ_PAGE_DATA_API = `/v1/user/faq/front-page`

// ADVANCE SEARCH API 
export const PROPERTY_INFO_API = `/v1/property/user/property/info`
export const PROPERTY_TYPE_LIST = `/v1/property/user/property/property-type-list`
export const AGENCY_AGENT_LIST_API = `/v1/user/agency/search`
export const GOVERNORATE_LIST_API = `/v1/user/governorate/list`

// PROPERTY DETAILS API
export const PROPERTY_DETAILS_API = `/v1/property/user/property`
export const PROPERTY_LIST = `/v1/property/user/property/list`
export const PROPERTY_LIST_MAP = `/v1/property/user/property/map-list`
export const PROPERTY_LIKE_API = `/v1/property/user/property/like`
export const NEAR_BY_SIMILAR_API = `/v1/property/user/property/list/near-by-similar`

// AGENCY DETAILS API

export const AGENCY_PROPERTY_LISTING_API = `/v1/property/user/property/list/agency`
export const AGENT_PROPERTY_LISTING_API = `/v1/property/user/property/list/agent`
export const AGENCY_VIEW_API = `/v1/user/agency/view`

// AGENT DETAILS API
export const AGENCY_CONTACT_API = `/v1/user/agency/front-page/contact-form`
export const AGENT_CONTACT_API = `/v1/user/agent/front-page/contact-form`
export const AGENT_VIEW_API = `/v1/user/agent/view`

export const TOUR_SCHEDULE_FORM_API = `/v1/user/schedule-tour`

// HOME PAGE API'S

export const TRUSTED_AGENCIES_API = `/v1/user/agency/trusted`

// PROPERTIES FOR YOU API

export const PROPERTIES_FOR_YOU_API = `/v1/property/user/property/list/for-you`
// FAVORITES LIST API
export const FAVORITES_LIST_API = `/v1/property/user/property/list/favourite`

// POPULAR SEARCH API 
export const POPULAR_SEARCH_API = `/v1/property/user/property/popular-search/list`;

export const REPORT_PROPERTY_API = `/v1/user/complaint-property`

export const PROPERTY_VIEW_COUNT = `/v1/property/user/property/view`

// SEND OTP ON MOBILE

export const SEND_OTP = `/v1/account/phone/send-otp`

// VERIFY OTP
export const VERIFY_OTP = `/v1/account/phone/verify-otp`

// DELETE ACCOUNT

export const DELETE_ACCOUNT  = `/v1/user/account/delete-account`

// EMAIL VERIFY

export const EMAIL_VERIFY_API = `/v1/user/account/send-verification-email`

// BLOGS
export const BLOG_LIST_API = `/v1/user/blog-post/list`
export const BLOG_DETAILS_API = `/v1/user/blog-post`

// BUILD API 

export const BUILD_DOWNLOAD_API = `/v1/user/build-download/latest`