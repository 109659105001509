import { axios } from "./axios"

import {
  SIGNUP_API,
  FORGOT_API,
  RESET_API,
  LOGIN_API,
  VIEW_CMS_API,
  GET_CONTACTUS_LIST_API,
  REPLY_CONTACTUS_API,
  VIEW_CONTACTUS_API,
  VERIFICATION_EMAIL_API,
  CHANGE_PASSWORD_API,
  UPDATE_USER_PROFILE_API,
  GET_USER_DATA_API,
  UPLOAD_USER_PROFILE_PIC,
  DELETE_USER_PROFILE_PIC,
  SOCIAL_SIGNUP_API,
  SOCIAL_SIGNIN_API,
  AGENT_SIGNUP_API,
  GENERAL_INFO_API,
  AGENCY_CONTACT_API,
  FAVORITES_LIST_API,
  POPULAR_SEARCH_API,
  SEND_OTP,
  VERIFY_OTP,
  DELETE_ACCOUNT,
  EMAIL_VERIFY_API,
  
} from "../constants/paths" 


/******
 * Get contactus list api call
 * @param  data
 * @returns {Promise<any>}
 */

export const getContactusListAPI = async (params) => {
  const response = await axios.get(`${GET_CONTACTUS_LIST_API}`, { params });
  return response?.data
};

/******
 * Add contactus list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const updateContactusAPI = async(data) => {
  const response = await axios.post(REPLY_CONTACTUS_API, data);
  return response?.data
};

/******
 * Get contactus list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewContactusAPI = async (data) => {
  const response = await axios.post(VIEW_CONTACTUS_API, data);
  return response?.data
};

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewCmsAPI = async (data) => {
  const response = await axios.post(VIEW_CMS_API, data);
  return response?.data
};

/**
 * Delete user
 * @param  data
 * @returns {Promise<any>}
 */
export const deleteUser = async (data) => {
  const response =  await axios.post(VIEW_CONTACTUS_API, data);
  return response?.data
};

//********************************************** */

/******
 * Forgot password
 * @param  data
 * @returns {Promise<any>}
 */
export const forgotPasswordAPI = async(data) => {
  const response = await axios.post(FORGOT_API, data);
  return response?.data
};

/******
 * Reset password
 * @param  data
 * @returns {Promise<any>}
 */
export const resetPasswordAPI = async (data) => {
  const response = await axios.post(RESET_API, data);
  return response?.data
};

/******
 * Sign Up
 * @param  data
 * @returns {Promise<any>}
 */
export const signUpAPI = async(data) => {
  const response = await axios.post(SIGNUP_API, data);
  return response?.data
};

/******
 * Sign In/ Login
 * @param  data
 * @returns {Promise<any>}
 */
export const signInAPI = async (data) => {
  const response = await axios.post(LOGIN_API, data);
  return response?.data
};

/******
 * Email verification api
 * @param  data
 * @returns {Promise<any>}
 */
export const getEmailVerificationAPI =async (params) => {
  const response = await axios.get(`${VERIFICATION_EMAIL_API}`, { params });
  return response?.data
};

/******
 * Sample api service
 * @param  data
 * @returns {Promise<any>}
 */
export const googleLogin = async (data) => {
  const response = await axios.post(SOCIAL_SIGNUP_API, data);
  return response?.data
};
// CHANGE PASSWORD
export const changePassword = async (data) => {
  const response = await axios.post(CHANGE_PASSWORD_API, data);
  return response?.data
};
// USER PROFILE UPDATE
export const updateProfileApi = async (data) => {
  const response = await axios.post(UPDATE_USER_PROFILE_API, data);
  return response?.data
};
// FETCH USER PROFILE DATA
export const getUserDataApi = async() => {
  const response = await axios.get(GET_USER_DATA_API);
  return response?.data
};
// UPLOAD PROFILE
export const uploadUserProfilePic = async (data) => {
  const response = await axios.post(UPLOAD_USER_PROFILE_PIC,data);
  return response?.data
};
// DELETE PROFILE
export const deleteUserProfilePic = async () => {
  const response = await axios.delete(DELETE_USER_PROFILE_PIC);
  return response?.data
};

/******
 * Social Signup
 * @param  data
 * @returns {Promise<any>}
 */
export const soicalSignUp = async (data) => {
  const response = await axios.post(SOCIAL_SIGNUP_API, data)  
  return response?.data
}

/******
 * Social Signin
 * @param  data
 * @returns {Promise<any>}
 */
export const soicalSignIn = async (data) => {
  const response = await axios.post(SOCIAL_SIGNIN_API, data)  
  return response?.data
}

/******
 * Agent signup
 * @param  data
 * @returns {Promise<any>}
 */
export const agentSignUp = async (data) => {
  const response = await  axios.post(AGENT_SIGNUP_API, data)  
  return response?.data
}

export const generalInfoApi = async () => {
  const response = await axios.get(GENERAL_INFO_API);
  return response?.data
};

/******
 * Contact us form
 * @param  data
 * @returns {Promise<any>}
 */
export const contactUsFormAPI = async (data) => {
  const response = await axios.post(AGENCY_CONTACT_API, data);
  return response?.data
};

// FAVORITES LIST 
export const favoritesListApi = async (params) => {
  const response = await axios.get(FAVORITES_LIST_API,{params});
  return response
};

// FAVORITES LIST 
export const popularSearchListApi = async (params) => {
  const response = await axios.get(POPULAR_SEARCH_API,{params});
  return response?.data
};

// SEND OTP
export const sendOTPapi = async (data)=>{
  const response = await axios.post(SEND_OTP,data)
  return response?.data
}

// VERIFY OTP
export const verifyOTPapi = async (data)=>{
  const response = await axios.post(VERIFY_OTP,data)
  return response?.data
}

// DELETE ACCOUNT 
export const deleteUserAccount = async ()=>{
  const response = await axios.delete(DELETE_ACCOUNT)
  return response?.data
}

// EMAIL VERIFY 
export const verifyUserEmailAccount = async ()=>{
  const response = await axios.get(EMAIL_VERIFY_API)
  return response?.data
}